import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import LoadingSpinner from '../../../../components/loading-spinner';

export default function TSGraph({ sensorInputs, sensorData, transformData, xaxisTicks, yaxisTicks, showLegend, isLoading}) {
    const canvasRef = useRef(null);
    const [sensorNames, setSensorNames] = useState([]);

    useEffect(() => {
        var names = [];
        sensorInputs.forEach((sensor) => {
            names.push(sensor['sensor_name']);
        })
        setSensorNames(names);
    }, [sensorInputs]);

    const COLOR_OPTIONS = [
        '#8B0000', // Dark Red
        '#00008B', // Dark Blue
        '#006400', // Dark Green
        '#B8860B', // Dark Yellow
        '#800080', // Dark Purple
        '#8B4513', // Dark Orange
        '#8B008B', // Dark Pink
        '#008080', // Dark Teal
        '#654321', // Dark Brown
        '#A9A9A9', // Dark Gray
    ];

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const padding = 50;
        // Function to draw the graph
        const drawGraph = () => {
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Set up the graph properties
            ctx.lineWidth = 2; // Graph line width

            // Draw the x-axis
            ctx.beginPath();
            ctx.strokeStyle = "#A9A9A9"
            ctx.moveTo(padding, canvas.height - padding);
            ctx.lineTo(canvas.width - padding, canvas.height - padding);
            ctx.stroke();

            // Draw the y-axis
            ctx.beginPath();
            ctx.moveTo(padding, canvas.height - padding);
            ctx.lineTo(padding, padding);
            ctx.stroke();
            
            // Draw x-axis ticks
            ctx.beginPath();
            const graphWidth = (canvas.width - padding) - padding;
            xaxisTicks.forEach((tickData) => {
                const label = tickData[0]
                const pos = tickData[1] + 0.0
                ctx.moveTo(padding + pos*graphWidth, canvas.height - (padding - 10))
                ctx.lineTo(padding + pos*graphWidth, canvas.height - padding)
            })
            ctx.stroke();

            // Draw vertical (x-axis) grid lines
            ctx.beginPath();
            ctx.strokeStyle = "#D3D3D3"
            xaxisTicks.forEach((tickData) => {
                const label = tickData[0]
                const pos = tickData[1] + 0.0
                if(pos !== 1 & pos !== 0){
                    ctx.moveTo(padding + pos*graphWidth, canvas.height - padding)
                    ctx.lineTo(padding + pos*graphWidth, padding)
                }
            })
            ctx.stroke();


            // draw x-axis labels
            ctx.font = '10px Arial';
            ctx.textAlign = 'right';
            xaxisTicks.forEach((tickData) => {
                const label = tickData[0]
                const pos = tickData[1] + 0.0
                ctx.save();
                ctx.translate(padding + pos*graphWidth, canvas.height - (padding - 15));
                ctx.rotate(-Math.PI/ 4);
                ctx.fillText(label, 0, 0);
                ctx.restore();
            })

            // draw y-axis labels
            const graphHeight = (canvas.height - padding) - padding;
            ctx.font = '10px Arial';
            ctx.textAlign = 'center';
            yaxisTicks.forEach((tickData) => {
                const label = tickData[0]
                const pos = tickData[1] + 0.0
                ctx.fillText(label, padding - 10, canvas.height - padding - graphHeight*pos);
            })

            // Draw horizontal (y-axis) grid lines
            ctx.beginPath();
            ctx.strokeStyle = "#D3D3D3"
            yaxisTicks.forEach((tickData) => {
                const label = tickData[0]
                const pos = tickData[1] + 0.0
                if(pos !== 1 & pos !== 0){
                    ctx.moveTo(padding, canvas.height - padding - graphHeight*pos)
                    ctx.lineTo(padding + graphWidth, canvas.height - padding - graphHeight*pos)
                }
            })
            ctx.stroke();

            // Draw the graph line
            if(sensorData.length){
                sensorData.forEach((data, idx) => {
                    ctx.save()
                    ctx.strokeStyle = COLOR_OPTIONS[idx]; // Graph line color
                    ctx.beginPath();
                    ctx.moveTo(padding, canvas.height - padding - graphHeight*data[0][1]);
                    for (let i = 1; i < data.length; i++) {
                        const dataPoint = data[i];
                        const xPos = dataPoint[0];
                        const yPos = dataPoint[1];
                        ctx.lineTo(padding + xPos*graphWidth, canvas.height - padding - graphHeight*yPos);
                    }
                    ctx.stroke();
                    ctx.restore();
                })
            }

            if(transformData.length){
                ctx.save()
                ctx.strokeStyle = '#B4BDCC'; // Graph line color
                ctx.beginPath();
                ctx.moveTo(padding, canvas.height - padding - graphHeight*transformData[0][1]);
                for (let i = 1; i < transformData.length; i++) {
                    const dataPoint = transformData[i];
                    const xPos = dataPoint[0];
                    const yPos = dataPoint[1];
                    ctx.lineTo(padding + xPos*graphWidth, canvas.height - padding - graphHeight*yPos);
                }
                ctx.stroke();
                ctx.restore();
            }
        };
        // Call the drawGraph function
        drawGraph();
        window.addEventListener('resize', drawGraph);
    }, [xaxisTicks, yaxisTicks, sensorData, transformData, sensorNames]); // Empty dependency array to run the effect only once on mount

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        function resizeCanvas() {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const canvasWidth = vw * 0.4; // Adjust the multiplier (0.8) as needed
            const canvasHeight = vh * 0.7; // Adjust aspect ratio as needed

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
        }

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);
    }, [])

    const handleMouseMove = (event) => {
        const canvas = event.target;
        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        const graphWidth = (canvas.width - 50) - 50;
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const graphHeight = (canvas.height - 50) - 50;
        for(const data of sensorData){
            for(const reading of data){
                const xpos = reading[0];
                const ypos = reading[1];
                const x1 = 50 + xpos*graphWidth;
                const y1 = canvas.height - 50 - graphHeight*ypos;
                const value = ypos*100;
                if(
                    (Math.abs(x - x1) <= 3) & (Math.abs(y - y1) <= 3)
                ){
                    showTooltip(value.toString(), x, y);
                    return;
                }else{
                    hideTooltip();
                }
            }
        }
    };

    const showTooltip = (text, x, y) => {
        const tooltip = document.getElementById('tooltip');
        tooltip.innerHTML = text;
        tooltip.style.display = 'block';
        tooltip.style.left = `${x}px`;
        tooltip.style.top = `${y}px`;
    };

    const hideTooltip = () => {
        const tooltip = document.getElementById('tooltip');
    };
    
    return (
        <div
            style={{
                position:'relative'
            }}
        >
            {
                showLegend && 
                <Grid container spacing={2}>
                    <Grid item xs={1.5}>
                        Actual
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                height: '20px',
                                width: '50px',
                                backgroundColor: '#8B0000'
                            }}
                        >
                        </Box>
                    </Grid>
                    <Grid item xs={1.5}>
                        Predicted
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                height: '20px',
                                width: '50px',
                                backgroundColor: '#00008B'
                            }}
                        >
                        </Box>
                    </Grid>
                </Grid>
            }
            <canvas
                oncontextmenu="return false;"
                ref={canvasRef}
                height={'600px'}
            />
            {
                isLoading &&
                <div
                    style={{
                        position: 'absolute',
                        zIndex: '1',
                        top: '50%',
                        left: '50%'
                    }}
                >
                    <LoadingSpinner isLoading={true}/>
                </div>
            }
        </div>
    );
}

TSGraph.propTypes = {
    xaxisTicks: PropTypes.array.isRequired,
    yaxisTicks: PropTypes.array.isRequired,
    sensorData: PropTypes.array.isRequired,
    sensorInputs: PropTypes.array.isRequired,
};