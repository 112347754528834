import React from 'react';
import { Card, CardContent, Typography, Grid, Divider } from '@mui/material';

function MaintenanceSummary() {
  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Summary of Anomalies and Downtime for Machine 1
        </Typography>

        {/* Overview Section */}
        <Typography variant="h6" gutterBottom>
          1. Overview
        </Typography>
        <Typography variant="body1" paragraph>
          This report covers the anomalies detected and the downtime experienced by Machine 1 during the period of [Date Range].
        </Typography>
        
        <Divider />

        {/* Anomalies Summary */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          2. Anomalies Summary
        </Typography>
        <Typography variant="body2">
          <strong>Total Anomalies Detected:</strong> 15
        </Typography>
        <Typography variant="body2">
          <strong>Critical Anomalies (High Severity):</strong> 3
        </Typography>
        <Typography variant="body2">
          - <strong>Temperature Spikes:</strong> Occurred on [Dates], where Sensor A recorded temperatures exceeding 75°C.
        </Typography>
        <Typography variant="body2">
          - <strong>Pressure Drops:</strong> Detected on [Dates], where Sensor B readings fell below 0.8 bar.
        </Typography>
        <Typography variant="body2">
          - <strong>High Vibration Levels:</strong> Detected on [Date], where Sensor C recorded vibrations above 0.7 g.
        </Typography>

        <Typography variant="body2" sx={{ marginTop: 1 }}>
          <strong>Moderate Anomalies (Medium Severity):</strong> 7
        </Typography>
        <Typography variant="body2">
          - <strong>Temperature Fluctuations:</strong> Minor fluctuations detected on [Dates].
        </Typography>
        <Typography variant="body2">
          - <strong>Pressure Variations:</strong> Detected on [Dates].
        </Typography>

        <Typography variant="body2" sx={{ marginTop: 1 }}>
          <strong>Low-Level Anomalies:</strong> 5
        </Typography>
        <Typography variant="body2">
          - <strong>Minor Vibration Changes:</strong> Detected on [Dates].
        </Typography>

        <Divider />

        {/* Downtime Summary */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          3. Downtime Summary
        </Typography>
        <Typography variant="body2">
          <strong>Total Downtime:</strong> 8 hours
        </Typography>
        <Typography variant="body2">
          <strong>Unplanned Downtime:</strong> 6 hours
        </Typography>
        <Typography variant="body2">
          - <strong>Root Cause:</strong> Primarily due to the critical anomalies listed above.
        </Typography>
        <Typography variant="body2">
          - <strong>Impact:</strong> Production was halted for 3 hours on [Date].
        </Typography>

        <Typography variant="body2" sx={{ marginTop: 1 }}>
          <strong>Planned Downtime:</strong> 2 hours
        </Typography>
        <Typography variant="body2">
          - <strong>Maintenance Activities:</strong> Routine checks and sensor replacements were conducted.
        </Typography>
        <Typography variant="body2">
          - <strong>Impact:</strong> Minimal impact on production.
        </Typography>

        <Divider />

        {/* Recommendations */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          4. Recommendations
        </Typography>
        <Typography variant="body2">
          - <strong>Increase Monitoring Frequency:</strong> Increase monitoring frequency for Sensor A and B.
        </Typography>
        <Typography variant="body2">
          - <strong>Review Cooling System:</strong> A detailed inspection of the cooling system is recommended.
        </Typography>
        <Typography variant="body2">
          - <strong>Vibration Analysis:</strong> Consider a thorough vibration analysis.
        </Typography>

        <Divider />

        {/* Next Steps */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          5. Next Steps
        </Typography>
        <Typography variant="body2">
          - Schedule a follow-up meeting to discuss the implementation of recommendations.
        </Typography>
        <Typography variant="body2">
          - Plan for additional training sessions for operators.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default MaintenanceSummary;