import * as React from 'react';
import Box from '@mui/material/Box';
import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import { useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import AvailableSensorsHeader from './components/available-sensors-header';
import HistorianMetadataSection from './components/historian-metadata';
import AvailableSensorsTable from './components/available-sensors-table';

export default function HistorianManagerExplore({}) {
    const {historianId} = useParams('historianId');

    const [historian, setHistorian] = React.useState(null);
    React.useEffect(() => {
        const getHistorian = async () => {
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/get/' + historianId);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data
            const result = await response.json();
            setHistorian(result['historian']);
        };
        if(historianId && historianId !== ''){
            getHistorian();
        }
    }, [historianId])


    const [tableHeader, setTableHeader] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);  
    React.useEffect(() => {
        const getSensors = async () => {
          try{
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/getSensorMetadata/' + historianId);
      
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
      
            const result = await response.json();
            console.log(result);
            setTableHeader(result['header']);
            setTableData(result['data']);
          }catch(error){
            setTableHeader([]);
            setTableData([]);
            console.log(error);
          }
        };

        getSensors();
    }, [historian])

    return (
        <Box sx={{ display: 'flex' }}>
          <MenuDrawer/>
          <Box component="main" sx={{ flexGrow: 1 }}>
              <Header title={'Historian Manager'}/>
              {
                historian &&
                <Box
                    sx={{
                        padding:'200px',
                        paddingTop:'40px',
                    }}
                >
                    <HistorianMetadataSection
                        historian={historian}
                    />
                    <Box
                        sx={{
                            paddingTop:'40px'
                        }}
                    >
                        <AvailableSensorsHeader/>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'40px'
                        }}
                    >
                        <AvailableSensorsTable
                            header={tableHeader.slice(1)}
                            data={tableData}
                        />
                    </Box>
                </Box>
              }
          </Box>
        </Box>
      );
}
