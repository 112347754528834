import * as React from 'react';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';

export default function HistorianMetadataSection({historian}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TextField
                    label='Name'
                    disabled={true}
                    value={historian['name']}
                    sx={{
                        width:'100%'
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label='Description'
                    disabled={true}
                    value={historian['description']}
                    sx={{
                        width:'100%'
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    variant={'filled'}
                    label='Type'
                    disabled={true}
                    value={historian['type']}
                    sx={{
                        width:'100%'
                    }}
                />
            </Grid>
        </Grid>
      );
}
