import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import UpdateIcon from "@mui/icons-material/Update";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

export default function CustomizedTimeline() {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
          margin: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="info">
            <UpdateIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            style={{
              fontSize: "14px",
              lineHeight: "18px",
              marginBottom: "4px",
            }}
          >
            <span
              style={{
                color: "rgba(215, 67, 4, 1)",
              }}
            >
              Vibration Forecasting (1min)
            </span>{" "}
            was updated
          </Typography>
          <Typography fontSize="11px">
            <span style={{ color: "rgb(50,50,50)" }}>By</span> Aditya Tewari •{" "}
            <span style={{ color: "rgb(50,50,50)" }}>43 min ago</span>
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="warning">
            <ModelTrainingIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            style={{
              fontSize: "14px",
              lineHeight: "18px",
              marginBottom: "4px",
            }}
          >
            Model training was started for
            <span
              style={{
                color: "rgba(215, 67, 4, 1)",
              }}
            >
              {" "}
              Vibration Forecasting (1min)
            </span>
          </Typography>
          <Typography fontSize="11px">
            <span style={{ color: "rgb(50,50,50)" }}>By</span> Aditya Tewari •{" "}
            <span style={{ color: "rgb(50,50,50)" }}>9:56 AM</span>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
