import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';

import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import ItemTable from '../../../components/item-table';
import MLFactoryCreateButton from './components/create-button';
import MLFactoryExploreButton from './components/explore-button';
import MLFactoryDeleteButton from './components/delete-button';

export default function MLFactoryHome({title, showButton, options, handleSensorImport}) {
  const navigate = useNavigate();

  const [connList, setConnList] = React.useState([]);
  const [streamList, setStreamList] = React.useState([]);
  React.useEffect(() => {
    const getStreams = async () => {
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/stream_service/getAll');

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setStreamList(result['streams']);
    };
    // Call the fetchData function
    getStreams();
  }, [])


  const [exploreLink, setExploreLink] = React.useState('');
  const [canExplore, setCanExplore] = React.useState(false);

  const handleCreateRequest = (type) => {
    navigate('/ml-factory/' + type);
  }


  const handleTableSelect = (streamId) => {
    if(streamId){
      setCanExplore(true);
      setExploreLink('/stream-builder/configure/' + streamId.toString());
    }else{
      setCanExplore(false);
      setExploreLink('');
    }
  }

  const handleDelete = () => {}

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuDrawer/>
      <Box component="main" sx={{ flexGrow: 1 }}>
          <Header title={'Stream Builder'}/>
          <Box sx={{ width: '100%', paddingBottom:'10px' }}>
            <Grid container spacing={2}>
                <Grid item xs={1.1}>
                  <Box
                      sx={{
                          paddingTop:'10px',
                          paddingLeft:'10px',
                      }}
                  >
                    <MLFactoryCreateButton
                      text={'Create'}
                      handleOnClick={handleCreateRequest}
                      link={'/stream-builder/create'}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1.3}>
                  <Box
                      sx={{
                          paddingTop:'10px',
                      }}
                  >
                    <MLFactoryExploreButton
                      text={'Configure'}
                      link={exploreLink}
                      disabled={!canExplore}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1.3}>
                  <Box
                      sx={{
                          paddingTop:'10px',
                      }}
                  >
                    <MLFactoryDeleteButton
                      text={'Delete'}
                      handleOnClick={handleDelete}
                      disabled={!canExplore}
                    />
                  </Box>
                </Grid>
            </Grid>
          </Box>
          <Divider/>
          <Box
              sx={{
                padding: '50px'
              }}
            >
            <ItemTable
              header={[
                ['name', 'Name'],
                ['last_updated', 'Last Updated'],
                ['creation_time', 'Creation Time'],
            ]}
              data={streamList}
              id_column={'stream_id'}
              handleSelection={handleTableSelect}
            />
          </Box>
      </Box>
    </Box>
  );
}