import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import { Modal } from '@mui/base';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { 
  Tooltip,
  Alert,
  AlertTitle,
  Divider,
  Typography,
  Grid,
  IconButton,
  Tab,
  Tabs
} from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { Visibility as VisibilityIcon } from '@mui/icons-material';

import SensorImportHeader from './components/sensor-import-header';
import SectionHeader from './components/section-header-text';
import TransformBuilder from './components/function-builder/components/transform-builder';
import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import ImportDataModal from './components/import-data-modal';
import TSGraph from './components/time-series-graph';

const StreamBuilderCreate = () => {
  const navigate = useNavigate();

  const COLOR_OPTIONS = [
    'rgb(139, 0, 0, 0.8)', // Dark Red
    'rgb(0, 0, 139, 0.8)', // Dark Blue
    'rgb(0, 100, 0, 0.8)', // Dark Green
    'rgb(184, 134, 11, 0.8)', // Dark Yellow
    'rgb(128, 0, 128, 0.8)', // Dark Purple
    'rgb(139, 69, 19, 0.8)', // Dark Orange
    'rgb(139, 0, 139, 0.8)', // Dark Pink
    'rgb(0, 128, 128, 0.8)', // Dark Teal
    'rgb(101, 67, 33, 0.8)', // Dark Brown
    'rgb(169, 169, 169, 0.8)', // Dark Gray
  ];

  const {streamId} = useParams('streamId');

  const [graphStatus, setGraphStatus] = React.useState({});
  const [importedSensors, setImportedSensors] = React.useState([]);
  React.useEffect(() => {
    var status = {...graphStatus};
    var ids = importedSensors.map((sensor) => (sensor['sensor_id']));
    ids.forEach((id) => {
      if(!Object.keys(status).includes(id.toString())){
        status[id] = false;
      }
    })
    setGraphStatus({...status})
  }, [importedSensors])


  const [sensorDataModalOpen, setSensorDataModalOpen] = useState(false);
  const handleSensorDataModalOpen = () => {
    setSensorDataModalOpen(true);
  };
  const handleSensorDataModalClose = () => {
    setSensorDataModalOpen(false);
  };

  const handleSensorSelection = async (sensorId) => {
    var sensorDatasetIds = importedSensors ? importedSensors.map((sensor) => (sensor['sensor_id'])) : []
    if(!sensorDatasetIds.includes(sensorId)){
      const sensorImport = await getSensorMetadata(sensorId);
      var sensorImports = [...importedSensors];
      setImportedSensors([...sensorImports, sensorImport])
    }
  }

  const getSensorMetadata = async (sensorId) => {
    try{
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/get/' + sensorId.toString());
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      const sensor = result['sensor'];
      const sensorImportData = {
        'sensor_id': sensor['sensor_id'],
        'sensor_name': sensor['name'],
      }
      return sensorImportData
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  React.useEffect(() => {
    const currentDate = new Date();
    const lastWeek = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
    const currentDateISO = currentDate.toISOString();
    const lastWeekISO = lastWeek.toISOString();

    setStartDate(lastWeekISO);
    setEndDate(currentDateISO);
  }, [])

  React.useEffect(() => {
    const updateGraph = async () => {
      const tickData = await getGraphTickData();
      setXaxisTicks(tickData['xaxis']);
      setYaxisTicks(tickData['yaxis']);
    }
    if(startDate && endDate){
      updateGraph();
    }
  }, [startDate, endDate])

  React.useEffect(() => {
    const updateGraph = async () => {
      var graphDataPromises = [];
      var status = graphStatus;
      Object.keys(status).forEach((sensorId) => {
        if(!status[sensorId]){
          const data = getSensorGraphData(sensorId);
          graphDataPromises.push(data);
          status[sensorId] = true;
        }
      })
      Promise.all(graphDataPromises).then((graphData) => setSensorGraphData([...sensorGraphData, ...graphData]));
      if(graphDataPromises.length){
        setGraphStatus({...status})
      }
    }
    updateGraph();
  }, [graphStatus])


  const [xaxisTicks, setXaxisTicks] = useState([]);
  const [yaxisTicks, setYaxisTicks] = useState([]);

  const [sensorGraphData, setSensorGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const getSensorGraphData = async (sensorId) => {
    setGraphLoading(true);
    try{
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data/' + sensorId.toString()
        + '?startDate=' + startDate + '&endDate=' + endDate
      );
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setGraphLoading(false);
      return result['data']
    } catch (error) {
      setGraphLoading(false);
      console.error('Error:', error);
    }
  };

  const getGraphTickData = async () => {
    setGraphLoading(true);
    try{
      const sensorId = 1;
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data/' + sensorId.toString()
        + '?startDate=' + startDate + '&endDate=' + endDate
      );
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setGraphLoading(false);
      return {
        'xaxis': result['xaxis'],
        'yaxis': result['yaxis']
      }
    } catch (error) {
      setGraphLoading(false);
      console.error('Error:', error);
    }
  };

  const [streamName, setStreamName] = React.useState(null);
  const handleNameInput = (event) => {
    if(event.target.value){
      setStreamName(event.target.value);
    }else{
      setStreamName(null);
    }
  }

  const [statusMessage, setStatusMessage] = React.useState({'message': '', 'severity': ''})
  const [showStatus, setShowStatus] = React.useState(false);

  const handleAlert = () => {
    setShowStatus(true);
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 5000); // Close after 5 seconds

    return () => clearTimeout(timer);
  }

  const [currentTab, setCurrentTab] = useState(1);
  const handleTabChange = (event, newValue) => {
      setCurrentTab(newValue);
  };

  const [transforms, setTransforms] = React.useState([]);
  const createTransform = () => { 
    var trans = [...transforms];
    trans.push(
      {
        'transform_name' : `transform_${trans.length + 1}`,
        'function_category': null,
        'function_name': null,
        'transform_config': {
          'variable_1_type': null,
          'variable_2_type': null,
          'variable_1_value': null,
          'variable_2_value': null,
        },
      }
    )
    setTransforms([...trans]);
    setCanPreview(false);
  }

  React.useEffect(() => {
    const getStream = async () => {
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/stream_service/get?streamId=' + streamId.toString());

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      var streamMd = result['stream'];
      setTransforms([...streamMd['transforms']]);
      setStreamName(streamMd['name']);
      setImportedSensors([...streamMd['sensors']])
    };
    // Call the fetchData function
    if(streamId){
      getStream();
    }
  }, [streamId])

  const getTransformMd = (md, idx) => {
    var trans = [...transforms];
    trans[idx] = md;
    setTransforms([...trans]);
  }


  const [canSave, setCanSave] = React.useState(false);
  const [canPreview, setCanPreview] = React.useState(false);
  const [saveError, setSaveError] = React.useState('Set valid configuration to save')
  console.log(transforms);
  React.useEffect(() => {
    var errorMessages = [];
    var save = true;
    if(transforms.length == 0){
      errorMessages.push('Stream builder requires at least one transform to save');
      save = false;
    }
    else{
      transforms.forEach((transform) => {
        Object.keys(transform['transform_config']).map((property) => {
          if(!transform['transform_config'][property]){
            save = false;
            errorMessages.push(`Error: ${transform['transform_name']}_${property}`)
          }
        })
      })
    }
    setCanPreview(save);
    if(!streamName){
      save = false;
      errorMessages.push('Enter Stream Name')
    }
    setCanSave(save);
    setSaveError(errorMessages.toString());
  }, [transforms, streamName])

  const [transformGraphData, setTransformGraphData] = React.useState([]);
  React.useEffect(() => {
    const getTransformData = async () => {
      setGraphLoading(true);
      try{
        // Make the API call using fetch
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/stream_service/graph_preview?transforms='
        + JSON.stringify(transforms) + '&startDate=' + startDate + '&endDate=' + endDate
        );
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        setGraphLoading(false);
        setTransformGraphData([...result['graph_data']]);
      } catch (error) {
        setGraphLoading(false);
        console.error('Error:', error);
      }
    }
    if(canPreview){
      getTransformData();
    }
  }, [transforms, canPreview])

  const createStream = async () => {
    try{
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/stream_service/create?transforms='
      + JSON.stringify(transforms) + '&streamName=' + streamName
      + '&sensors=' + JSON.stringify(importedSensors)
      );
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      if(result['success']){
        setStatusMessage({...{
          'message': 'Stream saved!',
          'severity': 'success'
        }})
        handleAlert();
      }else{
        setStatusMessage({...{
          'message': 'Stream failed to save',
          'severity': 'error'
        }})
        handleAlert();
      }
    } catch (error) {
      setStatusMessage({...{
        'message': 'Stream failed to save',
        'severity': 'error'
      }})
      handleAlert();
      console.error('Error:', error);
    }
  }

  const saveStream = async () => {
    try{
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/stream_service/save?transforms=' + JSON.stringify(transforms)
        + '&streamName=' + streamName
        + '&sensors=' + JSON.stringify(importedSensors)
        + '&streamId=' + streamId.toString()
      );
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      if(result['success']){
        setStatusMessage({...{
          'message': 'Stream saved!',
          'severity': 'success'
        }})
        handleAlert();
      }else{
        setStatusMessage({...{
          'message': 'Stream failed to save',
          'severity': 'error'
        }})
        handleAlert();
      }
    } catch (error) {
      setStatusMessage({...{
        'message': 'Stream failed to save',
        'severity': 'error'
      }})
      handleAlert();
      console.error('Error:', error);
    }
  }


  const handleSave = () => {
    if(!streamId){
      createStream();
    }else{
      saveStream();
    }
  }


  return (
    <Box sx={{ display: 'flex' }}>
    <MenuDrawer/>
    <Box component="main" sx={{ flexGrow: 1 }}>
        <Header title={'Stream Builder'}/>
        <Modal
          open={showStatus}
        >
          <Alert
            sx={{
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              top: '15%',
              left: '50%',
              width: '400px'
            }}
            severity={statusMessage['severity']}
          >
            <AlertTitle>{statusMessage['severity'].toUpperCase()}</AlertTitle>
            {statusMessage['message']}
          </Alert>
        </Modal>
        <Box
            sx={{
                padding: '20px',
                paddingRight:'10px',
                paddingLeft:'40px',
            }}
        >
            <Grid container spacing={'20px'}>
                <Grid item xs={8}>
                    <TextField
                        variant='filled'
                        label='Stream Name'
                        onChange={handleNameInput}
                        value={streamName ? streamName : ''}
                        sx={{
                            width:'100%'
                        }}
                    />
                </Grid>
                <Grid item xs={1.25}></Grid>
                <Grid item xs={1}>
                  {
                    !canSave &&
                    <Tooltip title={saveError}>
                      <IconButton
                          style={{ paddingLeft: '20px', paddingTop: '15px' }}
                          color="warning"
                          aria-label="warning"
                      >
                          <WarningIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Grid>
                <Grid item xs={1.5}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={!canSave}
                        onClick={handleSave}
                        sx={{
                            height:'100%',
                            width: '100%'
                        }}
                    >
                      Save
                    </Button>
                </Grid>
            </Grid>
        </Box>
        <Divider/>
        <Box
            sx={{
                display:'flex',
                justifyContent:'center',
                paddingLeft: '100px',
                height:'100%'
            }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6.5}>
              <Box
                sx={{
                  paddingTop:'20px',
                  paddingRight:'30px',
                }}
              >
                <SensorImportHeader
                  text={'Sensors'}
                  color={'#fff'}
                  getViewStatus={handleSensorDataModalOpen}
                />
              <Grid container spacing={'0px'}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      marginLeft:'40px',
                      marginRight:'40px',
                      marginTop: '20px'
                    }}
                  >
                    {
                        importedSensors.map((sensor, idx) => (
                          <Box
                            sx={{
                              backgroundColor:COLOR_OPTIONS[idx],
                              padding: '10px',
                              marginBottom: '10px',
                            }}
                          >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  marginLeft: '20px',
                                }}
                              >
                                <Typography variant='h7' style={{color:'white'}} align='left'>
                                  {sensor['sensor_name']}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          </Box>
                        ))
                      }
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: '50px',
                }}
              >
                <SectionHeader text={'Transforms'} color={'black'}/>
                {
                  transforms.map((transform, idx) => (
                    <TransformBuilder
                      transformIdx={idx}
                      transformMd={transform}
                      sensorConfigs={importedSensors}
                      getTransformMd={(md) => getTransformMd(md, idx)}
                    />
                  ))
                }
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingTop='40px'
                >
                  <IconButton
                    onClick={createTransform}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Box>
              </Box>
              </Box>
            </Grid>
            <Grid item xs={5.5}>
              <Box
                sx={{
                  border: '1px solid #e0e0e0', // Border color
                  borderRadius: '8px', // Border radius
                  paddingBottom: '10px',
                  minHeight: '100%',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#E3EBF3',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft:'20px',
                  }}
                >
                  <Box>
                    <IconButton
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <Typography variant='overline' fontSize='16px'>
                      Last 7 Days
                    </Typography>
                  </Box>
                  <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      textColor="primary"
                      indicatorColor="none"
                      aria-label="secondary tabs example"
                  >
                      <Tab value={1} label="Inputs" />
                      <Tab value={2} label="Output" />
                  </Tabs>
                </Box>
                {
                  currentTab == 1 &&
                  <Box
                    sx={{paddingLeft:'20px'}}
                  >
                    <TSGraph
                      transformData={[]}
                      sensorData={sensorGraphData}
                      xaxisTicks={xaxisTicks}
                      yaxisTicks={yaxisTicks}
                      sensorInputs={importedSensors}
                      isLoading={graphLoading}
                    />
                  </Box>
                }
                {
                  currentTab == 2 &&
                  <Box
                    sx={{paddingLeft:'20px'}}
                  >
                    <TSGraph
                      transformData={transformGraphData}
                      sensorData={[]}
                      xaxisTicks={xaxisTicks}
                      yaxisTicks={yaxisTicks}
                      sensorInputs={importedSensors}
                      isLoading={graphLoading}
                    />
                  </Box>
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      <ImportDataModal
        dataModalOpen={sensorDataModalOpen}
        onClose={handleSensorDataModalClose}
        onImport={handleSensorSelection}
      />
    </Box>
    </Box>
  );
};

export default StreamBuilderCreate;
