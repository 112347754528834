import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AltSensorTSGraph = ({sensor, altSensor, color}) => {
  const [sensorData, setSensorData] = React.useState([]);
  const [altSensorData, setAltSensorData] = React.useState([]);
  const [labels, setLabels] = React.useState([]);

  const [startDate, setStartDate] = React.useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = React.useState(dayjs());

  React.useEffect(() => {
    const getSensorGraphData = async () => {
      try{
        // Make the API call using fetch
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data_v2/' + sensor.sensor_id
          + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString()
        );
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        // Parse the JSON data
        const result = await response.json();
        setSensorData(result['data']);
        setLabels(result['labels']);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    getSensorGraphData();
  }, [sensor])

  React.useEffect(() => {
    const getSensorGraphData = async () => {
      try{
        // Make the API call using fetch
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data_v2/' + altSensor.sensor_id
          + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString()
        );
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        // Parse the JSON data
        const result = await response.json();
        setAltSensorData(result['data']);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    getSensorGraphData();
  }, [altSensor])

  const [chartData, setChartData] = React.useState(null);
  const [options, setOptions] = React.useState(null);

  React.useEffect(() => {
    if(labels){
      var __chartData = {
        labels: labels,
        datasets: [
          {
            data: sensorData,
            fill: false,
            borderColor: color,
            tension: 0.1,
            pointRadius:0,
            yAxisID: 'y1',
          },
          {
            data: altSensorData,
            fill: false,
            borderColor: 'rgba(230, 151, 255, 1)',
            tension: 0.1,
            pointRadius:0,
            yAxisID: altSensorData.length ? 'y2' : 'y1',
          },
        ],
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'time',
            },
            ticks: {
              color: 'rgb(120,120,120)', // Change the x-axis ticks color
              callback: function(value, index, values) {
                const label = this.getLabelForValue(value);
                return index % 10 === 0 ? label.slice(0,11) : '';
              },
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'left',
            border:{
              color: color,
            }
          },
        },
      };

      if(altSensorData.length){
        __options['scales']['y2'] = {
          type: 'linear',
          display: true,
          position: 'left',
          border:{
            color: 'rgba(230, 151, 255, 1)',
          }
        }
      }

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [sensorData, altSensorData, labels])


  return (
    <div style={{height:'300px', width:'90%'}}>
      {(chartData && options) && <Line data={chartData} options={options} />}
    </div>
  );
};

export default AltSensorTSGraph;
