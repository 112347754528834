import React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

const MLFactoryDeleteButton = ({text, handleOnClick, disabled}) => {
  return (
    <Button
      variant="contained"
      style={{
        borderRadius: '5px',
        fontSize: '14px',
        backgroundColor: disabled ? '#e0e0e0' : '#0F1354',
        display: 'flex',
        height: '30px',
      }}
      startIcon={<DeleteIcon />}
      disabled={disabled}
    >
          {text}
    </Button>
  );
};

export default MLFactoryDeleteButton;