import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';

import { Box } from '@mui/material';

import LoadingSpinner from '../../../../components/loading-spinner';



// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TSGraph = ({sensorId, color}) => {
  const startDate = dayjs().subtract(7, 'day');
  const endDate = dayjs();

  const [data, setData] = React.useState(null);
  const [sensorName, setSensorName] = React.useState(null);
  const [labels, setLabels] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getSensorGraphData = async () => {
      setLoading(true);
      try{
        // Make the API call using fetch
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data_v2/' + sensorId.toString()
          + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString()
        );
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        // Parse the JSON data
        const result = await response.json();
        console.log(result);
        setData(result['data']);
        setSensorName(result['legend_label']);
        setLabels(result['labels']);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    getSensorGraphData();
  }, [sensorId])

  const [chartData, setChartData] = React.useState(null);
  const [options, setOptions] = React.useState(null);

  console.log(data);
  console.log(labels);

  React.useEffect(() => {
    if(data && sensorName && labels){
      var __chartData = {
        labels: labels,
        datasets: [
          {
            data: data,
            fill: false,
            borderColor: color,
            tension: 0.1,
            yAxisID: 'y1',
          },
        ],
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: true,
            text: sensorName,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'Time',
            },
            ticks: {
              color: 'black', // Change the x-axis ticks color
              callback: function(value, index, values) {
                const label = this.getLabelForValue(value);
                return index % 10 === 0 ? label.slice(0,11) : '';
              },
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'left',
            border: {
                color: color
            },
          },
        },
      };

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [data, sensorName, labels])


  return (
    <div style={{height:'100%', width:'100%', paddingTop:'10px'}}>
      {
        loading &&
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            height:'100%',
          }}
        >
          <LoadingSpinner isLoading={true}/>
        </Box>
      }
      {(!loading && chartData && options) && <Line data={chartData} options={options} />}
    </div>
  );
};

export default TSGraph;
