import * as React from 'react';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';

export default function HistorianDeletionForm({onDelete, onClose}) {
    const [isChecked, setIsChecked] = React.useState(false);

    const handleDelete = () => {
      if (isChecked) {
        // Perform deletion logic
        onDelete();
        onClose();
      }
    };

    return (
        <Box>
            <Typography variant="h6" id="delete-resource-modal-title" gutterBottom>
                Confirm Deletion
            </Typography>
            <Typography variant="body1" id="delete-resource-modal-description" gutterBottom>
                Are you sure you want to delete this resource? This action may have downstream effects.
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    color="primary"
                    />
                }
                label="I acknowledge the potential downstream effects."
                />
            <Box sx={{ mt: 2 }}>
                <Button onClick={handleDelete} color="error" disabled={!isChecked}>
                    Delete
                </Button>
                <Button onClick={onClose} color="primary" sx={{ ml: 2 }}>
                    Cancel
                </Button>
            </Box>
        </Box>
      );
}
