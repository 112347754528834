
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { 
    Box,
    Button,
    Divider,
    TextField,
    IconButton,
    Typography,
    Modal,
    Alert,
    AlertTitle,
    FormControl,
    Select,
    MenuItem,
    TableHead,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody 
} from '@mui/material';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import SettingsIcon from '@mui/icons-material/Settings';
import ForwardIcon from '@mui/icons-material/Forward';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StormIcon from '@mui/icons-material/Storm';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EastIcon from '@mui/icons-material/East';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import InfoIcon from '@mui/icons-material/Info';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import MenuDrawer from '../../../components/menu-drawer';
import HyperparameterEditor from './components/hyperameter-editor';


const ModelBuilder = () => {
    const {modelId} = useParams('modelId')

    const [model, setModel] = useState({
        'model_id': '',
        'name': '',
        'model_type': '',
        'model_objective': '',
        'version': 1
    });

    useEffect(() => {
        const getModel = async () => {
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=' + modelId);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Parse the JSON data
            const result = await response.json();
            var config = result['model'];
            if(config['start_time']){
                setStartDate(dayjs(config['start_time']));
            }
            if(config['end_time']){
                setEndDate(dayjs(config['end_time']));
            }
            setModelVersion(model.version + 1);
            setModel({...config});
        };
        // Call the fetchData function
        getModel();
    }, [modelId])

    const [hyperparamConfig, setHyperparamConfig] = React.useState(false);

    const openHyperparam = () => setHyperparamConfig(true);
    const closeHyperparam = () => setHyperparamConfig(false);

    const navigate = useNavigate();

    const [startDate, setStartDate] = React.useState(dayjs(model['start_time']));
    const [endDate, setEndDate] = React.useState(dayjs(model['end_time']));

    const handleInterpolationFrequency = (event) => {
        var config = {...model};
        config['interpolation_frequency'] = event.target.value;
        setModel({...config});
    }

    const handleInterpolationFrequencyUnits = (event) => {
        var config = {...model};
        if(event.target.value){
            config['interpolation_frequency_units'] = event.target.value;
        }else{
            config['interpolation_frequency_units'] = null;
        }
        setModel({...config});
    };

    const handleLookback = (event) => {
        var config = {...model};
        config['lookback_count'] = event.target.value;
        setModel({...config});
    }

    const handleForecastCount = (event) => {
        var config = {...model};
        config['forecast_count'] = event.target.value;
        setModel({...config});
    }

    const onAddHyperparameter = (hyperparameter) => {
        var config = {...model};
        if(!config['hyperparameters']){
            config['hyperparameters'] = [];
        }
        config['hyperparameters'].push(hyperparameter);
        setModel({...config});
    }
    const onDeleteHyperparameter = (hyperparameter) => {
        var params = [];
        var config = model;
        config['hyperparameters'].forEach((param) => {
            if(param.name !== hyperparameter){
                params.push(param);
            }
        })
        config['hyperparameters'] = params;
        setModel({...config});
    }

    const [modelVersion, setModelVersion] = React.useState(1);

    const saveModel = async () => {
        try{
            const hyperparameters = model['hyperparameters'] ? model['hyperparameters'] : []
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/save?name='
            + model['name'].toString() + '&startTime=' + startDate.toISOString() +
            '&endTime=' + endDate.toISOString() + '&trainingDataset=' + model['training_set_id'] +
            '&intFreq=' + (model['interpolation_frequency'] ?? 1).toString() + '&intFreqUnits=' + (model['interpolation_frequency_units'] ?? 'minute') + '&lookbackCount=' + (model['lookback_count'] ?? 1)
            + '&forecastCount=' + (model['forecast_count'] ?? 1) + '&modelId=' + modelId.toString() + '&hyperparameters=' + JSON.stringify(hyperparameters)
            + '&sensors=' + JSON.stringify((model['sensors'] ?? [])) + '&streams=' + JSON.stringify((model['streams'] ?? [])) + '&version=' + modelVersion);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        
            // Parse the JSON data
            const result = await response.json();
            if(result['success']){
                setStatusMessage({...{
                    'message': 'Success!',
                    'severity': 'success'
                }})
                handleAlert();
            }else{
                setStatusMessage({...{
                    'message': 'Failed to save model',
                    'severity': 'error'
                }})
                handleAlert();
            }
        }catch (error) {
            console.error('Error:', error);
            setStatusMessage({...{
                'message': 'Failed to save model',
                'severity': 'error'
            }})
            handleAlert();
        }
    };

    const [statusMessage, setStatusMessage] = React.useState({'message': '', 'severity': ''})
    const [showStatus, setShowStatus] = React.useState(false);
  
    const handleAlert = () => {
      setShowStatus(true);
      const timer = setTimeout(() => {
        setShowStatus(false);
      }, 2500); // Close after 5 seconds
  
      return () => clearTimeout(timer);
    }


    const [totalCount, setTotalCount] = React.useState(0);
    const [downsampleCount, setDownsampleCount] = React.useState(0);
    const [featureCount, setFeatureCount] = React.useState(0);
    const [inputHeader, setInputHeader] = React.useState([]);
    const [inputSample, setInputSample] = React.useState([]);
    const [targetSample, setTargetSample] = React.useState({});
    const [targetName, setTargetName] = React.useState('');
    
    const previewModel = async () => {
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/preview' +
            '?trainingDataset=' + modelId +
            '&startTime=' + startDate.toISOString() +
            '&endTime=' + endDate.toISOString() +
            '&predIntValue=' + (model['interpolation_frequency'] ?? 1) +
            '&predIntType=' + (model['interpolation_frequency_units'] ?? 'minute') +
            '&lookbackCount=' + (model['lookback_count'] ?? 1)
        );

        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        setTotalCount(result['total_count']);
        setDownsampleCount(result['downsample_count']);
        setFeatureCount(result['feature_count']);
        setInputHeader(result['input_header']);
        setInputSample(result['input_sample']);
        setTargetSample(result['target_sample']);
        setTargetName(result['target_name']);
        console.log(result);
    }

    return (
    <Box sx={{ display: 'flex' }}>
    <MenuDrawer/>
    <Box component="main" sx={{ flexGrow: 1 }}>
    <Modal
          open={showStatus}
    >
        <Alert
        sx={{
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '15%',
            left: '50%',
            width: '400px'
        }}
        severity={statusMessage['severity']}
        >
        <AlertTitle>{statusMessage['severity'].toUpperCase()}</AlertTitle>
        {statusMessage['message']}
        </Alert>
    </Modal>
    <Box
        sx={{
          height:'70px',
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          paddingLeft:'10px',
          paddingRight:'10px',
        }}
      >
        <Box
          sx={{
            display:'flex',
            gap:'10px',
            justifyContent:'center',
            alignItems:'center',
            paddingBottom:'10px'
          }}
        >
          <IconButton
            sx={{
              padding:0,
              margin:0,
              height:'50px',
              width:'50px',
            }}
          >
            <ArrowBackIcon/>
          </IconButton>
          <Box
            sx={{
              paddingTop:'20px'
            }}
          >
            <Typography fontWeight='bold' variant='h7'>{model.name}</Typography>
            <Box
              sx={{
                display:'flex',
                gap:'15px',
                paddingTop:'5px',
                alignItems:'center'
              }}
            >
              <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Objective</Typography>
              <Box
                sx={{
                  display:'flex',
                  gap:'2px',
                  alignItems:'center'
                }}
              > 
                {model.model_objective == 'Forecasting' ? <StormIcon sx={{color:'rgba(33, 150, 122, 1)'}} /> : model.model_objective == 'Optimization' ? <TroubleshootIcon sx={{color:'#0047AB'}}/> : <CircleNotificationsIcon sx={{color:'rgba(199, 94, 0, 1)'}}/>}
                <Typography color={model.model_objective == 'Forecasting' ? 'rgba(33, 150, 122, 1)' : model.model_objective == 'Optimization' ? '#0047AB' : 'rgba(199, 94, 0, 1)'} variant='body2'>{model.model_objective}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
              sx={{
                  backgroundColor: model.model_type == 'Generalized' ? '#F2F2F2' : 'rgba(47, 128, 237, 0.1)',
                  padding:'5px',
                  borderRadius:'5px',
                  marginLeft:'10px'
              }}
          >
              <Typography fontSize='0.8rem'>
                  {model.model_type}
              </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display:'flex',
            gap:'25px',
            height:'100%',
            alignItems:'center'
          }}
        >
        <Box
            sx={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              height:'100%',
              '&:hover': {
                borderBottom: '2px solid rgba(70, 67, 212, 0.1)'
              },
            }}
            onClick={() => navigate('/ml-factory/model-builder/training-data/' + modelId)}
          >
            <Box
              sx={{
                height:'25px',
                width:'25px',
                borderRadius:'15px',
                backgroundColor:'rgba(240, 240, 240, 1)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
              }}
            >
              <Typography color='rgba(79, 79, 79, 1)'>1</Typography>
            </Box>
            <Typography color='rgba(36, 37, 42, 1)' variant='h7'>Training data</Typography>
          </Box>
          <ChevronRightIcon/>
          <Box
            sx={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              height:'100%',
              borderBottom: '2px solid rgba(70, 67, 212, 1)'
            }}
          >
            <Box
              sx={{
                height:'25px',
                width:'25px',
                borderRadius:'15px',
                backgroundColor:'rgba(70, 67, 212, 1)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
              }}
            >
              <Typography color='white'>2</Typography>
            </Box>
            <Typography color='rgba(70, 67, 212, 1)' variant='h7'>Model configuration</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            gap:'15px'
          }}
        >
          <Button
              sx={{
                backgroundColor:'rgba(36, 37, 42, 1)',
                textTransform:'none',
                '&:hover': {
                  backgroundColor: 'rgba(36, 37, 42, 0.9)', // Hover background color
                },
                width:'60px'
              }}
              variant='contained'
              onClick={saveModel}
          >
              Save
          </Button>
          <Divider flexItem orientation='vertical'/>
          <Button
              sx={{
                  backgroundColor:'rgba(70, 67, 212, 1)',
                  textTransform:'none',
                  '&:hover': {
                      backgroundColor: 'rgba(70, 67, 212, 0.9)', // Hover background color
                  },
                  width:'150px'
              }}
              variant='contained'
              endIcon={<EastIcon/>}
              component={Link}
              to={'/ml-factory/model-overview/' + modelId}
          >
              Train model
          </Button>
        </Box>
    </Box>
    <Divider/>
      <Box
        sx={{
            display:'flex',
        }}
      >
          <Box
            sx={{
                backgroundColor:'rgba(247, 247, 247, 1)',
                width:'70%',
                minHeight:'95vh',
                paddingLeft:'50px',
                paddingRight:'50px',
            }}
          >
            <Box
            sx={{
                paddingTop:'20px',
            }}
            >
                <Typography variant='h6'>Set up model training</Typography>
            </Box>
            <Box
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                backgroundColor:'white',
                marginTop:'20px'
            }}
            >
            <Box
            sx={{
                padding:'15px',
                paddingLeft:'20px',
                display:'flex',
                gap:'10px',
                alignItems:'center'
            }}
            >
                <CalendarMonthIcon/>
                <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Training time range</Typography>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    gap:'20px',
                    padding:'20px',
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='caption' fontWeight='bold'>Start date & time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
                <ForwardIcon/>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='caption' fontWeight='bold'>End date & time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            </Box>
            <Box
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                backgroundColor:'white',
                marginTop:'20px'
            }}
            >
            <Box
            sx={{
                padding:'15px',
                paddingLeft:'20px',
                display:'flex',
                gap:'10px',
                alignItems:'center'
            }}
            >
                <ArrowCircleRightIcon sx={{fill:'rgba(47, 128, 237, 1)'}}/>
                <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Model input parameters</Typography>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    gap:'20px',
                    padding:'20px',
                    flexDirection:'column'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='h7' fontSize='0.9rem'>Sampling Frequency</Typography>
                    <Typography variant='caption'>Downsample the data to reduce model complexity and increase context size</Typography>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            gap:'5px',
                            width:'60%'
                        }}
                    >
                        <TextField
                            defaultValue={1}
                            value={model['interpolation_frequency']}
                            onChange={handleInterpolationFrequency}
                            type='number'
                            size='small'
                            sx={{
                                width:'100px'
                            }}
                        />
                        <FormControl sx={{width:'80%'}} size='small'>
                            <Select
                                defaultValue={'minute'}
                                value={model['interpolation_frequency_units']}
                                onChange={handleInterpolationFrequencyUnits}
                            >
                            <MenuItem value={'second'}>Second</MenuItem>
                            <MenuItem value={'minute'}>Minute</MenuItem>
                            <MenuItem value={'hour'}>Hour</MenuItem>
                            <MenuItem value={'day'}>Day</MenuItem>
                            <MenuItem value={'week'}>Week</MenuItem>
                            <MenuItem value={'month'}>Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='h7' fontSize='0.9rem'>Look-back count</Typography>
                    <Typography variant='caption'>Number of data points to include in the context size of the model</Typography>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            gap:'15px',
                        }}
                    >
                        <TextField
                            defaultValue={1}
                            type='number'
                            size='small'
                            sx={{width:'100px'}}
                            value={model['lookback_count']}
                            onChange={handleLookback}
                        />
                        <Box
                            sx={{
                                display:'flex',
                                padding:'10px',
                                paddingLeft:'15px',
                                width:'100%',
                                backgroundColor:'rgba(240, 240, 240, 1)',
                                alignItems:'center',
                                borderRadius:'8px',
                                gap:'10px'
                            }}
                        >
                            <InfoIcon sx={{fontSize:'1.2rem'}}/>
                            <Typography variant='h7' fontSize='0.9rem'>The lookback range will be {(model['interpolation_frequency'] ?? 1) * (model['lookback_count'] ?? 1)} {model['interpolation_frequency_units'] ? model['interpolation_frequency_units'] : 'minute'}(s)</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
            {
                model.model_objective === 'Forecasting' &&
                <Box
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    backgroundColor:'white',
                    marginTop:'20px'
                }}
                >
                <Box
                sx={{
                    padding:'15px',
                    paddingLeft:'20px',
                    display:'flex',
                    gap:'10px',
                    alignItems:'center'
                }}
                >
                    <StormIcon sx={{fill:'rgba(39, 174, 96, 1)'}}/>
                    <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Model output parameters</Typography>
                </Box>
                <Divider/>
                <Box
                    sx={{
                        display:'flex',
                        gap:'20px',
                        padding:'20px',
                        flexDirection:'column'
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            maxWidth:'100%',
                            gap:'5px'
                        }}
                    >
                        <Typography variant='h7' fontSize='0.9rem'>Forecast Index</Typography>
                        <Typography variant='caption'>Number of data points to generate with the model. Anomaly detection models can only have a forecast index of 1.</Typography>
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                gap:'5px',
                                width:'60%'
                            }}
                        >
                            <TextField onChange={handleForecastCount} value={model['forecast_count']} defaultValue={1} type='number' size='small' sx={{width:'100px'}}/>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            width:'100%',
                            gap:'5px'
                        }}
                    >
                        <Typography variant='h7' fontSize='0.9rem'>Forecast Prediction Interval</Typography>
                        <Typography variant='caption'>Model will output a single value at this interval</Typography>
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                gap:'15px',
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    padding:'10px',
                                    paddingLeft:'15px',
                                    width:'100%',
                                    backgroundColor:'rgba(240, 240, 240, 1)',
                                    alignItems:'center',
                                    borderRadius:'8px',
                                    gap:'10px'
                                }}
                            >
                                <InfoIcon sx={{fontSize:'1.2rem'}}/>
                                <Typography variant='h7' fontSize='0.9rem'>The forecast interval will be {(model['interpolation_frequency'] ?? 1) * (model['forecast_count'] ?? 1)} {model['interpolation_frequency_units'] ? model['interpolation_frequency_units'] : 'minute'}(s)</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                </Box>
            }
            <Box
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                backgroundColor:'white',
                marginTop:'20px',
                marginBottom:'40px'
            }}
            >
            <Box
            sx={{
                padding:'15px',
                paddingLeft:'20px',
                display:'flex',
                gap:'10px',
                alignItems:'center',
                justifyContent:'space-between'
            }}
            >
                <Box
                    sx={{
                        alignItems:'center',
                        display:'flex',
                        gap:'10px'
                    }}
                >
                    <SettingsIcon/>
                    <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Advanced configuration</Typography>
                </Box>
                {
                    hyperparamConfig &&
                    <IconButton
                        sx={{
                            padding:0,
                            margin:0
                        }}
                        onClick={closeHyperparam}
                    >
                        <ArrowDropUpIcon/>
                    </IconButton>
                }
                {
                    !hyperparamConfig &&
                    <IconButton
                        sx={{
                            padding:0,
                            margin:0
                        }}
                        onClick={openHyperparam}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                }
            </Box>
            <Divider/>
            {
                hyperparamConfig &&
                <Box
                    sx={{
                        display:'flex',
                        gap:'20px',
                        padding:'20px',
                        flexDirection:'column'
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            width:'100%',
                            gap:'5px'
                        }}
                    >
                        <Typography variant='caption' fontWeight='bold'>Define hyperparameters</Typography>
                        <Typography variant='caption'>Choose model training parameters to override defaults.</Typography>
                    </Box>
                    <HyperparameterEditor
                        hyperparameters={model['hyperparameters'] ? model['hyperparameters'] : []}
                        onAddHyperparameter={onAddHyperparameter}
                        onDeleteHyperparameter={onDeleteHyperparameter}
                    />
                </Box>
            }
            </Box>
          </Box>
          <Divider flexItem orientation='vertical'/>
          <Box
            sx={{
                width:'60%',
            }}
          >
              <Box
                sx={{
                    display:'flex',
                    padding:'15px',
                    paddingLeft:'20px',
                    backgroundColor:'white'
                }}
              >
                  <Button sx={{p:0, m:0}} variant='text' startIcon={<PlayArrowIcon/>} onClick={previewModel}>Preview</Button>
              </Box>
              <Divider/>
              {
                  totalCount !== 0 &&
                <Box
                    sx={{
                        padding:'20px',
                        display:'flex',
                        flexDirection:'row',
                        gap:'10px',
                        alignItems:'center'
                    }}
                >
                    <Box
                        sx={{
                            marginRight:'40px',
                            padding:'10px',
                            backgroundColor:'white',
                            border:'1px solid lightgrey'
                        }}
                    >
                        <Typography color='grey'>
                            Feature Count
                        </Typography>
                        <Typography fontWeight='bold' variant='h6'>
                            {featureCount}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            padding:'10px',
                            backgroundColor:'white',
                            border:'1px solid lightgrey'
                        }}
                    >
                        <Typography color='grey'>
                            Data Count
                        </Typography>
                        <Typography fontWeight='bold' variant='h6'>
                            {totalCount}
                        </Typography>
                    </Box>
                    <EastIcon/>
                    <Box
                        sx={{
                            padding:'10px',
                            backgroundColor:'white',
                            border:'1px solid lightgrey'
                        }}
                    >
                        <Typography color='grey'>
                            Resampled Count
                        </Typography>
                        <Typography fontWeight='bold' variant='h6'>
                            {totalCount}
                        </Typography>
                    </Box>
                </Box>
              }
              {
                  totalCount !== 0 &&
                    <Box
                        sx={{
                            padding:'20px',
                            paddingTop:'10px',
                            display:'flex',
                            flexDirection:'column',
                            gap:'20px'
                        }}
                    >
                        <Typography variant='h7' fontWeight='bold' paddingLeft='10px'>
                            Output
                        </Typography>
                        <TableContainer sx={{backgroundColor:'lightgrey', maxWidth:'400px', maxHeight:'300px'}}>
                            <Table stickyHeader>
                                <TableHead sx={{backgroundColor:'lightgrey'}}>
                                    <TableCell>
                                        time
                                    </TableCell>
                                    <TableCell>
                                        {targetName}
                                    </TableCell>
                                </TableHead>
                                <TableBody sx={{backgroundColor:'white'}}>
                                    <TableRow>
                                        <TableCell>
                                            {targetSample['time']}
                                        </TableCell>
                                        <TableCell>
                                            {targetSample[targetName]}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
              }
              {
                  totalCount !== 0 &&
                <Box
                    sx={{
                        padding:'20px',
                        paddingTop:'10px',
                        display:'flex',
                        flexDirection:'column',
                        gap:'20px'
                    }}
                >
                    <Typography variant='h7' fontWeight='bold' paddingLeft='10px'>
                        Input
                    </Typography>
                    <TableContainer sx={{backgroundColor:'white', maxWidth:'400px', maxHeight:'300px'}}>
                        <Table stickyHeader>
                            <TableHead sx={{backgroundColor:'lightgrey'}}>
                                {
                                    inputHeader.map((input) => (
                                        <TableCell>
                                            {input}
                                        </TableCell>
                                    ))
                                }
                            </TableHead>
                            <TableBody>
                                    {inputSample.map((row) => (
                                        <TableRow>
                                            {
                                                inputHeader.map((col) => (
                                                <TableCell>
                                                    {row[col]}
                                                </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

              }
          </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default ModelBuilder;