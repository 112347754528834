import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import SensorsIcon from '@mui/icons-material/Sensors';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import TransformIcon from '@mui/icons-material/Transform';
import WebhookIcon from '@mui/icons-material/Webhook';
import AppsIcon from '@mui/icons-material/Apps';
import praxisLogoFull from './praxisLogoFull.png'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: 'none',
  backgroundColor: 'black',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: 'black',
  border: 'none',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'black',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MenuDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [iconDirection, setIconDirection] = React.useState('rtl');

  const handleDrawerClick = () => {
      if(open){
          setOpen(false);
          setIconDirection('rtl')
      }else{
          setOpen(true)
          setIconDirection('ltr')
      }
  }

  return (
    <Box sx={{ display: 'flex'}}>
      <CustomDrawer
        variant="permanent"
        open={open}
      >
        <DrawerHeader>
          {
              open &&
            <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item xs = {1}>
                </Grid>
                <Grid item xs = {9}>
                    <img style={{maxWidth:'90px'}} src={praxisLogoFull} alt="logo" />
                    {/* <Typography color='#F0F0F0'>
                        Praxis AI
                    </Typography> */}
                </Grid>
                <Grid item xs = {2}>
                    <IconButton
                    sx={{
                        color:'#F0F0F0'
                    }}
                    onClick={handleDrawerClick}>
                        {iconDirection === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Grid>
            </Grid>
          }
          {
              !open &&  <IconButton 
              sx={{
                  color:'#F0F0F0'
              }}
              onClick={handleDrawerClick}>
                  {iconDirection === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          }
        </DrawerHeader>
        <Divider />
        <List>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton button component={Link} to={'/'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  ":hover":{
                      backgroundColor: '#636363'
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AppsIcon
                    sx={{
                        color:'#FB6E0D'
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={'Home'} sx={{ color:'#F0F0F0', opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton button component={Link} to={'/sensor-hub'}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              ":hover":{
                  backgroundColor: '#636363'
              }
              
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <SensorsIcon
                sx={{
                    color:'#FFC300'
                }}
              />
            </ListItemIcon>
            <ListItemText primary={'Sensor Hub'} sx={{ color:'#F0F0F0', opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton button component={Link} to={'/stream-builder'}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              ":hover":{
                  backgroundColor: '#636363'
              }
              
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <TransformIcon
                sx={{
                    color:'#DC3545'
                }}
              />
            </ListItemIcon>
            <ListItemText primary={'Stream Builder'} sx={{ color:'#F0F0F0', opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton button component={Link} to={'/ml-factory'}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              ":hover":{
                  backgroundColor: '#636363'
              }
              
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <BuildCircleIcon
                sx={{
                    color:'#28A745'
                }}
              />
            </ListItemIcon>
            <ListItemText primary={'ML Factory'} sx={{ color:'#F0F0F0', opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton button component={Link} to={'/investigator'}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              ":hover":{
                  backgroundColor: '#636363'
              }
              
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <TroubleshootIcon
                sx={{
                    color:'#8c34eb'
                }}
              />
            </ListItemIcon>
            <ListItemText primary={'Investigator'} sx={{ color:'#F0F0F0', opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        </List>
      </CustomDrawer>
    </Box>
  );
}
