import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Box, Divider, Typography, Button, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

import DownloadIcon from '@mui/icons-material/Download';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LoadingSpinner from '../../../../components/loading-spinner';

const drawerWidth = 400;

const stepMap = {
    'start': 0,
    'init': 0,
    'analyze': 1,
    'arch_tune': 2,
    'param_tune': 3,
    'training': 4,
    'complete': 5, 
    'success': 6,
    'failure': 6,
}

function formatDateTime(inputString) {
    // Parse the input date string
    const date = new Date(inputString);
  
    // Options for date formatting
    const dateOptions = { 
      month: 'long', 
      day: '2-digit', 
      year: 'numeric' 
    };
  
    // Format the date part
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  
    // Options for time formatting
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
  
    // Format the time part
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
  
    // Combine date and time with the desired format
    return `${formattedDate} at ${formattedTime}`;
  }

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      /**
       * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
       * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
       * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
       * proper interaction with the underlying content.
       */
      position: 'relative',
    }),
);

export default function TrainingLogs({jobStarted}) {
    const navigate = useNavigate();
    const {modelId} = useParams('modelId')

    const [trainingStatus, setTrainingStatus] = React.useState(null);
    const [model, setModel] = React.useState({
        'model_id': modelId,
        'name': '',
        'model_type': '',
        'model_objective': '',
    });
    const [trainLogs, setTrainLogs] = React.useState(null);
    const [modelData, setModelData] = React.useState({'sensors':[], 'streams':[]});

    const sensorColors = {
        'PV': 'rgba(39, 174, 96, 1)',
        'SP': 'rgba(41, 128, 185, 1)',
        'OUT': 'rgba(231, 76, 60, 1)',
        'TEST': 'rgba(243, 156, 18, 1)',
    }
    
    const SensorTag = ({sensor}) => {
        var sensorColor = 'black'
        if(model.model_type === 'Generalized'){
            Object.keys(sensorColors).forEach((type) => {
              if(sensor.name.includes(type)){
                sensorColor = sensorColors[type];
              }
            });
        }else{
            Object.keys(sensorColors).forEach((type) => {
                if(sensor.sensor_name.includes(type)){
                  sensorColor = sensorColors[type];
                }
              });
        }

        return (
            <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                gap:'10px',
            }}
            >
                <Box sx={{height:'10px', width:'10px', backgroundColor:sensorColor}}></Box>
                <Typography variant='body2'>{model.model_type === 'Generalized' ? sensor.name : sensor.sensor_name}</Typography>
                <Divider orientation='vertical' flexItem/>
            </Box>
        )
    }

    const StreamTag = ({stream, idx}) => {
        var sensorColor = Object.values(sensorColors)[idx] ?? 'black';

        return (
            <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                gap:'10px',
            }}
            >
                <Box sx={{height:'10px', width:'10px', backgroundColor:sensorColor}}></Box>
                <Typography variant='body2'>{stream.name}</Typography>
                <Divider orientation='vertical' flexItem/>
            </Box>
        )
    }

    React.useEffect(() => {
        const getModel = async () => {
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=' + modelId);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Parse the JSON data
            const result = await response.json();
            var config = result['model'];
            setModel({...config});
            setTrainingStatus(config['training_status']);
        };
        // Call the fetchData function
        getModel();
    }, [modelId])

    const checkForResults = async () => {
        try{
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/hasResults?modelId=' + model.model_id)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if(result['has_results']){
                return true;
            }else{
                return false;
            }
        }catch (error) {
            console.error('Error:', error);
            return false;
        }
    }

    const getResults = async () => {
        try{
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/getResults?modelId=' + model.model_id)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const result = await response.json();
                setTrainLogs(result['model_results']['training_logs']);
        }catch (error) {
            console.error('Error:', error);
        }
    }

    React.useEffect(() => {
        const getTrainingLogs = async () => {
            checkForResults().then((hasResults) => {if(hasResults){getResults()}});
        }

        getTrainingLogs();
        const intervalId = setInterval(getTrainingLogs, 10000);

        // Clear the interval when the component unmounts
        if(trainingStatus && ((trainingStatus == 'success') || (trainingStatus == 'failure') || (trainingStatus == 'complete'))){
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [trainingStatus])

    React.useEffect(() => {
        const getResources = async () => {
            var streams = [];
            var sensors = [];
            if(model.model_type == 'Targeted'){
                try{
                    const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=' + model.model_id)
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    const result = await response.json();
                    sensors = result['training_set']['sensor_config'];
                }catch (error) {
                    console.error('Error:', error);
                }
            }else if(model.model_type == 'Generalized'){
                sensors = model.sensors;
                streams = model.streams;
            }
            var modelData = {
                'sensors': sensors,
                'streams': streams
            }
    
            setModelData({...modelData})
        }

        if(model.sensor_id !== -1){
            getResources();
        }
    }, [model])


    const [jobStatus, setJobStatus] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=' + model.model_id);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const result = await response.json();
                setTrainingStatus(result['model']['training_status']);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        // Set up interval to fetch data every 5 seconds
        fetchData();
        const intervalId = setInterval(fetchData, 10000);
        // Clear the interval when the component unmounts

        if(!jobStarted){
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);

    }, [jobStarted]);

    const ProgressDescription = ({stepName, step}) => {
        console.log(trainingStatus);
        console.log(step);
        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    gap:'5px'
                }}
            >
                <Box
                    sx={{
                    position: 'relative',
                    width: '130px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: (stepName === 'Initialize') ? '50%' : 0,
                        right: (stepName === 'Complete!') ? '50%' : 0,
                        height: '1px',
                        backgroundColor: (((stepMap[step] > stepMap[trainingStatus]) && (trainingStatus !== 'complete')) || !trainingStatus)  ? 'lightgrey' : 'rgba(20, 174, 92, 1)',
                        transform: 'translateY(-50%)',
                    }}
                    />
                    {
                        !trainingStatus ? <CircleIcon
                            sx={{
                                fill:'lightgrey',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white', // Optional: to give a background to the icon
                                borderRadius: '50%',     // Optional: to make the background circular
                            }}
                        /> :
                        trainingStatus == 'complete' ? <CheckCircleIcon
                        sx={{
                        fill:'rgba(20, 174, 92, 1)',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white', // Optional: to give a background to the icon
                        borderRadius: '50%',     // Optional: to make the background circular
                        }}/> :
                        (stepMap[step] == stepMap[trainingStatus])  ?
                        <LoadingSpinner isLoading={true} size={30}/> :
                        (stepMap[step] > stepMap[trainingStatus]) ?
                        <CircleIcon
                            sx={{
                                fill:'lightgrey',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white', // Optional: to give a background to the icon
                                borderRadius: '50%',     // Optional: to make the background circular
                            }}
                        /> :
                        <CheckCircleIcon
                            sx={{
                            fill:'rgba(20, 174, 92, 1)',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white', // Optional: to give a background to the icon
                            borderRadius: '50%',     // Optional: to make the background circular
                        }}
                        />
                    }
                </Box>
                <Typography color='rgba(30, 30, 30, 1)' fontSize='0.8rem'>
                    {stepName}
                </Typography>
            </Box>
        )
    }

    const ProgressBar = ({step}) => {
        return (
            <Box
                sx={{
                    width:'40px',
                    height:'2px',
                    backgroundColor:(stepMap[step] >= stepMap[trainingStatus])  ? 'lightgrey' : 'rgba(20, 174, 92, 1)'
                }}
            ></Box>
        )
    }

  return (
        <Box sx={{}}>
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    flexWrap:'wrap'
                }}
            >   
                <ProgressDescription step={'init'} stepName={'Initialize'}/>
                {/* <ProgressBar step={'init'}/> */}
                <ProgressDescription step={'analyze'} stepName={'Analyze Data'}/>
                {/* <ProgressBar step={'analyze'}/> */}
                <ProgressDescription step={'arch_tune'} stepName={'Select Architecture'}/>
                {/* <ProgressBar step={'arch_tune'}/> */}
                <ProgressDescription step={'param_tune'} stepName={'Fine-tune Parameters'}/>
                {/* <ProgressBar step={'param_tune'}/> */}
                <ProgressDescription step={'training'} stepName={'Train model'}/>
                {/* <ProgressBar step={'training'}/> */}
                <ProgressDescription step={'complete'} stepName={'Complete!'}/>
            </Box>
            {
                trainingStatus &&
                <Box
                    sx={{
                        backgroundColor:'white',
                        border:'1px solid lightgrey',
                        marginTop:'20px',
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            padding:'10px'
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                gap:'2px',
                                alignItems:'center'
                            }}
                        >
                            <IconButton sx={{p:0, m:0}}><ArrowDropDownIcon sx={{fill:'black'}}/></IconButton>
                            <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Training Logs</Typography>
                        </Box>
                        <Button
                            sx={{
                                color:'rgba(75, 64, 221, 1)',
                                textTransform:'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(114, 110, 255, 0.1)', // Hover background color
                                },
                            }}
                            component={Link}
                            to={model.model_type == 'Targeted' ? '/ml-factory/model-builder/training-data/' + model.model_id : '/ml-factory/model-builder/training-data/generalized/' + model.model_id}
                            variant='text'
                            startIcon={<DownloadIcon/>}
                        >
                            Download .csv 
                        </Button>
                    </Box>
                    <Divider/>
                    {
                        (trainLogs && (trainLogs.length > 0)) &&
                        <TableContainer sx={{maxHeight:'300px'}}>
                            <Table stickyHeader>
                                <TableHead>
                                    {
                                        ['epoch', 'validation_rmse', 'training_rmse', 'validation_loss', 'training_loss'].map((col) => (
                                            <TableCell>{col}</TableCell>
                                        ))
                                    }
                                </TableHead>
                                <TableBody>
                                    {trainLogs.map((log) => (
                                    <TableRow>
                                        {
                                            ['epoch', 'validation_rmse', 'training_rmse', 'validation_loss', 'loss'].map((col) => (
                                                <TableCell>{log[col]}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>
            }
        </Box>
    );
}