import React, { useState, useEffect } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemButton,
} from '@mui/material';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const HyperparameterEditor = ({ hyperparameters, onAddHyperparameter, onDeleteHyperparameter }) => {
    const nnHyperparameters = {
        'epochs': 25,
        'learning_rate': 0.0001
    };
    const [paramName, setParamName] = useState('');
  const [paramValue, setParamValue] = useState('');
  const [paramOptions, setParamOptions] = useState(Object.keys(nnHyperparameters));

  const handleAddHyperparameter = () => {
    if (paramName && paramValue) {
        onAddHyperparameter({ name: paramName, value: paramValue });
        setParamName('');
        setParamValue('');
    }
  };

  const handleDeleteHyperparameter = (param) => {
    onDeleteHyperparameter(param);
  }

  useEffect(() => {
    const getParamOptions = () => {
        var options = [];

        Object.keys(nnHyperparameters).forEach((paramName) => {
            if(!hyperparameters.map((param) => (param.name)).includes(paramName)){
                options.push(paramName);
            }
        })
        setParamOptions(options);
    };
    getParamOptions();
  }, [hyperparameters]);

  const handleHyperparameterSelection = (event) => {
    setParamName(event.target.value);
    setParamValue(nnHyperparameters[event.target.value]);
  };

  return (
    <div>
        <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <FormControl
                sx={{
                    width:'100%'
                }}
            >
                <Select
                    size='small'
                    labelId="model-select-label"
                    id="model-select"
                    onChange={handleHyperparameterSelection}
                >
                    {paramOptions.map((param) => (
                        <MenuItem key={param} value={param}>
                            {param}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
          </Grid>
          {
            paramName !== '' &&
            <Grid item xs={4}>
              <TextField
                label="Value"
                variant="outlined"
                fullWidth
                size='small'
                value={paramValue}
                onChange={(e) => setParamValue(e.target.value)}
              />
            </Grid>
          }
          {
            paramName !== '' &&
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddHyperparameter}
                startIcon={<AddIcon/>}
              >
                Add
              </Button>
            </Grid>
          }  
        </Grid>
        {hyperparameters.length > 0 && (
            <List>
                {hyperparameters.map((param, index) => (
                <ListItem key={index} sx={{border: `1px solid #F2F2F2`}}>
                    <ListItemText sx={{width:'90%'}} primary={`${param.name}: ${param.value}`} />
                    <ListItemButton onClick={() => handleDeleteHyperparameter(param.name)}>
                        <DeleteIcon/>
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
        )}
      </Box>
    </div>
  );
};

export default HyperparameterEditor;