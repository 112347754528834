import * as React from "react";

import MenuDrawer from "../../components/menu-drawer";

import AddIcon from "@mui/icons-material/Add";
import { Typography, Divider, Box, Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CustomizedTimeline from "./components/timeline";
import CustomizedTimeline2 from "./components/timeline-2";

const parseDate = (date) => {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const parts = formattedDate.split(",");
  const customFormattedDate = `${parts[0]} ${parts[1]}, ${parts[2]}`;

  return customFormattedDate;
};

export default function HomePage({}) {
  const today = new Date();
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(today.getDate() - 2);

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              alignItems: "center",
              padding: "60px",
            }}
          >
            <Typography fontSize="20px" fontWeight="bold">
              Welcome to
            </Typography>
            <Typography
              sx={{ p: 0, m: 0 }}
              fontWeight="bold"
              fontFamily="copperplate"
              fontSize="60px"
            >
              <span style={{ p: 0, m: 0, color: "#1D175B" }}>Praxis</span>
              <span style={{ p: 0, m: 0, color: "#FF7D1F" }}>AI</span>
            </Typography>
            <Box
              sx={{
                border: "1px solid rgba(0,0,0,0.1)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
                width: "70%",
                margin: "30px",
              }}
            >
              <Box
                sx={{
                  padding: "15px",
                }}
              >
                <Typography fontWeight="bold" fontSize="1.1rem" variant="h5">
                  Let's get started
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #4643D4",
                      height: "20px",
                      width: "19px",
                      pl: "2px",
                      pr: "2px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <Typography fontWeight="bold" color="#4643D4">
                      1
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      gap: "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="0.9rem">
                      Connect to a historian and ingest sensors
                    </Typography>
                    <Typography color="#24252A" fontSize="0.8rem">
                      Choose from standard historians such as OSI PI, Honeywell
                      etc.
                    </Typography>
                    <Typography color="#24252A" fontSize="0.8rem">
                      <span style={{ fontWeight: "bold" }}>Need help?</span>{" "}
                      Read documentation on{" "}
                      <span style={{ color: "rgb(70, 67, 212)" }}>
                        how to connect a historian
                      </span>
                    </Typography>
                  </Box>
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    sx={{
                      mt: "20px",
                      mb: "20px",
                      p: "10px",
                      ml: "20px",
                      fontSize: "0.8rem",
                      textTransform: "none",
                      backgroundColor: "rgb(70, 67, 212)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                      },
                    }}
                  >
                    Connect
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgba(0, 0, 0, 0.35)",
                      height: "20px",
                      width: "19px",
                      pl: "2px",
                      pr: "2px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <Typography fontWeight="bold" color="#4F4F4F">
                      2
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="0.9rem">
                      Build your first stream
                    </Typography>
                    <Typography color="#24252A" fontSize="0.8rem">
                      Apply data transformations to derive new sensor streams
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgba(0, 0, 0, 0.35)",
                      height: "20px",
                      width: "18px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                      pr: "2px",
                      pl: "2px",
                    }}
                  >
                    <Typography fontWeight="bold" color="#4F4F4F">
                      3
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="0.9rem">
                      Train and deploy your first model
                    </Typography>
                    <Typography color="#24252A" fontSize="0.8rem">
                      Call model results from a live endpoint or writeback the
                      results to a historian
                    </Typography>
                    <Button
                      endIcon={<EastIcon />}
                      variant="contained"
                      sx={{
                        mt: "10px",
                        p: "5px",
                        fontSize: "0.8rem",
                        textTransform: "none",
                        backgroundColor: "#24252A",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "black", // Hover background color
                        },
                      }}
                    >
                      Open ML Factory
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgb(247, 247, 247)",
              width: "25%",
              padding: "24px",
              borderRadius: "0px",
              borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  height: "2px",
                  flexGrow: "1",
                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                }}
              />
              <Typography
                sx={{
                  color: "rgba(50, 50, 50, 0.7)",
                  fontSize: "14px",
                  width: "auto",
                }}
              >
                Today, {parseDate(today)}
              </Typography>
              <Box
                sx={{
                  height: "2px",
                  flexGrow: "1",
                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <CustomizedTimeline />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  height: "2px",
                  flexGrow: "1",
                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                }}
              />
              <Typography
                sx={{
                  color: "rgba(50, 50, 50, 0.7)",
                  fontSize: "14px",
                  width: "auto",
                }}
              >
                {parseDate(twoDaysAgo)}
              </Typography>
              <Box
                sx={{
                  height: "2px",
                  flexGrow: "1",
                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <CustomizedTimeline2 />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
