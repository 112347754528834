import * as React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import {
  Grid,
  Divider,
  Typography,
  Button,
  LinearProgress,
  ListItemButton,
} from "@mui/material";
import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";

import MenuDrawer from "../../../components/menu-drawer";
import TSGraph from "./components/line-chart";
import TSDataTable from "./components/ts-data-table";

import StorageIcon from "@mui/icons-material/Storage";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import SensorsIcon from "@mui/icons-material/Sensors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StormIcon from "@mui/icons-material/Storm";
import TableChartIcon from "@mui/icons-material/TableChart";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SpeedIcon from "@mui/icons-material/Speed";
import VerifiedIcon from "@mui/icons-material/Verified";
import CompressIcon from "@mui/icons-material/Compress";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import VibrationIcon from "@mui/icons-material/Vibration";

export default function SensorHubHome({}) {
  const sensorIcons = {
    Temperature: <ThermostatIcon />,
    Speed: <SpeedIcon />,
    Quality: <VerifiedIcon />,
    Pressure: <CompressIcon />,
    Steam: <WhatshotIcon />,
    Vibration: <VibrationIcon />,
  };

  const sensorColors = {
    PV: "rgba(39, 174, 96, 1)",
    SP: "rgba(41, 128, 185, 1)",
    OUT: "rgba(231, 76, 60, 1)",
    TEST: "rgba(243, 156, 18, 1)",
  };

  const [historianList, setHistorianList] = React.useState([]);
  React.useEffect(() => {
    const getHistorians = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/getAll"
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setHistorianList(result["historians"]);
    };
    // Call the fetchData function
    getHistorians();
  }, []);

  const [sensorList, setSensorList] = React.useState([]);
  React.useEffect(() => {
    const getSensors = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll"
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setSensorList(result["sensors"]);
    };
    // Call the fetchData function
    getSensors();
  }, []);

  const [selectedHistorian, setSelectedHistorian] = React.useState(null);

  const [selectedSensor, setSelectedSensor] = React.useState({
    name: "",
    description: "",
    sensor_id: "",
  });

  const [sensorIcon, setSensorIcon] = React.useState(<SensorsIcon />);
  const [sensorColor, setSensorColor] = React.useState("white");
  React.useEffect(() => {
    if (selectedSensor) {
      var __sensorIcon = <SensorsIcon />;
      Object.keys(sensorIcons).forEach((type) => {
        if (selectedSensor.description.includes(type)) {
          __sensorIcon = sensorIcons[type];
        }
      });
      setSensorIcon(__sensorIcon);

      var __sensorColor = "black";
      Object.keys(sensorColors).forEach((type) => {
        if (selectedSensor.name.includes(type)) {
          __sensorColor = sensorColors[type];
        }
      });
      setSensorColor(__sensorColor);
    }
  }, [selectedSensor]);

  const [dataTab, setDataTab] = React.useState("Chart");
  const selectDataTab = (tab) => {
    setDataTab(tab);
  };

  const [tab, setTab] = React.useState("Models");

  const selectTab = (tab) => {
    setTab(tab);
  };

  const selectSensor = (sensor) => {
    const selected = { ...sensor };
    setSelectedSensor({ ...selected });
  };

  const selectHistorian = (historianName) => {
    setSelectedHistorian(historianName);
  };

  const CustomCheckBox = ({ variant }) => {
    const [checked, setChecked] = React.useState(false);

    const handleCheck = () => {
      if (checked) {
        setChecked(false);
      } else {
        setChecked(true);
      }
    };

    return checked ? (
      <Box
        sx={{
          height: "18px",
          width: "18px",
          backgroundColor: "rgba(70, 67, 212, 1)",
          border: "1px solid rgba(0, 0, 0, 0.24)",
          borderRadius: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleCheck}
      >
        {variant == "all" ? (
          <HorizontalRuleIcon sx={{ stroke: "white", fill: "white" }} />
        ) : (
          <CheckIcon sx={{ stroke: "white", fill: "white" }} />
        )}
      </Box>
    ) : (
      <Box
        sx={{
          height: "18px",
          width: "18px",
          backgroundColor: "rgba(247, 247, 247, 1)",
          border: "1px solid rgba(0, 0, 0, 0.24)",
          borderRadius: "3px",
        }}
        onClick={handleCheck}
      ></Box>
    );
  };

  const HistorianCard = ({ historian }) => {
    var isSelected = false;
    if (selectedHistorian && selectedHistorian == historian.name) {
      isSelected = true;
    }

    const ingestionProgress = Math.ceil(
      100 * (historian["ingested_sensors"] / historian["total_sensors"])
    );

    return (
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)", // Adjust the blur and shadow strength
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          height: "140px",
          "&:hover": {
            border: "1px solid rgba(70, 67, 212, 0.5)", // New border color on hover
            "& .historianName": {
              color: "rgb(70, 67, 212)", // Change typography color on hover
            },
          },
        }}
      >
        <Box
          sx={{
            padding: "15px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            className="historianName"
            fontWeight="bold"
            variant="body1"
            fontSize="0.9rem"
          >
            {historian.name}
          </Typography>
          <Box
            sx={{
              height: "48px",
            }}
          >
            <Typography
              variant="body1"
              fontSize="0.9rem"
              color="rgba(79, 79, 79, 1)"
            >
              {historian.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <SensorsIcon />
              <Typography paddingTop="2px" fontSize="0.9rem">
                <span style={{ fontWeight: "bold" }}>
                  {historian["ingested_sensors"]}{" "}
                </span>
                of {historian["total_sensors"]} sensors ingested
              </Typography>
            </Box>
            {ingestionProgress == 100 ? (
              <CheckCircleIcon
                sx={{
                  fill: "rgba(33, 150, 83, 1)",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "90px",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={ingestionProgress} // Set the progress value here (0-100)
                  sx={{
                    height: 10, // Set the height of the progress bar
                    borderRadius: 5, // Optional: to add rounded corners
                    backgroundColor: "rgba(224, 224, 224, 1)", // Background color
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "rgba(33, 150, 83, 1)", // Progress bar color
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const HeaderCell = ({ col }) => {
    return (
      <TableCell
        sx={{
          padding: 0,
        }}
      >
        <Typography fontSize="0.8rem" variant="body1">
          {col}
        </Typography>
      </TableCell>
    );
  };

  const SensorRow = ({ sensor }) => {
    var isSelected = false;
    if (sensor.name == selectedSensor.name) {
      isSelected = true;
    }

    var sensorIcon = <SensorsIcon />;
    Object.keys(sensorIcons).forEach((type) => {
      if (sensor.description.includes(type)) {
        sensorIcon = sensorIcons[type];
      }
    });

    var sensorColor = "black";
    Object.keys(sensorColors).forEach((type) => {
      if (sensor.name.includes(type)) {
        sensorColor = sensorColors[type];
      }
    });

    return (
      <TableRow
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: isSelected
              ? "rgba(70, 67, 212, 0.08)"
              : "rgba(134, 134, 134, 0.08)",
          },
          backgroundColor: isSelected ? "rgba(70, 67, 212, 0.08)" : "white",
        }}
      >
        <TableCell
          sx={{
            padding: "2px 4px 20px 16px",
          }}
        >
          <CustomCheckBox />
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
          onClick={() => selectSensor(sensor)}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px",
                width: "20px",
                borderRadius: "3px",
                color: "white",
                backgroundColor: sensorColor,
                "& .MuiSvgIcon-root": { fontSize: "14px" },
              }}
            >
              {sensorIcon}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}
            >
              <Typography
                variant="body1"
                lineHeight="20px"
                fontSize="14px"
                fontWeight="600"
              >
                {sensor.name}
              </Typography>
              <Typography variant="body1" fontSize="12px">
                {sensor.description}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell sx={{ p: 0 }} onClick={() => selectSensor(sensor)}>
          <Typography fontSize="14px" fontWeight="bold">
            {sensor.tag_id}
          </Typography>
        </TableCell>
        <TableCell sx={{ p: 0 }} onClick={() => selectSensor(sensor)}>
          <Typography fontSize="14px">{sensor.historian_name}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  const [loading, setLoading] = React.useState(true);

  const [header, setHeader] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [reading, setReading] = React.useState("");
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    const getSensorData = async () => {
      setLoading(true);
      try {
        // Make the API call using fetch
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getData/" +
            selectedSensor.sensor_id
        );

        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        setHeader(result["header"]);
        setData(result["data"]);
        setReading(result["data"][0]["time"]);
        setValue(result["data"][0]["value"]);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getSensorData();
  }, [selectedSensor]);

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            paddingTop: "24px",
            paddingLeft: "64px",
          }}
        >
          <Typography fontSize="1.8rem" variant="h4">
            Sensor Hub
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            paddingTop: "25px",
            paddingLeft: "64px",
            paddingRight: "64px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <StorageIcon />
              <Typography fontWeight="bold" fontSize="1.2rem" variant="h6">
                Historians
              </Typography>
            </Box>
            <Button
              sx={{
                color: "rgba(70, 67, 212, 1)",
                textTransform: "none",
                padding: 0,
                paddingTop: "1px",
                margin: 0,
              }}
              endIcon={<ArrowForwardIcon />}
            >
              <Typography fontSize="0.9rem">View all</Typography>
            </Button>
          </Box>
          <Button
            component={Link}
            to={"/historian-manager/create"}
            variant="outlined"
            sx={{
              color: "rgba(75, 64, 221, 1)",
              border: "1px solid rgba(114, 110, 255, 0.4)",
              textTransform: "none",
            }}
            startIcon={<AddIcon />}
          >
            Connect Historian
          </Button>
        </Box>
        <Box
          sx={{
            paddingTop: "15px",
            paddingLeft: "64px",
            paddingRight: "64px",
          }}
        >
          <Grid container spacing={"40px"}>
            {historianList.map((historian) => (
              <Grid item xs={4}>
                <HistorianCard historian={historian} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            paddingTop: "40px",
            paddingLeft: "64px",
            paddingRight: "64px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <SensorsIcon />
            <Typography fontWeight="bold" fontSize="1.2rem" variant="h6">
              Sensors
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "2px",
              width: "60px",
              height: "30px",
              borderRadius: "20px",
              backgroundColor: "rgba(220, 220, 220, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography fontSize="0.9rem" fontWeight="bold">
              {sensorList.length}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            height: "40px",
            paddingTop: "20px",
            paddingLeft: "64px",
            paddingRight: "64px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "60%",
                border: "1px solid lightgrey",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: "10px",
                paddingLeft: "10px",
                boxShadow: 1,
              }}
            >
              <SearchIcon fontSize="1.2rem" />
              <Typography fontSize="0.9rem">Search...</Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid lightgrey",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                boxShadow: 1,
              }}
            >
              <Typography fontSize="0.9rem">Type</Typography>
              <ArrowDropDownIcon fontSize="1.2rem" />
            </Box>
            <Box
              sx={{
                border: "1px solid lightgrey",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                boxShadow: 1,
              }}
            >
              <Typography fontSize="0.9rem">Any Historian</Typography>
              <ArrowDropDownIcon fontSize="1.2rem" />
            </Box>
          </Box>
          <Button
            component={Link}
            to={"/sensor-hub/ingest"}
            color="success"
            startIcon={<AddIcon />}
            endIcon={<ArrowDropDownIcon />}
            variant="contained"
          >
            Ingest
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginRight: "64px",
            marginLeft: "64px",
            marginTop: "10px",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            marginBottom: "30px",
            backgroundColor: "white",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                gap: "20px",
                alignItems: "center",
                padding: "12px 16px",
              }}
            >
              <CustomCheckBox variant="all" />
              <Typography fontSize="14px" fontWeight="bold">
                Sensors
              </Typography>
            </Box>
            <Divider />
            <TableContainer
              sx={{
                height: "90vh",
                overflow: "scroll",
              }}
            >
              <Table>
                <TableHead sx={{ backgroundColor: "rgba(247, 247, 247, 1)" }}>
                  <TableRow>
                    <TableCell />
                    {["Name", "Tag ID", "Historian"].map((col) => (
                      <HeaderCell col={col} />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sensorList.map((sensor) => (
                    <SensorRow sensor={sensor} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Divider flexItem orientation="vertical" />
          <Box
            sx={{
              width: "50%",
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    alignItems: "center",
                    marginTop: "3px",
                    height: "32px",
                    width: "32px",
                    borderRadius: "4px",
                    color: "white",
                    backgroundColor: sensorColor,
                  }}
                >
                  {sensorIcon}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    {selectedSensor.name}
                  </Typography>
                  <Typography variant="body1" fontSize="0.9rem">
                    {selectedSensor.description}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    borderColor: "black",
                    color: "black",
                    "&:hover": {
                      borderColor: "black",
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  variant="outlined"
                >
                  <Typography fontWeight="bold">...</Typography>
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  padding: "20px",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}
              >
                <Typography fontSize="0.9rem">Latest reading</Typography>
                <Typography fontWeight="bold">
                  {!loading ? reading : "..."}
                </Typography>
              </Box>
              <Divider flexItem orientation="vertical" />
              <Box
                sx={{
                  width: "50%",
                  padding: "20px",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}
              >
                <Typography fontSize="0.9rem">Latest value</Typography>
                <Typography fontWeight="bold">
                  {!loading ? value : "..."}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid rgba(0, 0, 0, 0.07)",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <CalendarMonthIcon />
                <Typography>Last 7 Days</Typography>
                <ArrowDropDownIcon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  sx={{
                    borderColor:
                      dataTab == "Chart"
                        ? "rgba(70, 67, 212, 1)"
                        : "rgba(0, 0, 0, 0.07)",
                    textTransform: "none",
                    color:
                      dataTab == "Chart" ? "rgba(70, 67, 212, 1)" : "black",
                    width: "80px",
                    "&:hover": {
                      borderColor:
                        dataTab == "Chart"
                          ? "rgba(70, 67, 212, 1)"
                          : "rgba(191, 190, 232, 1)",
                      backgroundColor:
                        dataTab == "Chart" ? "white" : "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  onClick={() => selectDataTab("Chart")}
                  variant="outlined"
                  startIcon={<ShowChartIcon />}
                >
                  Chart
                </Button>
                <Button
                  sx={{
                    borderColor:
                      dataTab == "Data"
                        ? "rgba(70, 67, 212, 1)"
                        : "rgba(0, 0, 0, 0.07)",
                    textTransform: "none",
                    color: dataTab == "Data" ? "rgba(70, 67, 212, 1)" : "black",
                    width: "80px",
                    "&:hover": {
                      borderColor:
                        dataTab == "Data"
                          ? "rgba(70, 67, 212, 1)"
                          : "rgba(191, 190, 232, 1)",
                      backgroundColor:
                        dataTab == "Data" ? "white" : "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  onClick={() => selectDataTab("Data")}
                  variant="outlined"
                  startIcon={<TableChartIcon />}
                >
                  Data
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                height: "200px",
              }}
            >
              {selectedSensor.sensor_id !== "" && dataTab == "Chart" && (
                <TSGraph
                  color={sensorColor}
                  sensorId={selectedSensor.sensor_id}
                />
              )}
              {selectedSensor.sensor_id !== "" && dataTab == "Data" && (
                <TSDataTable loading={loading} header={header} data={data} />
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "20px",
                paddingRight: "20px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  padding="10px 8px 10px 0px"
                  variant="body1"
                  fontWeight="bold"
                >
                  Usage
                </Typography>
                <Box
                  sx={{
                    borderBottom:
                      tab == "Models" ? "1px solid black" : "0px solid black",
                    display: "flex",
                    gap: "5px",
                    padding: "10px",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor:
                        tab == "Models" ? "white" : "rgba(0, 0, 0, 0.04)", // Hover background color
                    },
                  }}
                  onClick={() => selectTab("Models")}
                >
                  <StormIcon />
                  <Typography>Models</Typography>
                </Box>
                <Box
                  sx={{
                    borderBottom:
                      tab == "Streams" ? "1px solid black" : "0px solid black",
                    display: "flex",
                    gap: "5px",
                    padding: "10px",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor:
                        tab == "Streams" ? "white" : "rgba(0, 0, 0, 0.04)", // Hover background color
                    },
                  }}
                  onClick={() => selectTab("Streams")}
                >
                  <ShowChartIcon />
                  <Typography>Streams</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Button
                  sx={{
                    borderColor: "rgba(70, 67, 212, 1)",
                    color: "rgba(70, 67, 212, 1)",
                    textTransform: "none",
                    "&:hover": {
                      borderColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                      color: "rgba(70, 67, 212, 1)",
                    },
                    width: "120px",
                    p: 0,
                    m: 0,
                  }}
                  startIcon={<AddIcon />}
                  endIcon={<ArrowDropDownIcon />}
                  variant="text"
                >
                  New
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box padding={"8px 0px"}>
              {tab == "Models" &&
                ["Vibration Forecasting (1min)", "Vibration Anomaly"].map(
                  (model) => (
                    <ListItemButton>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                          padding: "4px, 0px",
                        }}
                      >
                        <StormIcon sx={{ fill: "rgba(70, 67, 212, 1)" }} />
                        <Typography>{model}</Typography>
                      </Box>
                    </ListItemButton>
                  )
                )}
              {tab == "Streams" &&
                ["Average Pressure", "Quality Time Shift"].map((model) => (
                  <ListItemButton>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                        padding: "4px, 0px",
                      }}
                    >
                      <ShowChartIcon sx={{ fill: "rgba(70, 67, 212, 1)" }} />
                      <Typography>{model}</Typography>
                    </Box>
                  </ListItemButton>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
