import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import Modal from '@mui/material/Modal';
import { TableContainer, Table, TableBody, TableHead, TableCell, TableRow, Paper } from '@mui/material';

const ImportDataModal = ({dataModalOpen, onClose, onImport}) => {
  const [eventDatasets, setEventDatasets] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');

  useEffect(() => {
    const getEventDatasets = () => {
      const fetchData = async () => {
            const query = 'https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll';
            // Make the API call using fetch
            const response = await fetch(query);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data
            const result = await response.json();
            setEventDatasets(result['sensors']);
      };
      // Call the fetchData function
      fetchData();
    };
    getEventDatasets();
  }, []);

  useEffect(() => {
    var options = [];
    eventDatasets.forEach((dataset) => {
      const option = {
        'label': dataset.name,
        'value': dataset.sensor_id,
      }
      options.push(option)
    })
    setEventOptions(options)
  }, [eventDatasets]);


  const handleEventSelect = (event, selectedOption) => {
    if(selectedOption){
      setSelectedEventId(selectedOption.value);
    }else{
      setSelectedEventId('');
    }
  };

  const handleDataModalClose = () => {
    setSelectedEventId('');
    onClose();
  };

  const handleImport = () => {
    onImport(selectedEventId);
    handleDataModalClose();
  }

  return (
    <Modal
        open={dataModalOpen}
        onClose={handleDataModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                bgcolor: 'white',
                border: '0.5px solid #000',
                boxShadow: 24,
                p: 4,
            }}
        >
            <Grid container spacing={'20px'}>
            <Grid item xs={10}>
                <Autocomplete
                    sx={{
                        padding:'20px'
                    }}
                    freeSolo
                    options={eventOptions}
                    onChange={handleEventSelect}
                    renderInput={(params) => (
                        <TextField {...params} label="Search" variant="outlined" />
                    )}
                />
            </Grid> 
            <Grid item xs={2}>
                <Button
                sx={{
                    padding:'30px'
                }}
                disabled={selectedEventId === ''}
                onClick={handleImport}
                >
                Import
                </Button>
            </Grid>
            </Grid>
        </Box>
    </Modal>
  );
};

export default ImportDataModal;