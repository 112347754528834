import * as React from "react";
import { Link } from "react-router-dom";

import { Box, Divider, Typography, Button, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

import MenuDrawer from "../../../components/menu-drawer";
import CreateModal from "./components/create-modal";

import CancelIcon from "@mui/icons-material/Cancel";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StormIcon from "@mui/icons-material/Storm";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import PsychologyIcon from "@mui/icons-material/Psychology";
import StarIcon from "@mui/icons-material/Star";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 400;

function formatDateTime(inputString) {
  // Parse the input date string
  const date = new Date(inputString);

  // Options for date formatting
  const dateOptions = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };

  // Format the date part
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  // Options for time formatting
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Format the time part
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Combine date and time with the desired format
  return `${formattedDate} at ${formattedTime}`;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

export default function MLFactoryHome({
  title,
  showButton,
  options,
  handleSensorImport,
}) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState({ sensor_id: -1 });
  const [openCreate, setOpenCreate] = React.useState(false);

  const handleModalState = (state) => {
    setOpenCreate(state);
  };

  const handleModelSelect = (model) => {
    if (model.sensor_id == selectedModel.sensor_id) {
      setOpenDrawer(false);
      setSelectedModel({ ...{ sensor_id: 1 } });
    } else {
      setSelectedModel({ ...model });
      setOpenDrawer(true);
    }
  };

  const [modelList, setModelList] = React.useState([]);

  React.useEffect(() => {
    const getModels = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/getAll"
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setModelList(result["models"]);
    };
    // Call the fetchData function
    getModels();
  }, []);

  const ModelCard = ({ model }) => {
    return (
      <Box
        sx={{
          padding: "16px 20px",
          borderBottom: "1px solid lightgrey",
          backgroundColor:
            model.model_id === selectedModel.model_id
              ? "rgba(70, 67, 212, 0.06)"
              : "rgba(255, 255, 255, 1)",
          "&:hover": {
            backgroundColor: "rgba(44, 44, 45, 0.06)",
            cursor: "pointer",
          },
        }}
        onClick={() => handleModelSelect(model)}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#000000",
              textDecoration: "none",
              "&:hover": {
                color: "#4643D4",
              },
            }}
            component={Link}
            to={"/ml-factory/model-overview/" + model.model_id}
          >
            {model.name}
          </Typography>
          <Box
            sx={{
              backgroundColor:
                model.model_type === "Generalized"
                  ? "#F2F2F2"
                  : "rgba(47, 128, 237, 0.1)",
              padding: "2px 8px",
              borderRadius: "3px",
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>
              {model.model_type}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "4px",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            {model.description}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: "16px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {model.model_objective == "Forecasting" ? (
              <StormIcon
                sx={{ color: "rgba(33, 150, 122, 1)", fontSize: "14px" }}
              />
            ) : model.model_objective == "Optimization" ? (
              <TroubleshootIcon sx={{ color: "#0047AB", fontSize: "14px" }} />
            ) : (
              <CircleNotificationsIcon
                sx={{ color: "rgba(199, 94, 0, 1)", fontSize: "14px" }}
              />
            )}
            <Typography
              color={
                model.model_objective == "Forecasting"
                  ? "rgba(33, 150, 122, 1)"
                  : model.model_objective == "Optimization"
                  ? "#0047AB"
                  : "rgba(199, 94, 0, 1)"
              }
              fontSize="12px"
              fontWeight="600"
              variant="body1"
            >
              {model.model_objective}
            </Typography>
          </Box>
          <Typography fontSize="12px" fontWeight="800">
            •
          </Typography>
          <Typography fontSize="12px" variant="subtitle2" color="#5e5e5e">
            Created on {formatDateTime(model.creation_time)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const sensorColors = {
    PV: "rgba(39, 174, 96, 1)",
    SP: "rgba(41, 128, 185, 1)",
    OUT: "rgba(231, 76, 60, 1)",
    TEST: "rgba(243, 156, 18, 1)",
  };

  const SensorTag = ({ sensor }) => {
    var sensorColor = "black";
    try {
      Object.keys(sensorColors).forEach((type) => {
        if (sensor.name.includes(type)) {
          sensorColor = sensorColors[type];
        }
      });
    } catch (error) {}
    try {
      Object.keys(sensorColors).forEach((type) => {
        if (sensor.sensor_name.includes(type)) {
          sensorColor = sensorColors[type];
        }
      });
    } catch (error) {}

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">
          {selectedModel.model_type === "Generalized"
            ? sensor.name
            : sensor.sensor_name}
        </Typography>
        <Divider orientation="vertical" flexItem />
      </Box>
    );
  };

  const StreamTag = ({ stream, idx }) => {
    var sensorColor = Object.values(sensorColors)[idx] ?? "black";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">{stream.name}</Typography>
        <Divider orientation="vertical" flexItem />
      </Box>
    );
  };

  const [selectedModelData, setSelectedModelData] = React.useState({
    sensors: [],
    streams: [],
  });
  React.useEffect(() => {
    const getResources = async () => {
      var streams = [];
      var sensors = [];
      if (selectedModel.model_type == "Targeted") {
        try {
          const response = await fetch(
            "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=" +
              selectedModel.model_id
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          sensors = result["training_set"]["sensor_config"];
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (selectedModel.model_type == "Generalized") {
        sensors = selectedModel.sensors;
        streams = selectedModel.streams;
      }
      var modelData = {
        sensors: sensors,
        streams: streams,
      };

      setSelectedModelData({ ...modelData });
    };

    if (selectedModel.sensor_id !== -1) {
      getResources();
    }
  }, [selectedModel]);

  const [hasResults, setHasResults] = React.useState(false);
  React.useEffect(() => {
    const checkForResults = async () => {
      try {
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/hasResults?modelId=" +
            selectedModel.model_id
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result["has_results"]) {
          setHasResults(true);
        } else {
          setHasResults(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (selectedModel.sensor_id !== -1) {
      checkForResults();
    }
  }, [selectedModel]);

  const [trainResults, setTrainResults] = React.useState(null);
  React.useEffect(() => {
    const getResults = async () => {
      try {
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/getResults?modelId=" +
            selectedModel.model_id
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setTrainResults(result["model_results"]);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (hasResults) {
      getResults();
    }
  }, [selectedModel]);

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <CreateModal open={openCreate} handleModalState={handleModalState} />
        <Box sx={{ display: "flex" }}>
          <Main open={openDrawer}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "64px",
                paddingRight: "64px",
                paddingTop: "32px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontSize: "32px", lineHeight: "44px" }}
                  variant="h4"
                >
                  Models
                </Typography>
                <Button
                  onClick={() => handleModalState(true)}
                  color="success"
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  New
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  height: "40px",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                }}
              >
                <Box
                  sx={{
                    flexGrow: "1",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "white",
                    boxShadow: 1,
                  }}
                >
                  <SearchIcon />
                  <Typography>Search...</Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    backgroundColor: "white",
                    boxShadow: 1,
                  }}
                >
                  <Typography>Type</Typography>
                  <ArrowDropDownIcon />
                </Box>
                <Box
                  sx={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    backgroundColor: "white",
                    boxShadow: 1,
                  }}
                >
                  <Typography>Objective</Typography>
                  <ArrowDropDownIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid lightgrey",
                  overflow: "hidden",
                }}
              >
                {modelList.map((model) => (
                  <ModelCard model={model} />
                ))}
              </Box>
            </Box>
          </Main>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={openDrawer}
          >
            <Box
              sx={{
                width: "400px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                minHeight: "100%",
                boxShadow: 20,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  justifyContent: "left",
                  alignItems: "center",
                  height: "64px",
                  paddingLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    height: "30px",
                    width: "30px",
                    backgroundColor: "lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PsychologyIcon />
                </Box>
                <Typography fontWeight="bold" variant="h7">
                  {selectedModel.name}
                </Typography>
                <StarIcon sx={{ color: "gold" }} />
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  height: "50px",
                  padding: "10px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "rgba(70, 67, 212, 1)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                    },
                    width: "120px",
                  }}
                  variant="contained"
                  component={Link}
                  to={"/ml-factory/model-overview/" + selectedModel.model_id}
                >
                  Open
                </Button>
                <Button
                  sx={{
                    backgroundColor: "rgba(114, 110, 255, 0.16)",
                    color: "rgba(75, 64, 221, 1)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(114, 110, 255, 0.5)", // Hover background color
                    },
                    width: "120px",
                  }}
                  component={Link}
                  to={
                    selectedModel.model_type == "Targeted"
                      ? "/ml-factory/model-builder/training-data/" +
                        selectedModel.model_id
                      : "/ml-factory/model-builder/training-data/generalized/" +
                        selectedModel.model_id
                  }
                  variant="contained"
                  startIcon={<SettingsIcon />}
                >
                  Configure
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    borderColor: "black",
                    color: "black",
                    width: "50px",
                    "&:hover": {
                      borderColor: "black",
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  variant="outlined"
                >
                  Share
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    borderColor: "black",
                    color: "black",
                    "&:hover": {
                      borderColor: "black",
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  variant="outlined"
                >
                  <Typography fontWeight="bold">...</Typography>
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Details
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Objective
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    {selectedModel.model_objective == "Forecasting" ? (
                      <StormIcon sx={{ color: "rgba(33, 150, 122, 1)" }} />
                    ) : selectedModel.model_objective == "Optimization" ? (
                      <TroubleshootIcon sx={{ color: "#0047AB" }} />
                    ) : (
                      <CircleNotificationsIcon
                        sx={{ color: "rgba(199, 94, 0, 1)" }}
                      />
                    )}
                    <Typography
                      color={
                        selectedModel.model_objective == "Forecasting"
                          ? "rgba(33, 150, 122, 1)"
                          : selectedModel.model_objective == "Optimization"
                          ? "#0047AB"
                          : "rgba(199, 94, 0, 1)"
                      }
                      variant="body2"
                    >
                      {selectedModel.model_objective}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Model Type
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {selectedModel.model_type}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Sensors
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "190px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedModelData.sensors.map((sensor) => (
                      <SensorTag sensor={sensor} />
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Streams
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "190px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedModelData.streams.map((stream) => (
                      <StreamTag stream={stream} />
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Training time range
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(selectedModel.start_time)}{" "}
                      <span style={{ color: "rgba(79, 79, 79, 1)" }}>to</span>{" "}
                      {formatDateTime(selectedModel.end_time)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Last trained
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    {trainResults && (
                      <Typography variant="body2">
                        {formatDateTime(trainResults.creation_time)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Deployment
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Active
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "2px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <CancelIcon sx={{ fill: "red", stroke: "white" }} />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Metadata
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Creator
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">You</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Last modified
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(selectedModel.last_updated)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Created on
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(selectedModel.creation_time)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>
      {/* <Box component="main" sx={{ flexGrow: 1 }}>
            <CreateModal open={openCreate} handleModalState={handleModalState}/>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    paddingLeft:'100px',
                    paddingRight:'100px',
                    paddingTop:'34px',
                    // width: openDrawer ? '70%' : '100%'
                }}          
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                    }}
                >
                    <Typography fontSize={'2.8rem'} variant='h4'>Models</Typography>
                    <Button onClick={() => handleModalState(true)} color='success' startIcon={<AddIcon/>} variant='contained' >New</Button>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        gap:'8px',
                        height:'40px',
                        paddingTop:'30px',
                    }}
                >
                    <Box
                        sx={{
                            width:'75%',
                            border:'1px solid lightgrey',
                            borderRadius:'5px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'left',
                            gap:'10px',
                            paddingLeft:'10px',
                            boxShadow:1
                        }}
                    >
                        <SearchIcon/>
                        <Typography>
                            Search...
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            border:'1px solid lightgrey',
                            borderRadius:'5px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            gap:'10px',
                            paddingLeft:'10px',
                            paddingRight:'10px',
                            boxShadow:1
                        }}
                    >
                        <Typography>Type</Typography>
                        <ArrowDropDownIcon/>
                    </Box>
                    <Box
                        sx={{
                            border:'1px solid lightgrey',
                            borderRadius:'5px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            gap:'10px',
                            paddingLeft:'10px',
                            paddingRight:'10px',
                            boxShadow:1
                        }}
                    >
                        <Typography>Objective</Typography>
                        <ArrowDropDownIcon/>
                    </Box>
                </Box>
                <Box
                    sx={{
                        paddingTop:'30px',
                        borderRadius:'8px',
                    }}
                >
                    {
                        modelList.map((model) => (
                            <ModelCard
                                name={model.name}
                                description={model.description}
                                type={model.model_type}
                                objective={model.model_objective}
                            />
                        ))
                    }
                </Box>
            </Box>
        </Box>
        <Drawer
            variant="persistent"
            anchor="left"
            open={openDrawer}
        >
            <Box
            sx={{
                width: '400px',
                borderTopLeftRadius:'8px',
                borderBottomLeftRadius:'8px',
                minHeight:'100%',
                boxShadow:20
            }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'12px',
                        justifyContent:'left',
                        alignItems:'center',
                        height:'64px',
                        paddingLeft:'20px'
                    }}
                >
                    <Box
                        sx={{
                            height:'30px',
                            width:'30px',
                            backgroundColor:'lightgrey',
                            borderRadius:'5px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <PsychologyIcon/>
                    </Box>
                    <Typography fontWeight='bold' variant='h7'>Vibration Forecasting (1min)</Typography>
                    <StarIcon sx={{color:'gold'}}/>
                </Box>
                <Divider/>
                <Box
                    sx={{
                        display:'flex',
                        gap:'10px',
                        alignItems:'center',
                        height:'50px',
                        padding:'10px',
                    }}
                >
                    <Button
                        sx={{
                            backgroundColor:'rgba(70, 67, 212, 1)',
                            textTransform:'none',
                            '&:hover': {
                                backgroundColor: 'rgba(70, 67, 212, 0.8)', // Hover background color
                            },
                            width:'120px'
                        }}
                        variant='contained'
                    >
                        Open
                    </Button>
                    <Button
                        sx={{
                            backgroundColor:'rgba(114, 110, 255, 0.16)',
                            color:'rgba(75, 64, 221, 1)',
                            textTransform:'none',
                            '&:hover': {
                                backgroundColor: 'rgba(114, 110, 255, 0.5)', // Hover background color
                            },
                            width:'120px'
                        }}
                        variant='contained'
                        startIcon={<SettingsIcon/>}
                    >
                        Configure
                    </Button>
                    <Button
                        sx={{
                            textTransform:'none',
                            borderColor: 'black',
                            color:'black',
                            width:'50px',
                            '&:hover': {
                                borderColor: 'black',
                                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slight background change on hover
                            },
                        }}
                        variant='outlined'
                    >
                        Share
                    </Button>
                    <Button
                        sx={{
                            textTransform:'none',
                            borderColor: 'black',
                            color:'black',
                            '&:hover': {
                                borderColor: 'black',
                                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slight background change on hover
                            },
                        }}
                        variant='outlined'
                    >
                        <Typography fontWeight='bold'>...</Typography>
                    </Button>
                </Box>      
                <Divider/>
                <Box
                    sx={{
                        padding:'20px',
                    }}
                >
                    <Typography variant='body2' fontWeight='bold'>Details</Typography>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'130px',
                                display:'flex',
                                alignItems:'center',
                            }}
                        >
                            <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Objective</Typography>
                        </Box>
                        <Box
                            sx={{
                                width:'100px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'left',
                                gap:'10px'
                            }}
                        >
                            <StormIcon sx={{color:'rgba(33, 150, 122, 1)'}} />
                            <Typography variant='body2' color='rgba(33, 150, 122, 1)'>Forecasting</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'130px',
                                display:'flex',
                                alignItems:'center',
                            }}
                        >
                            <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Model Type</Typography>
                        </Box>
                        <Box
                            sx={{
                                width:'100px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'left',
                                gap:'10px'
                            }}
                        >
                            <Typography variant='body2'>Targeted</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'130px',
                                display:'flex',
                            }}
                        >
                            <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Sensors</Typography>
                        </Box>
                        <Box
                            sx={{
                                width:'190px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'left',
                                gap:'10px',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    gap:'10px',
                                }}
                            >
                                <Box sx={{height:'10px', width:'10px', backgroundColor:'rgba(39, 174, 96, 1)'}}></Box>
                                <Typography variant='body2'>rpm_1.out</Typography>
                            </Box>
                            <Divider orientation='vertical' flexItem/>
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    gap:'10px',
                                }}
                            >
                                <Box sx={{height:'10px', width:'10px', backgroundColor:'rgba(235, 87, 87, 1)'}}></Box>
                                <Typography variant='body2'>temp_1.pv</Typography>
                            </Box>
                            <Divider orientation='vertical' flexItem/>
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    gap:'10px',
                                }}
                            >
                                <Box sx={{height:'10px', width:'10px', backgroundColor:'rgba(47, 128, 237, 1)'}}></Box>
                                <Typography variant='body2'>vib_1.out</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'130px',
                                display:'flex',
                            }}
                        >
                            <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Training time range</Typography>
                        </Box>
                        <Box
                            sx={{
                                width:'200px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'left',
                                gap:'10px'
                            }}
                        >
                            <Typography variant='body2'>May 26, 2024 at 9:44 PM <span style={{ color: 'rgba(79, 79, 79, 1)' }}>to</span> June 26, 2024 at 9:44 PM</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                            <Box
                                sx={{
                                    width:'130px',
                                    display:'flex',
                                }}
                            >
                                <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Last trained</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width:'200px',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'left',
                                    gap:'10px'
                                }}
                            >
                                <Typography variant='body2'>June 20, 2024 at 10:32 PM</Typography>
                            </Box>
                    </Box>
                </Box>
                <Divider/>
                <Box
                    sx={{
                        padding:'20px',
                    }}
                >
                    <Typography variant='body2' fontWeight='bold'>Metadata</Typography>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'130px',
                                display:'flex',
                                alignItems:'center',
                            }}
                        >
                            <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Creator</Typography>
                        </Box>
                        <Box
                            sx={{
                                width:'100px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'left',
                                gap:'10px'
                            }}
                        >
                            <Typography variant='body2'>You</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'130px',
                                display:'flex',
                            }}
                        >
                            <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Last modified</Typography>
                        </Box>
                        <Box
                            sx={{
                                width:'200px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'left',
                                gap:'10px'
                            }}
                        >
                            <Typography variant='body2'>May 26, 2024 at 9:44 PM</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'15px',
                            display:'flex',
                            gap:'10px',
                        }}
                    >
                            <Box
                                sx={{
                                    width:'130px',
                                    display:'flex',
                                }}
                            >
                                <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Created on</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width:'200px',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'left',
                                    gap:'10px'
                                }}
                            >
                                <Typography variant='body2'>June 20, 2024 at 10:32 PM</Typography>
                            </Box>
                    </Box>
                </Box>
            </Box>
        </Drawer> */}
    </Box>
  );
}
