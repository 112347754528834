import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { TableHead } from '@mui/material';

import LoadingSpinner from '../../../../components/loading-spinner';


export default function TSDataTable({data, header, loading}) {

    return (
        <div style={{height:'100%', width:'100%', paddingTop:'10px'}}>
        {
            loading &&
            <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                height:'100%',
                width:'500px'
            }}
            >
            <LoadingSpinner isLoading={true}/>
            </Box>
        }
        {
            !loading &&
            <TableContainer component={Paper} style={{ maxHeight:'200px', overflow: 'auto' }}>
                <Table stickyHeader>
                <TableHead>
                    <TableRow>
                    {header.map((col) => (
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                {col}
                            </Typography>
                        </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            hover
                        >
                            {
                            header.map((col) => (
                                <TableCell>{row[col]}</TableCell>
                            ))
                            }
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        }
      </div>
    );
  };