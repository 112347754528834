import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import dayjs from 'dayjs';

import {
  Alert,
  AlertTitle,
  IconButton,
  Divider,
  Typography,
  FormControl,
  Select,
  MenuItem, 
  Switch,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { Modal } from '@mui/base';


import MenuDrawer from '../../../components/menu-drawer';
import TSGraph from './components/line-chart';

import { Visibility as VisibilityIcon } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StormIcon from '@mui/icons-material/Storm';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EastIcon from '@mui/icons-material/East';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';

const TrainingDataCreator = () => {
  const {trainingDatasetId} = useParams('trainingDatasetId')

  const navigate = useNavigate();

  const [model, setModel] = useState({
    'model_id': '',
    'name': '',
    'model_type': '',
    'model_objective': '',
  });

  useEffect(() => {
      const getModel = async () => {
          // Make the API call using fetch
          const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=' + trainingDatasetId);

          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          // Parse the JSON data
          const result = await response.json();
          var config = result['model'];
          setModel({...config});
        };
        // Call the fetchData function
        getModel();
  }, [trainingDatasetId])
  const [visibility, setVisibility] = React.useState(null);
  React.useEffect(() => {
    const getTrainingSet = async () => {
      try{
        // Make the API call using fetch
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=' + trainingDatasetId.toString());
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        // Parse the JSON data
        const result = await response.json();
        setTrainingSet({...result['training_set']})
        var _visibility = {};
        result['training_set']['sensor_config'].forEach((sensor) => {
          _visibility[sensor.sensor_name] = true;
        })
        setVisibility({..._visibility})
      } catch (error) {
        console.error('Error:', error);
      }
    };
    if(trainingDatasetId){
      getTrainingSet();
    }
  }, [trainingDatasetId])

  const COLORS = [
    'rgba(39, 174, 96, 1)',
    'rgba(41, 128, 185, 1)',
    'rgba(231, 76, 60, 1)',
    'rgba(243, 156, 18, 1)',
    'rgba(142, 68, 173, 1)',
    'rgba(26, 188, 156, 1)',
    'rgba(241, 196, 15, 1)',
    'rgba(52, 152, 219, 1)',
    'rgba(211, 84, 0, 1)',
    'rgba(127, 140, 141, 1)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(244, 67, 54, 0.5)',
    'rgba(33, 150, 243, 0.5)',
    'rgba(76, 175, 80, 0.5)',
    'rgba(156, 39, 176, 0.5)',
    'rgba(63, 81, 181, 0.5)',
    'rgba(255, 87, 34, 0.5)',
    'rgba(205, 220, 57, 0.5)',
    'rgba(121, 85, 72, 0.5)',
    'rgba(96, 125, 139, 0.5)',
    'rgba(255, 235, 59, 0.5)',
    'rgba(139, 195, 74, 0.5)',
    'rgba(233, 30, 99, 0.5)',
    'rgba(103, 58, 183, 0.5)',
    'rgba(0, 150, 136, 0.5)',
  ]

  const [trainingSet, setTrainingSet] = React.useState({
    'training_set_id': null,
    'name': '',
    'sensor_config': [],
  });

  const [graphStatus, setGraphStatus] = React.useState({});
  React.useEffect(() => {
    var status = {...graphStatus};
    var ids = trainingSet['sensor_config'].map((sensor) => (sensor['sensor_id']));
    var hasChange = false;
    ids.forEach((id) => {
      if(!Object.keys(status).includes(id.toString())){
        status[id] = false;
        hasChange = true;
      }
    })
    if(hasChange){
      setGraphStatus({...status})
    }
  }, [trainingSet])

  const [startDate, setStartDate] = React.useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = React.useState(dayjs());

  const [sensorGraphData, setSensorGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const getSensorGraphData = async (sensorId) => {
    setGraphLoading(true);
    try{
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data_v2/' + sensorId.toString()
        + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString()
      );
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setGraphLoading(false);
      return result['data']
    } catch (error) {
      setGraphLoading(false);
      console.error('Error:', error);
    }
  };

  // React.useEffect(() => {
  //   const updateGraph = async () => {
  //     var graphDataPromises = [];
  //     var status = graphStatus;
  //     trainingSet['sensor_config'].map((sensor) => (sensor['sensor_id'])).forEach((sensorId) => {
  //       if(!status[sensorId]){
  //         const data = getSensorGraphData(sensorId);
  //         graphDataPromises.push(data);
  //         status[sensorId] = true;
  //       }
  //     })
  //     Promise.all(graphDataPromises).then((graphData) => setSensorGraphData([...sensorGraphData, ...graphData]));
  //     if(graphDataPromises.length){
  //       setGraphStatus({...status})
  //     }
  //   }
  //   updateGraph();
  // }, [graphStatus])

  const [labels, setLabels] = useState([]);

  // React.useEffect(() => {
  //   const updateGraph = async () => {
  //     const tickData = await getGraphTickData();
  //     setLabels(tickData['labels']);
  //   }
  //   if(startDate && endDate){
  //     updateGraph();
  //   }
  // }, [])

  const getGraphTickData = async () => {
    setGraphLoading(true);
    try{
      const sensorId = 1;
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/graph_data_v2/' + sensorId.toString()
        + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString()
      );
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setGraphLoading(false);
      return {
        'labels': result['labels'],
      }
    } catch (error) {
      setGraphLoading(false);
      console.error('Error:', error);
    }
  };

  const handleSensorSelection = async (sensorId) => {
    var sensorDatasetIds = trainingSet['sensor_config'] ? trainingSet['sensor_config'].map((sensor) => (sensor['sensor_id'])) : []
    if(!sensorDatasetIds.includes(sensorId)){
      const sensorImport = await getSensorMetadata(sensorId);
      var sensorImports = trainingSet['sensor_config'] ? trainingSet['sensor_config'] : []
      var config = trainingSet;
      config['sensor_config'] = [...sensorImports, sensorImport];
      var _visibility = {...visibility};
      _visibility[sensorImport.sensor_name] = true;
      setVisibility({..._visibility});
      setTrainingSet({...config});
    }
    setSelectedSensor(null);
    setAnchor(null);
  }

  const handleInputCreate = (sensorId) => {
    var sensorImports = [];
    trainingSet['sensor_config'].forEach((sensorImport) => {
      if(sensorImport['sensor_id'] == sensorId){
        sensorImport['variable_type'] = 'input';
        if(!sensorImport['interpolation_strategy']){
          sensorImport['interpolation_strategy'] = 'linear';
        }
      }
      sensorImports.push(sensorImport);
    })
    var config = trainingSet;
    config['sensor_config'] = sensorImports;
    setTrainingSet({...config});
  }

  const handleIntStrat = (sensorId, strategy) => {
    var sensorImports = [];
    trainingSet['sensor_config'].forEach((sensorImport) => {
      if(sensorImport['sensor_id'] == sensorId){
        sensorImport['interpolation_strategy'] = strategy;
      }
      sensorImports.push(sensorImport);
    })
    var config = trainingSet;
    config['sensor_config'] = sensorImports;
    setTrainingSet({...config});
  }


  const handleTargetCreate = (sensorId) => {
    var sensorImports = [];
    trainingSet['sensor_config'].forEach((sensorImport) => {
      if(sensorImport['sensor_id'] == sensorId){
        sensorImport['variable_type'] = 'target';
        if(sensorImport['interpolation_strategy']){
          sensorImport['interpolation_strategy'] = null;
        }
      }else if(sensorImport['variable_type'] && sensorImport['variable_type'] == 'target'){
        sensorImport['variable_type'] = null;
      }
      sensorImports.push(sensorImport);
    })
    var config = trainingSet;
    config['sensor_config'] = sensorImports;
    setTrainingSet({...config});
  }

  const handleOptimizationVariableType = (sensorId, sensorType) => {
    console.log(sensorType);
    var sensorConfig = trainingSet['sensor_config'];
    var newConfig = [];
    sensorConfig.forEach((sensor) => {
      if(sensor.sensor_id === sensorId){
        sensor['sensor_type'] = sensorType;
        newConfig.push(sensor);
      }else{
        newConfig.push(sensor);
      }
    })
    var config = {...trainingSet};
    config['sensor_config'] = newConfig;
    setTrainingSet({...config});
  }
  
  const getSensorMetadata = async (sensorId) => {
    try{
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/get/' + sensorId.toString());
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      const sensor = result['sensor'];
      const sensorImportData = {
        'sensor_id': sensor['sensor_id'],
        'sensor_name': sensor['name'],
        'variable_type': null,
        'interpolation_strategy': null,
        'transforms': [],
        'sensor_type': null,
      }
      return sensorImportData
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setSelectedSensor(null);
    setAnchor(anchor ? null : event.currentTarget);
  };

  const [sensorList, setSensorList] = React.useState([]);
  React.useEffect(() => {
    const getSensors = async () => {
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll');
      
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      // Parse the JSON data
      const result = await response.json();
      setSensorList(result['sensors']);
    };
    // Call the fetchData function
    getSensors();
  }, [])

  const [selectedSensor, setSelectedSensor] = React.useState(null);
  const selectSensor = (sensor) => {
    setSelectedSensor(sensor);
  }

  const handleToggleVisibility = (sensor, status) => {
    var _visibility = {...visibility};
    _visibility[sensor.sensor_name] = status;
    setVisibility({..._visibility});
  }

  const open = Boolean(anchor);

  const SensorRow = ({sensor}) => {
    var isSelected = false;
    if(selectedSensor && (sensor.name == selectedSensor.name)){
      isSelected = true;
    }
    return (
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'rgba(134, 134, 134, 0.08)',
          },
          backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'white',
        }}
      >
        <TableCell
          sx={{
            p:'20px',
            paddingRight:'60px',
            paddingTop:'20px',
            paddingBottom:'15px',
          }}
          onClick={() => selectSensor(sensor)}
        >
          <Box
            sx={{
              display:'flex',
              gap:'10px',
            }}
          >
            <Box
              sx={{
                display:'flex',
                flexDirection:'column',
                gap:'5px'
              }}
            >
              <Typography variant='body1' fontSize='0.9rem'>{sensor.name}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell
          sx={{p:'10px'}}
          onClick={() => selectSensor(sensor)}
        >
          <Typography fontWeight='bold'>{sensor.tag_id}</Typography>
        </TableCell>
        <TableCell
          sx={{p:'10px'}}
          onClick={() => selectSensor(sensor)}
        >
          <Typography>{sensor.historian_name}</Typography>
        </TableCell>
      </TableRow>
    )
  }

  const VariableCard = ({config, idx}) => {
    return (
    <Box
      sx={{
        margin:'10px',
        padding:'20px',
        backgroundColor:'white',
        borderRadius:'8px',
        border:'1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.07)',
      }}
    >
      <Box
        sx={{
          display:'flex',
          alignItems:'center',
          gap:'10px',
          justifyContent:'space-between'
        }}
      >
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            gap:'10px'
          }}
        >
          <Box
            sx={{
              width:'20px',
              height:'20px',
              borderRadius:'5px',
              backgroundColor:COLORS[idx]
            }}
            ></Box>
            <Typography variant='overline'>{config.sensor_name}</Typography>
          </Box>
          {
            visibility[config.sensor_name] &&
            <IconButton onClick={() => handleToggleVisibility(config, false)} sx={{m:0,p:0}}>
                <VisibilityIcon/>  
            </IconButton>
          }
          {
            !visibility[config.sensor_name] &&
            <IconButton onClick={() => handleToggleVisibility(config, true)} sx={{m:0,p:0}}>
                <VisibilityOffIcon/>
            </IconButton>
          }
      </Box>
      <Box
        sx={{
          border:'1px solid rgba(0, 0, 0, 0.15)',
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          marginTop:'10px',
          height:'40px',
        }}
      >
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            width:'50%',
            padding:'10px',
            gap:'10px',
            '&:hover': {
              backgroundColor: 'rgba(247, 247, 247, 0.5)',
            },
          }}
          onClick={() => handleInputCreate(config.sensor_id)}
        >
          {
            config.variable_type === 'input' &&
            <RadioButtonCheckedIcon sx={{fill:'rgba(70, 67, 212, 1)'}}/>
          }
          {
            config.variable_type !== 'input' &&
            <RadioButtonUncheckedIcon sx={{fill:'rgba(0, 0, 0, 0.25)'}}/>
          }
          <Typography fontSize='0.9rem' color={config.variable_type === 'input' ? 'rgba(70, 67, 212, 1)' : 'black'}>Input</Typography>
        </Box>
        <Divider flexItem orientation='vertical'/>
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            width:'50%',
            padding:'10px',
            gap:'10px',
            '&:hover': {
              backgroundColor: 'rgba(247, 247, 247, 0.5)',
            },
          }}
          onClick={() => handleTargetCreate(config.sensor_id)}
        >
          {
            config.variable_type === 'target' &&
            <RadioButtonCheckedIcon sx={{fill:'rgba(70, 67, 212, 1)'}}/>
          }
          {
            config.variable_type !== 'target' &&
            <RadioButtonUncheckedIcon sx={{fill:'rgba(0, 0, 0, 0.25)'}}/>
          }
          <Typography fontSize='0.9rem' color={config.variable_type === 'target' ? 'rgba(70, 67, 212, 1)' : 'black'}>Target</Typography>
        </Box>
      </Box>
      {
        config.variable_type === 'input' &&
        <Box
          sx={{
            borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
            alignItems:'center',
            marginTop:'15px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
          }}
        >
          <Typography paddingBottom='5px' fontSize='0.72rem' fontWeight='bold' >Interpolation Strategy</Typography>
          <FormControl sx={{width:'100%'}} size='small'>
            <Select
              defaultValue={'linear'}
              value={'linear'}
            >
              <MenuItem value={'linear'}>Linear</MenuItem>
            </Select>
          </FormControl>
        </Box>
      }
      {
        ((model.model_objective === 'Optimization') && (config.variable_type === 'input')) &&
        <Box
          sx={{
            borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
            alignItems:'center',
            marginTop:'15px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
          }}
        >
          <Typography paddingBottom='5px' fontSize='0.72rem' fontWeight='bold' >Sensor Type</Typography>
          <FormControl sx={{width:'100%'}} size='small'>
            <Select
              defaultValue={'control'}
              value={config.sensor_type}
              onChange={(event) => handleOptimizationVariableType(config.sensor_id, event.target.value)}
            >
              <MenuItem value={'control'}>Control</MenuItem>
              <MenuItem value={'measured_value'}>Measured Value</MenuItem>
            </Select>
          </FormControl>
        </Box>
      }
    </Box>
    )
  }

  const handleSaveButton = () => {
    saveDataTable();
  }

  const saveDataTable = async () => {
    try {
        // Make the API call using fetch
        const response = await fetch(
          'https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/save?name=' + trainingSet['name']
          + '&sensorConfig=' + JSON.stringify(trainingSet['sensor_config']) + '&trainingSetId=' + trainingSet['training_set_id']
        );

        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        if(result['success']){
          setStatusMessage({...{
            'message': 'Success!',
            'severity': 'success'
          }})
        }else{
          setStatusMessage({...{
            'message': 'Failed to save training set',
            'severity': 'error'
          }})
        }
        handleAlert();
    } catch (error) {
        console.error(error);
        setStatusMessage({...{
          'message': 'Failed to create training set',
          'severity': 'error'
        }})
        handleAlert();
    }
  };

  const [statusMessage, setStatusMessage] = React.useState({'message': '', 'severity': ''})
  const [showStatus, setShowStatus] = React.useState(false);

  const handleAlert = () => {
    setShowStatus(true);
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 3000); // Close after 5 seconds

    return () => clearTimeout(timer);
  };

  const [timeFrame, setTimeFrame] = React.useState(8);
  const [timeFrameUnits, setTimeFrameUnits] = React.useState('hour');

  return (
    <Box sx={{ display: 'flex' }}>
    <MenuDrawer/>
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Modal
          open={showStatus}
      >
        <Alert
          sx={{
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '15%',
            left: '50%',
            width: '400px'
          }}
          severity={statusMessage['severity']}
        >
          <AlertTitle>{statusMessage['severity'].toUpperCase()}</AlertTitle>
          {statusMessage['message']}
        </Alert>
      </Modal>
      <Box
        sx={{
          height:'70px',
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          paddingLeft:'10px',
          paddingRight:'10px',
        }}
      >
        <Box
          sx={{
            display:'flex',
            gap:'10px',
            justifyContent:'center',
            alignItems:'center',
            paddingBottom:'10px'
          }}
        >
          <IconButton
            sx={{
              padding:0,
              margin:0,
              height:'50px',
              width:'50px',
            }}
          >
            <ArrowBackIcon/>
          </IconButton>
          <Box
            sx={{
              paddingTop:'20px'
            }}
          >
            <Typography fontWeight='bold' variant='h7'>{model.name}</Typography>
            <Box
              sx={{
                display:'flex',
                gap:'15px',
                paddingTop:'5px',
                alignItems:'center'
              }}
            >
              <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Objective</Typography>
              <Box
                sx={{
                  display:'flex',
                  gap:'2px',
                  alignItems:'center'
                }}
              >
                {model.model_objective == 'Forecasting' ? <StormIcon sx={{color:'rgba(33, 150, 122, 1)'}} /> : model.model_objective == 'Optimization' ? <TroubleshootIcon sx={{color:'#0047AB'}}/> : <CircleNotificationsIcon sx={{color:'rgba(199, 94, 0, 1)'}}/>}
                <Typography color={model.model_objective == 'Forecasting' ? 'rgba(33, 150, 122, 1)' : model.model_objective == 'Optimization' ? '#0047AB' : 'rgba(199, 94, 0, 1)'} variant='body2'>{model.model_objective}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
              sx={{
                  backgroundColor: model.model_type == 'Generalized' ? '#F2F2F2' : 'rgba(47, 128, 237, 0.1)',
                  padding:'5px',
                  borderRadius:'5px',
                  marginLeft:'10px'
              }}
          >
              <Typography fontSize='0.8rem'>
                  {model.model_type}
              </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display:'flex',
            gap:'25px',
            height:'100%',
            alignItems:'center'
          }}
        >
          <Box
            sx={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              height:'100%',
              borderBottom: '2px solid rgba(70, 67, 212, 1)'
            }}
          >
            <Box
              sx={{
                height:'25px',
                width:'25px',
                borderRadius:'15px',
                backgroundColor:'rgba(70, 67, 212, 1)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
              }}
            >
              <Typography color='white'>1</Typography>
            </Box>
            <Typography color='rgba(70, 67, 212, 1)' variant='h7'>Training data</Typography>
          </Box>
          <ChevronRightIcon/>
          <Box
            sx={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              height:'100%',
              '&:hover': {
                borderBottom: '2px solid rgba(70, 67, 212, 0.1)'
              },
            }}
            onClick={() => navigate('/ml-factory/model-builder/config/' + trainingDatasetId)}
          >
            <Box
              sx={{
                height:'25px',
                width:'25px',
                borderRadius:'15px',
                backgroundColor:'rgba(240, 240, 240, 1)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
              }}
            >
              <Typography color='rgba(79, 79, 79, 1)'>2</Typography>
            </Box>
            <Typography color='rgba(36, 37, 42, 1)' variant='h7'>Model configuration</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            gap:'15px'
          }}
        >
          <Button
              sx={{
                backgroundColor:'rgba(36, 37, 42, 1)',
                textTransform:'none',
                '&:hover': {
                  backgroundColor: 'rgba(36, 37, 42, 0.9)', // Hover background color
                },
                width:'60px'
              }}
              variant='contained'
              onClick={handleSaveButton}
          >
              Save
          </Button>
          <Divider flexItem orientation='vertical'/>
          <Button
              sx={{
                  backgroundColor:'rgba(70, 67, 212, 1)',
                  textTransform:'none',
                  '&:hover': {
                      backgroundColor: 'rgba(70, 67, 212, 0.9)', // Hover background color
                  },
                  width:'150px'
              }}
              variant='contained'
              endIcon={<EastIcon/>}
              component={Link}
              to={'/ml-factory/model-overview/' + trainingDatasetId}
          >
              Train model
          </Button>
        </Box>
      </Box>
      <Divider/>
      <Box
        sx={{
          display:'flex',
          width:'100%'
        }}
      >
        <Box
          sx={{
            backgroundColor:'rgba(247, 247, 247, 1)',
            width:'30%',
            minHeight:'95vh'
          }}
        >
          <Box
            sx={{
              height:'50px',
              display:'flex',
              backgroundColor:'white',
              justifyContent:'space-between',
              alignItems:'center',
              paddingLeft:'20px',
              paddingRight:'20px',
            }}
          >
            <Typography fontWeight='bold' variant='h8'>Data</Typography>
          <Button
            sx={{
                textTransform:'none',
                color:'rgba(75, 64, 221, 1)'
            }}
            variant='text'
            startIcon={<AddIcon/>}
            onClick={handleClick}
          >
              Add
          </Button>
          <BasePopup placement={'right-start'} open={open} anchor={anchor}>
            <Box
              sx={{
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                borderRadius: '8px',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: '-8px', // Adjust this value to move the arrow
                  top: '3%',
                  transform: 'translateY(-50%)',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '5px 8px 5px 0',
                  borderColor: 'transparent rgba(0, 0, 0, 0.15) transparent transparent',
                },
              }}
            >
              <Box
                sx={{
                  display:'flex',
                  justifyContent:'space-between',
                  alignItems:'center',
                }}
              >
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'5px',
                    padding:'10px'
                  }}
                >
                  <SearchIcon  sx={{fill:'black'}}/>
                  <Typography color='rgba(130, 130, 130, 1)'>
                    Search...
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'5px',
                    paddingRight:'5px',
                  }}
                >
                  <Box
                      sx={{
                          borderRadius:'5px',
                          padding:'5px',
                          backgroundColor: 'rgba(55, 70, 91, 0.08)',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          gap:'10px',
                          paddingLeft:'10px',
                          paddingRight:'10px',
                          boxShadow:1
                      }}
                    >
                        <Typography fontSize='0.9rem'>All historians</Typography>
                        <ArrowDropDownIcon fontSize='1.2rem'/>
                  </Box>
                  <Divider flexItem orientation='vertical'/>
                  <CloseIcon onClick={handleClick} sx={{fill:'rgba(36, 37, 42, 1)'}}/>
                </Box>
              </Box>
              <Divider/>
              <TableContainer sx={{width:'500px', maxHeight:'400px'}}>
              <Table>
                <TableBody>
                  {
                    sensorList.map((sensor) => (
                      <SensorRow sensor={sensor}/>
                    ))
                  }
                </TableBody>
              </Table>
              </TableContainer>
              <Box
                sx={{
                  display:'flex',
                  justifyContent:'right',
                  alignItems:'center',
                  padding:'10px'
                }}
              >
                <Button
                  sx={{
                      textTransform:'none',
                      color:'rgba(75, 64, 221, 1)',
                      borderColor:'rgba(75, 64, 221, 1)',
                  }}
                  variant='outlined'
                  startIcon={<AddIcon/>}
                  onClick={() => handleSensorSelection(selectedSensor.sensor_id)}
                  disabled={!selectedSensor}
                >
                    Import
                </Button>
              </Box>
            </Box>
          </BasePopup>
          </Box>
          <Divider/>
          <Box
            sx={{
              height:'40px',
              display:'flex',
              backgroundColor:'white',
              justifyContent:'space-between',
              alignItems:'center',
              paddingLeft:'20px',
              paddingRight:'20px',
            }}
          >
            <Box
              sx={{
                  width:'75%',
                  borderRadius:'5px',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'left',
                  gap:'10px',
                  boxShadow:1
              }}
            >
                <SearchIcon  sx={{fill:'rgba(130, 130, 130, 0.9)'}}/>
                <Typography color='rgba(130, 130, 130, 1)'>
                  Search...
                </Typography>
            </Box>
            <Box
              sx={{
                display:'flex',
                padding:'5px',
                backgroundColor:'rgba(55, 70, 91, 0.08)',
                alignItems:'center',
                borderRadius:'5px'
              }}
            >
              <FilterAltIcon sx={{fill:'none', stroke:'black'}}fontSize='1rem'/>
              <ArrowDropDownIcon fontSize='3rem'/>
            </Box>
          </Box>
          <Divider/>
          {
            trainingSet.sensor_config.map((config, idx) => (
              <VariableCard config={config} idx={idx}/>
            ))
          }
        </Box>
        <Divider orientation='vertical' flexItem/>
        <Box
          sx={{
            backgroundColor:'white',
            width:'70%',
            padding:'20px'
          }}
        >
          <Box
            sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
              width:'100%',
              paddingBottom:'10px',
            }}
          >
            <Box
              sx={{
                width:'450px',
                borderRadius:'30px',
                border:'1px solid rgba(217, 217, 217, 1)',
                paddingTop:'5px',
                paddingBottom:'5px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                paddingLeft:'20px',
                paddingRight:'10px',
                gap:'20px'
              }}
            >
              <Box
                sx={{
                  display:'flex',
                  alignItems:'center'
                }}
              >
                <CalendarMonthIcon/>
                <ArrowDropDownIcon/>
              </Box>
              <Box
                sx={{
                  display:'flex',
                  gap:'1px'
                }}
              >
                <TextField
                  size='small'
                  type='number'
                  sx={{
                    width:'120px'
                  }}
                  value={timeFrame}
                  onChange={(event) => setTimeFrame(event.target.value)}
                />
                <FormControl sx={{width:'100%'}} size='small'>
                  <Select
                    value={timeFrameUnits}
                    onChange={(event) => setTimeFrameUnits(event.target.value)}
                  >
                    <MenuItem value={'minute'}>minutes</MenuItem>
                    <MenuItem value={'hour'}>hours</MenuItem>
                    <MenuItem value={'day'}>days</MenuItem>
                    <MenuItem value={'week'}>weeks</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'center'
                }}
              >
                <Typography fontWeight='bold'>Live Data</Typography>
                <Switch defaultChecked />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display:'flex',
              height:'600px'
            }}
          >
            <TSGraph
              sensorConfig={trainingSet.sensor_config}
              sensorData={sensorGraphData}
              labels={labels}
              graphStatus={graphStatus}
              visibility={visibility}
              timeFrame={timeFrame}
              timeFrameUnits={timeFrameUnits}
            />
          </Box>
        </Box>

      </Box>
    </Box>
    </Box>
  );
};

export default TrainingDataCreator;