import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Divider } from '@mui/material';
import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import CreateHistorianButton from './components/create-historian-button';
import ExploreHistorianButton from './components/explore-historian-button';
import DeleteHistorianButton from './components/delete-historian-button'
import HistorianTable from './components/historian-table';
import SearchBar from './components/search-bar'


export default function HistorianManagerHome({title, showButton, options, handleSensorImport}) {
  const [connList, setConnList] = React.useState([]);
  React.useEffect(() => {
    const getHistorians = async () => {
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/getAll');

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setConnList(result['historians']);
    };
    // Call the fetchData function
    getHistorians();
  }, [])

  const [historian, setHistorian] = React.useState(null);
  const [historianButtonStatus, setHistorianButtonStatus] = React.useState(false);
  const handleConnSelect = (historianId) => {
    setHistorian(historianId);
    if(historianId){
        setHistorianButtonStatus(true);
    }else{
        setHistorianButtonStatus(false);
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuDrawer/>
      <Box component="main" sx={{ flexGrow: 1 }}>
          <Header title={'Historian Manager'}/>
          <Box sx={{ width: '100%' }}>
            {/* <Box
              sx={{
                display:'flex',
                paddingLeft:'20px',
                paddingTop:'10px',
                paddingBottom:'10px',
              }}
            >
              <SearchBar/>
            </Box> */}
            <Grid container spacing={2}>
                <Grid item xs={1.4}>
                    <Box
                        sx={{
                            paddingBottom:'10px',
                            marginLeft: '20px',
                            paddingTop:'10px',
                        }}
                    >
                        <CreateHistorianButton text={'Connect'} link={'create'}/>
                    </Box>
                </Grid> 
                <Grid item xs={1.3}>
                    <Box
                        sx={{
                            paddingBottom:'10px',
                            paddingTop:'10px',
                        }}
                    >
                        <ExploreHistorianButton text={'Explore'} link={'explore/' + historian} disabled={!historianButtonStatus}/>
                    </Box>
                </Grid>
                <Grid item xs={1.2}>
                    <Box
                        sx={{
                            paddingBottom:'10px',
                            paddingTop:'10px',
                        }}
                    >
                        <DeleteHistorianButton text={'Delete'} link={'delete/' + historian} disabled={!historianButtonStatus}/>
                    </Box>
                </Grid> 
            </Grid>
          </Box>
          <Divider/>
          <Box
              sx={{
                padding: '50px',
                paddingTop: '30px',
              }}
            >
              <HistorianTable
                header={[
                  ['name', 'Name'],
                  ['description', 'Description'],
                  ['type', 'Type'],
                  ['auth_type', 'Authentication'],
                  ['conn_url', 'Connection URL'],
                ]}
                id_column={'historian_id'}
                data={connList}
                handleSelection={handleConnSelect}
              />
          </Box>
      </Box>
    </Box>
  );
}