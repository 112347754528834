import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box } from '@mui/system';
import NestedSelect from './components/nested-select';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, IconButton } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';

const filter = createFilterOptions();

const FunctionBuilder = ({func, sensorConfigs, transformIdx, transformConfig, getTransformConfig, saved}) => {
    const [config, setConfig] = React.useState(transformConfig);

    const handleValue1Sensor = (event, selectedOption) => {
        var transformConfig = {...config};
        transformConfig['variable_1_value'] = selectedOption ? selectedOption.value.toString() : null;
        setConfig({...transformConfig});
     }

     const handleValue1Variable = (event, selectedOption) => {
        var transformConfig = {...config};
        transformConfig['variable_1_value'] = selectedOption ? selectedOption.value.toString() : null;
        setConfig({...transformConfig});
     }

     const handleValue1Number = (event, selectedOption) => {
        var transformConfig = {...config};
        transformConfig['variable_1_value'] = selectedOption ? selectedOption.value.toString() : null;
        setConfig({...transformConfig});
     }

    const handleValue1Type = (event) => {
        var transformConfig = {...config};
        transformConfig['variable_1_value'] = null;
        transformConfig['variable_1_type'] = event.target.value ? event.target.value.toString() : null;
        setConfig({...transformConfig});
    }

    const handleValue2Sensor = (event, selectedOption) => {
        var transformConfig = {...config};
        transformConfig['variable_2_value'] = selectedOption ? selectedOption.value.toString() : null;
        setConfig({...transformConfig});
     }

     const handleValue2Variable = (event, selectedOption) => {
        var transformConfig = {...config};
        transformConfig['variable_2_value'] = selectedOption ? selectedOption.value.toString() : null;
        setConfig({...transformConfig});
     }

     const handleValue2Number = (event, selectedOption) => {
        var transformConfig = {...config};
        transformConfig['variable_2_value'] = event.target.value ? event.target.value.toString() : null;
        setConfig({...transformConfig});
     }

    const handleValue2Type = (event) => {
        var transformConfig = {...config};
        transformConfig['variable_2_value'] = null;
        transformConfig['variable_2_type'] = event.target.value ? event.target.value : null;
        setConfig({...transformConfig});
    }

    React.useEffect(() => {
        getTransformConfig(config);
    }, [config])

    const iconMap = {
        'Add': <AddIcon/>,
        'Multiply': <CloseIcon/>
    }
    const [variableOptions, setVariableOptions] = React.useState([]);
    React.useEffect(() => {
        var options = [];
        for(var i = 0; i < transformIdx; ++i){
            options.push({
                'label': `transform_${i + 1}`,
                'value': i + 1,
            })
        }
        setVariableOptions([...options]);
    }, [transformIdx])
    console.log(transformConfig['variable_1_value']);

    const [sensorList, setSensorList] = React.useState([]);
    React.useEffect(() => {
        const getSensors = () => {
          const fetchData = async () => {
                const query = 'https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll';
                // Make the API call using fetch
                const response = await fetch(query);
    
                // Check if the response is successful (status code 200-299)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                // Parse the JSON data
                const result = await response.json();
                setSensorList(result['sensors']);
          };
          // Call the fetchData function
          fetchData();
        };
        getSensors();
      }, []);

    console.log(sensorList);

    return (
        <Box
            sx={{
                display:'flex',
                alignItems:'center',
                gap:'30px'
            }}
        >
            <Box
                sx={{
                    padding:'20px',
                    backgroundColor:'white',
                    borderRadius:'8px',
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <Typography variant='overline'>
                    Value 1
                </Typography>
                <Divider/>
                {
                    <FormControl
                        sx={{
                            padding:'10px'
                        }}
                    >
                        <RadioGroup
                            row
                            onChange={handleValue1Type}
                            value={transformConfig['variable_1_type']}
                        >
                            <FormControlLabel disabled={(sensorConfigs.length == 0) || saved} value="sensor" control={<Radio />} label="Sensor" />
                            <FormControlLabel disabled={(variableOptions.length == 0) || saved} value="variable" control={<Radio />} label="Variable" />
                            <FormControlLabel disabled={saved} value="number" control={<Radio />} label="Number" />
                        </RadioGroup>
                    </FormControl>
                }
                {
                    config['variable_1_type'] == 'sensor' &&
                    <Autocomplete
                        sx={{
                            width:'100%'
                        }}
                        freeSolo
                        options={sensorConfigs.map((config) => ({'label': config['sensor_name'], 'value': config['sensor_id']}))}
                        onChange={handleValue1Sensor}
                        disabled={saved}
                        value={(sensorList.length && transformConfig['variable_1_value']) ? sensorList[parseInt(transformConfig['variable_1_value'], 10) - 1]['name']: ''}
                        renderInput={(params) => (
                            <TextField {...params} label="Sensor" variant="outlined" />
                        )}
                    />
                }
                {
                    config['variable_1_type'] == 'variable' &&
                    <Autocomplete
                        sx={{
                            width:'100%'
                        }}
                        freeSolo
                        options={variableOptions}
                        onChange={handleValue1Variable}
                        value={`transform_${transformConfig['variable_1_value']}`}
                        disabled={saved}
                        renderInput={(params) => (
                            <TextField {...params} label="Sensor" variant="outlined" />
                        )}
                    />
                }
                {
                    config['variable_1_type'] == 'number' &&
                    <TextField
                        disabled={saved}
                        value={transformConfig['variable_1_value'] ? parseFloat(transformConfig['variable_1_value'], 10): 0}
                        onChange={handleValue1Number}
                        type="number"
                    />
                }
            </Box>
            <IconButton>
                {iconMap[func]}
            </IconButton>
            <Box
                sx={{
                    padding:'20px',
                    backgroundColor:'white',
                    borderRadius:'8px',
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <Typography variant='overline'>
                    Value 2
                </Typography>
                <Divider/>
                {
                    <FormControl
                        sx={{
                            padding:'10px'
                        }}
                    >
                        <RadioGroup
                            row
                            onChange={handleValue2Type}
                            value={transformConfig['variable_2_type']}
                        >
                            <FormControlLabel disabled={(sensorConfigs.length == 0) || saved} value="sensor" control={<Radio />} label="Sensor" />
                            <FormControlLabel disabled={(variableOptions.length == 0) || saved} value="variable" control={<Radio />} label="Variable" />
                            <FormControlLabel disabled={saved} value="number" control={<Radio />} label="Number" />
                        </RadioGroup>
                    </FormControl>
                }
                {
                    config['variable_2_type'] == 'sensor' &&
                    <Autocomplete
                        sx={{
                            width:'100%'
                        }}
                        freeSolo
                        options={sensorConfigs.map((config) => ({'label': config['sensor_name'], 'value': config['sensor_id']}))}
                        onChange={handleValue2Sensor}
                        value={(sensorList.length && transformConfig['variable_2_value']) ? sensorList[parseInt(transformConfig['variable_2_value'], 10) - 1]['name']: ''}
                        disabled={saved}
                        renderInput={(params) => (
                            <TextField {...params} label="Sensor" variant="outlined" />
                        )}
                    />
                }
                {
                    config['variable_2_type'] == 'variable' &&
                    <Autocomplete
                        sx={{
                            width:'100%'
                        }}
                        freeSolo
                        options={variableOptions}
                        onChange={handleValue2Variable}
                        value={`transform_${transformConfig['variable_2_value']}`}
                        disabled={saved}
                        renderInput={(params) => (
                            <TextField {...params} label="Sensor" variant="outlined" />
                        )}
                    />
                }
                {
                    config['variable_2_type'] == 'number' &&
                    <TextField
                        disabled={saved}
                        onChange={handleValue2Number}
                        value={parseFloat(transformConfig['variable_2_value'], 10)}
                        type="number"
                    />
                }
            </Box>
        </Box>
    );
};

export default FunctionBuilder;