import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TSGraph = ({sensorConfig, graphStatus, visibility, timeFrame, timeFrameUnits}) => {
  const [chartData, setChartData] = React.useState(null);
  const [options, setOptions] = React.useState(null);

  const [sensorData, setSensorData] = React.useState(null);
  const [labels, setLabels] = React.useState(null);
  React.useEffect(() => {
    const getSensors = async () => {
        // Make the API call using fetch
        const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/graph_service/config/graph_data?sensorConfig=' + 
        JSON.stringify(sensorConfig) + '&timeFrame=' + timeFrame + '&timeFrameUnits=' + timeFrameUnits
      );
      
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      // Parse the JSON data
      const result = await response.json();
      setSensorData(result['data']);
      setLabels(result['labels'])
    };
    // Call the fetchData function
    if(sensorConfig){
      getSensors();
    }
  }, [graphStatus, timeFrame, timeFrameUnits])

  const COLORS = [
    'rgba(39, 174, 96, 1)',
    'rgba(41, 128, 185, 1)',
    'rgba(231, 76, 60, 1)',
    'rgba(243, 156, 18, 1)',
    'rgba(142, 68, 173, 1)',
    'rgba(26, 188, 156, 1)',
    'rgba(241, 196, 15, 1)',
    'rgba(52, 152, 219, 1)',
    'rgba(211, 84, 0, 1)',
    'rgba(127, 140, 141, 1)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(244, 67, 54, 0.5)',
    'rgba(33, 150, 243, 0.5)',
    'rgba(76, 175, 80, 0.5)',
    'rgba(156, 39, 176, 0.5)',
    'rgba(63, 81, 181, 0.5)',
    'rgba(255, 87, 34, 0.5)',
    'rgba(205, 220, 57, 0.5)',
    'rgba(121, 85, 72, 0.5)',
    'rgba(96, 125, 139, 0.5)',
    'rgba(255, 235, 59, 0.5)',
    'rgba(139, 195, 74, 0.5)',
    'rgba(233, 30, 99, 0.5)',
    'rgba(103, 58, 183, 0.5)',
    'rgba(0, 150, 136, 0.5)',
  ]

  React.useEffect(() => {
    if(sensorConfig && sensorData && labels){
      var __chartData = {
        labels: labels,
        datasets: sensorData.map((data, idx) => (
          {
            label: data.title,
            data: data.values,
            fill: false,
            borderColor: COLORS[idx],
            tension: 0.1,
            yAxisID: `y${idx + 1}`,
            pointRadius:0,
            hidden:!visibility[data.title]
          }
        ))
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'Time',
            },
            ticks: {
              color: 'black', // Change the x-axis ticks color
              callback: function(value, index, values) {
                const label = this.getLabelForValue(value);
                return index % 30 === 0 ? label.slice(0,11) : '';
              },
            },
          },
        },
      };

      sensorData.forEach((data, idx) => {
        __options.scales[`y${idx + 1}`] = {
          type: 'linear',
          display: visibility[data.title],
          position: 'left',
          border: {
              color: COLORS[idx]
          },
        }
      })

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [sensorConfig, sensorData, labels, visibility])

  return (
    <div style={{height:'100%', width:'100%', paddingTop:'10px'}}>
      {/* {
        loading &&
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            height:'100%',
          }}
        >
          <LoadingSpinner isLoading={true}/>
        </Box>
      } */}
      {/* {(!loading && chartData && options) && <Line data={chartData} options={options} />} */}
      {(chartData && options) && <Line data={chartData} options={options} />}
    </div>
  );
};

export default TSGraph;
