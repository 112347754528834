import * as React from 'react';
import { Typography } from '@mui/material';

export default function SensorDataHeader({}) {
    return (
        <Typography
            sx={{
                display: 'block', // Make the text display as a block
                backgroundColor: '#e0e0e0', // Light grey background color
                padding: '10px', // Add padding for spacing
                borderRadius: '5px', // Add border radius for blocky appearance
                fontSize: '16px', // Set font size
                fontWeight: 'bold', // Set font weight
                color: '#333', // Dark grey text color
            }}
        >
            Recent Data
        </Typography>
      );
}
