import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Autocomplete, Button, TextField } from '@mui/material';

import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import ItemTable from '../../../components/item-table';
import SectionHeader from './components/section-header'


export default function SensorHubIngest({title, showButton, options, handleSensorImport}) {
    const [connOptions, setConnOptions] = React.useState([]);
    const [selectedConn, setSelectedConn] = React.useState('');
    const [connTables, setConnTables] = React.useState([]);

    React.useEffect(() => {
        const getTables = async () => {
          try{
          // Make the API call using fetch
          const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/getSensorMetadata/' + selectedConn.toString());
    
          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          // Parse the JSON data
          const result = await response.json();
          console.log(result);
          setConnTables(
            result['data'].map((sensor) => (
                {
                    'label': sensor['name'],
                    'value': sensor['name'],
                }
            ))
          );
          }catch(error){
              setConnTables([]);
              console.log(error);
          }
        };
        
        // Call the fetchData function
        getTables();
    }, [selectedConn])

    React.useEffect(() => {
        const getConnections = async () => {
          // Make the API call using fetch
          const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/getAll');
    
          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          // Parse the JSON data
          const result = await response.json();

          setConnOptions(
              result['historians'].map((conn) => (
                {
                    'label': conn.name,
                    'value': conn.historian_id,
                }
              ))
            );
        };
        // Call the fetchData function
        getConnections();
    }, [])

    const handleConnectionSelect = (event, selectedTarget) => {
        if(selectedTarget){
            setSelectedConn(selectedTarget.value);
        }else{
            setSelectedConn('');
        }
    }

    const [selectedTable, setSelectedTable] = React.useState('');
    const handleTableSelect = (event, selectedTarget) => {
        if(selectedTarget){
            setSelectedTable(selectedTarget.value);
        }else{
            setSelectedTable('');
        }
    }

    const doNothing = () => {}

    const [datasetType, setDatasetType] = React.useState('');
    const handleDatasetTypeSelect = (type) => {
        setDatasetType(type);
        setTsTypes([]);
        if(type === 'object'){
            setIngestReady(true);
        }
        else{
            setIngestReady(false);
        }
    }
    
    const navigate = useNavigate();

    const CreateSensor = async () => {
        try {
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/create', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: selectedTable,
                historian_id: selectedConn,
              }),
            });
            const result = await response.json();
            if (result['success']) {
                console.log('Sensor added successfully');
                const sensor_id = result['sensor_id']
                ingestTable(sensor_id)
            } else {
                console.error('Failed to create sensor');
            }
          } catch (error) {
            console.error('Error:', error);
          }
      };

    const ingestTable = async (sensor_id) => {
        try{
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/ingest_service/ingestSensor?historianId=' + selectedConn.toString() + '&sensorId=' + sensor_id.toString());

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
      
            // Parse the JSON data
            const result = await response.json();
            console.log(result);
            if(result['success']){
                navigate('/sensor-hub');
            }else{
                console.log('Failed to ingest table')
            }

            }catch(error){
                console.log(error);
            }
    };

    const [tableHeader, setTableHeader] = React.useState([]);
    const [columnOptions, setColumnOptions] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [tableSchema, setTableSchema] = React.useState([]);
    const [ranPreview, setRanPreview] = React.useState(false);
    const previewTable = async () => {
        try{
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/ingest_service/previewSensor?historianId=' + selectedConn.toString() + '&sensorName=' + selectedTable.toString());
      
            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
      
            // Parse the JSON data
            const result = await response.json();
            setTableHeader(result.header.map((col) => {return [col, col]}));
            setTableData(result.data);
            setTableSchema(result.schema);

            const colOptions = result.header.map((col) => (
                {
                    'label': col.toString(),
                    'value': col.toString(),
                }
            ))
            setColumnOptions(colOptions);
            setRanPreview(true);
            }catch(error){
                console.log(error);
            }
    };

    const [tsTypes, setTsTypes] = React.useState([]);
    const [ingestReady, setIngestReady] = React.useState(false);
    const tsTypeMap = {
        'sensor': ['Measure Timestamp', 'Output Value'],
        'events': ['Event Start', 'Event End'],
        'records': ['Recorded Time'],
        'object': [],
        'other': [],
    };

    const handleTypeColumnInput = (event, selectedTarget, typeColName, datasetType) => {
        if(selectedTarget){
            const inputs = tsTypeMap[datasetType];
            var editedTypeColumns = [];
            tsTypes.forEach((col) => {
                if(col.typeColName !== typeColName){
                    editedTypeColumns.push({
                        'typeColName': col.typeColName,
                        'colName': col.colName
                    })
                }
            })
            editedTypeColumns.push({
                'typeColName': typeColName,
                'colName': selectedTarget.value,
            })
            setTsTypes(editedTypeColumns);
            if(inputs.length === editedTypeColumns.length){
                setIngestReady(true);
            }
        }else{
            setIngestReady(false);
        }
    }

    const [columnTypes, setColumnTypes] = React.useState([]);
    const handleColumnTypeSelection = (column, type) => {
        var colTypes = [];
        columnTypes.forEach((colData) => {
            if(colData.name !== column){
                colTypes.push(colData)
            }
        })
        colTypes.push({
            'name':column,
            'type': type,
        })
        setColumnTypes(colTypes);
    }
    

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuDrawer/>
      <Box component="main" sx={{ flexGrow: 1 }}>
          <Header title={'Sensor Hub'}/>
        <Box
            sx={{
                paddingTop: '40px',
                marginTop: '10px',
                paddingBottom: '20px',
                marginLeft: '300px',
                marginRight: '300px',
            }}
        >
            <SectionHeader
                text={'Historian'}
            />
            <Autocomplete
                sx={{
                    paddingTop:'15px'
                }}
                onChange={handleConnectionSelect}
                options={connOptions}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                )}
            />
            <Box
                sx={{
                    paddingTop:'20px'
                }}
            >
            <SectionHeader
                text={'Available Sensors'}
            />
            </Box>
            <Box
                sx={{
                    marginTop: '20px'
                }}
            >
            <Autocomplete
                sx={{
                }}
                onChange={handleTableSelect}
                options={connTables}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                )}
            />
            </Box>
            <Box
                sx={{
                    marginTop:'20px'
                }}
            >
                <Button
                    onClick={previewTable}
                    disabled={selectedTable === ''}
                >Preview</Button>
            </Box>
            {
                tableData.length > 0 &&
                <ItemTable
                header={tableHeader}
                data={tableData}
                handleSelection={doNothing}
              />
            }
            {
                ranPreview &&
                <Box
                    sx={{
                        marginTop:'20px'
                    }}
                >
                    <Button
                        onClick={CreateSensor}
                        disabled={selectedTable === ''}
                    >Ingest</Button>
                </Box>
            }
            </Box>
        </Box>
      </Box>
  );
}


