import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';

import {
  Alert,
  AlertTitle,
  IconButton,
  Divider,
  Typography,
  FormControl,
  Select,
  MenuItem, 
  Switch,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { Modal } from '@mui/base';


import MenuDrawer from '../../../components/menu-drawer';
import TSGraph from './components/line-chart';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StormIcon from '@mui/icons-material/Storm';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EastIcon from '@mui/icons-material/East';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import CloseIcon from '@mui/icons-material/Close';

const GeneralizedDataCreator = () => {
  const COLORS = [
    'rgba(39, 174, 96, 1)',
    'rgba(41, 128, 185, 1)',
    'rgba(231, 76, 60, 1)',
    'rgba(243, 156, 18, 1)',
    'rgba(142, 68, 173, 1)',
    'rgba(26, 188, 156, 1)',
    'rgba(241, 196, 15, 1)',
    'rgba(52, 152, 219, 1)',
    'rgba(211, 84, 0, 1)',
    'rgba(127, 140, 141, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(244, 67, 54, 1)',
    'rgba(33, 150, 243, 1)',
    'rgba(76, 175, 80, 1)',
    'rgba(156, 39, 176, 1)',
    'rgba(63, 81, 181, 1)',
    'rgba(255, 87, 34, 1)',
    'rgba(205, 220, 57, 1)',
    'rgba(121, 85, 72, 1)',
    'rgba(96, 125, 139, 1)',
    'rgba(255, 235, 59, 1)',
    'rgba(139, 195, 74, 1)',
    'rgba(233, 30, 99, 1)',
    'rgba(103, 58, 183, 1)',
    'rgba(0, 150, 136, 1)',
  ]

  const {modelId} = useParams('modelId')

  const navigate = useNavigate();

  const [model, setModel] = useState({
    'model_id': '',
    'name': '',
    'model_type': '',
    'model_objective': '',
  });
  const [importedSensors, setImportedSensors] = React.useState([]);
  const [importedStreams, setImportedStreams] = React.useState([]);

  useEffect(() => {
      const getModel = async () => {
          // Make the API call using fetch
          const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=' + modelId);

          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the JSON data
          const result = await response.json();
          var config = result['model'];
          setModel({...config});
          setImportedSensors(config['sensors']);
          setImportedStreams(config['streams']);
        };
        // Call the fetchData function
        getModel();
  }, [modelId])

  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setSelectedSensors([]);
    setAnchor(anchor ? null : event.currentTarget);
  };

  const [sensorList, setSensorList] = React.useState([]);
  React.useEffect(() => {
    const getSensors = async () => {
      // Make the API call using fetch
      const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll');
      
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      // Parse the JSON data
      const result = await response.json();
      setSensorList(result['sensors']);
    };
    // Call the fetchData function
    getSensors();
  }, [])

  React.useEffect(() => {
    var newList = [];
    var importedIds = importedSensors.map((sensor) => (sensor.sensor_id));
    sensorList.forEach((s) => {
      if(!importedIds.includes(s.sensor_id)){
        newList.push(s);
      }
    })
    setSensorList([...newList]);
  },[importedSensors])

  const [selectedSensors, setSelectedSensors] = React.useState([]);
  const addSensor = (sensor) => {
    const sensorIds = selectedSensors.map((s) => (s.sensor_id));
    if(!sensorIds.includes(sensor.sensor_id)){
      var currSelection = [...selectedSensors];
      currSelection.push(sensor);
      setSelectedSensors([...currSelection])
    }
    else if(sensorIds.includes(sensor.sensor_id)){
      var currSelection = [];
      selectedSensors.forEach((s) => {
        if(s.sensor_id != sensor.sensor_id){
          currSelection.push(s);
        }
      })
      setSelectedSensors([...currSelection]);
    }
  }

  const open = Boolean(anchor);

  const handleSensorImport = () => {
    var currentImports = [...importedSensors];
    selectedSensors.forEach((s) => {
      currentImports.push(s);
    })
    setImportedSensors([...currentImports]);
    var empty = [];
    setSelectedSensors([...empty]);
    setAnchor(null);
  }

  const SensorRow = ({sensor}) => {
    var isSelected = false;
    const sensorIds = selectedSensors.map((s) => (s.sensor_id));
    if(sensorIds.includes(sensor.sensor_id)){
      isSelected = true;
    }
    return (
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'rgba(134, 134, 134, 0.08)',
          },
          backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'white',
        }}
      >
        <TableCell
          sx={{
            p:'20px',
            paddingRight:'60px',
            paddingTop:'20px',
            paddingBottom:'15px',
          }}
          onClick={() => addSensor(sensor)}
        >
          <Box
            sx={{
              display:'flex',
              gap:'10px',
            }}
          >
            <Box
              sx={{
                display:'flex',
                flexDirection:'column',
                gap:'5px'
              }}
            >
              <Typography variant='body1' fontSize='0.9rem'>{sensor.name}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell
          sx={{p:'10px'}}
          onClick={() => addSensor(sensor)}
        >
          <Typography fontWeight='bold'>{sensor.tag_id}</Typography>
        </TableCell>
        <TableCell
          sx={{p:'10px'}}
          onClick={() => addSensor(sensor)}
        >
          <Typography>{sensor.historian_name}</Typography>
        </TableCell>
      </TableRow>
    )
  }

  const [selectedSensor, setSelectedSensor] = React.useState({'sensor_id': null});
  const [selectedSensorColor, setSelectedSensorColor] = React.useState('black');
  const handleSensorSelect = (sensor, color) => {
    setSelectedSensor({...sensor});
    setSelectedSensorColor(color);
  }

  const VariableCard = ({config, idx}) => {
    var isSelected = false;
    if(selectedSensor.sensor_id === config.sensor_id){
      isSelected = true;
    }
    return (
    <Box
      sx={{
        margin:'10px',
        padding:'20px',
        backgroundColor:'white',
        borderRadius:'8px',
        border:'1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.07)',
        backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.1)' : 'white',
        '&:hover': {
          backgroundColor: 'rgba(70, 67, 212, 0.1)', // Hover background color
        },
      }}
      onClick={() => handleSensorSelect(config, COLORS[idx])}
    >
      <Box
        sx={{
          display:'flex',
          alignItems:'center',
          gap:'10px',
        }}
      >
        <Box
          sx={{
            width:'20px',
            height:'20px',
            borderRadius:'5px',
            backgroundColor:COLORS[idx]
          }}
        ></Box>
        <Typography variant='overline'>{config.name}</Typography>
      </Box>
    </Box>
    )
  }

  const handleSaveButton = () => {
    saveDataTable();
  }

  const saveDataTable = async () => {
    try {
        // Make the API call using fetch
        const response = await fetch(
          'https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/generalized/saveInputs?streams=' + JSON.stringify(importedStreams)
          + '&sensors=' + JSON.stringify(importedSensors) + '&modelId=' + modelId
        );

        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        if(result['success']){
          setStatusMessage({...{
            'message': 'Success!',
            'severity': 'success'
          }})
        }else{
          setStatusMessage({...{
            'message': 'Failed to save training set',
            'severity': 'error'
          }})
        }
        handleAlert();
    } catch (error) {
        console.error(error);
        setStatusMessage({...{
          'message': 'Failed to create training set',
          'severity': 'error'
        }})
        handleAlert();
    }
  };

  const [statusMessage, setStatusMessage] = React.useState({'message': '', 'severity': ''})
  const [showStatus, setShowStatus] = React.useState(false);

  const handleAlert = () => {
    setShowStatus(true);
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 3000); // Close after 5 seconds

    return () => clearTimeout(timer);
  };

  return (
    <Box sx={{ display: 'flex' }}>
    <MenuDrawer/>
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Modal
          open={showStatus}
      >
        <Alert
          sx={{
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '15%',
            left: '50%',
            width: '400px'
          }}
          severity={statusMessage['severity']}
        >
          <AlertTitle>{statusMessage['severity'].toUpperCase()}</AlertTitle>
          {statusMessage['message']}
        </Alert>
      </Modal>
      <Box
        sx={{
          height:'70px',
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          paddingLeft:'10px',
          paddingRight:'10px',
        }}
      >
        <Box
          sx={{
            display:'flex',
            gap:'10px',
            justifyContent:'center',
            alignItems:'center',
            paddingBottom:'10px'
          }}
        >
          <IconButton
            sx={{
              padding:0,
              margin:0,
              height:'50px',
              width:'50px',
            }}
          >
            <ArrowBackIcon/>
          </IconButton>
          <Box
            sx={{
              paddingTop:'20px'
            }}
          >
            <Typography fontWeight='bold' variant='h7'>{model.name}</Typography>
            <Box
              sx={{
                display:'flex',
                gap:'15px',
                paddingTop:'5px',
                alignItems:'center'
              }}
            >
              <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Objective</Typography>
              <Box
                sx={{
                  display:'flex',
                  gap:'2px',
                  alignItems:'center'
                }}
              >
                {model.model_objective == 'Forecasting' ? <StormIcon sx={{color:'rgba(33, 150, 122, 1)'}} /> : <CircleNotificationsIcon sx={{color:'rgba(199, 94, 0, 1)'}}/>}
                <Typography color={model.model_objective == 'Forecasting' ? 'rgba(33, 150, 122, 1)' : 'rgba(199, 94, 0, 1)'} variant='body2'>{model.model_objective}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
              sx={{
                  backgroundColor: model.model_type == 'Generalized' ? '#F2F2F2' : 'rgba(47, 128, 237, 0.1)',
                  padding:'5px',
                  borderRadius:'5px',
                  marginLeft:'10px'
              }}
          >
              <Typography fontSize='0.8rem'>
                  {model.model_type}
              </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display:'flex',
            gap:'25px',
            height:'100%',
            alignItems:'center'
          }}
        >
          <Box
            sx={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              height:'100%',
              borderBottom: '2px solid rgba(70, 67, 212, 1)'
            }}
          >
            <Box
              sx={{
                height:'25px',
                width:'25px',
                borderRadius:'15px',
                backgroundColor:'rgba(70, 67, 212, 1)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
              }}
            >
              <Typography color='white'>1</Typography>
            </Box>
            <Typography color='rgba(70, 67, 212, 1)' variant='h7'>Training data</Typography>
          </Box>
          <ChevronRightIcon/>
          <Box
            sx={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              height:'100%',
              '&:hover': {
                borderBottom: '2px solid rgba(70, 67, 212, 0.1)'
              },
            }}
            onClick={() => navigate('/ml-factory/model-builder/config/' + modelId)}
          >
            <Box
              sx={{
                height:'25px',
                width:'25px',
                borderRadius:'15px',
                backgroundColor:'rgba(240, 240, 240, 1)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
              }}
            >
              <Typography color='rgba(79, 79, 79, 1)'>2</Typography>
            </Box>
            <Typography color='rgba(36, 37, 42, 1)' variant='h7'>Model configuration</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            gap:'15px'
          }}
        >
          <Button
              sx={{
                backgroundColor:'rgba(36, 37, 42, 1)',
                textTransform:'none',
                '&:hover': {
                  backgroundColor: 'rgba(36, 37, 42, 0.9)', // Hover background color
                },
                width:'60px'
              }}
              variant='contained'
              onClick={handleSaveButton}
          >
              Save
          </Button>
          <Divider flexItem orientation='vertical'/>
          <Button
              sx={{
                  backgroundColor:'rgba(70, 67, 212, 1)',
                  textTransform:'none',
                  '&:hover': {
                      backgroundColor: 'rgba(70, 67, 212, 0.9)', // Hover background color
                  },
                  width:'150px'
              }}
              variant='contained'
              endIcon={<EastIcon/>}
              component={Link}
              to={'/ml-factory/model-overview/' + modelId}
          >
              Train model
          </Button>
        </Box>
      </Box>
      <Divider/>
      <Box
        sx={{
          display:'flex',
          width:'100%'
        }}
      >
        <Box
          sx={{
            backgroundColor:'rgba(247, 247, 247, 1)',
            width:'30%',
            minHeight:'95vh'
          }}
        >
          <Box
            sx={{
              height:'50px',
              display:'flex',
              backgroundColor:'white',
              justifyContent:'space-between',
              alignItems:'center',
              paddingLeft:'20px',
              paddingRight:'20px',
            }}
          >
            <Typography fontWeight='bold' variant='h8'>Data</Typography>
          <Button
            sx={{
                textTransform:'none',
                color:'rgba(75, 64, 221, 1)'
            }}
            variant='text'
            startIcon={<AddIcon/>}
            onClick={handleClick}
          >
              Add
          </Button>
          <BasePopup placement={'right-start'} open={open} anchor={anchor}>
            <Box
              sx={{
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                borderRadius: '8px',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: '-8px', // Adjust this value to move the arrow
                  top: '30%',
                  transform: 'translateY(-50%)',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '5px 8px 5px 0',
                  borderColor: 'transparent rgba(0, 0, 0, 0.15) transparent transparent',
                },
              }}
            >
              <Box
                sx={{
                  display:'flex',
                  justifyContent:'space-between',
                  alignItems:'center',
                }}
              >
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'5px',
                    padding:'10px'
                  }}
                >
                  <SearchIcon  sx={{fill:'black'}}/>
                  <Typography color='rgba(130, 130, 130, 1)'>
                    Search...
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'5px',
                    paddingRight:'5px',
                  }}
                >
                  <Box
                      sx={{
                          borderRadius:'5px',
                          padding:'5px',
                          backgroundColor: 'rgba(55, 70, 91, 0.08)',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          gap:'10px',
                          paddingLeft:'10px',
                          paddingRight:'10px',
                          boxShadow:1
                      }}
                    >
                        <Typography fontSize='0.9rem'>All historians</Typography>
                        <ArrowDropDownIcon fontSize='1.2rem'/>
                  </Box>
                  <Divider flexItem orientation='vertical'/>
                  <CloseIcon onClick={handleClick} sx={{fill:'rgba(36, 37, 42, 1)'}}/>
                </Box>
              </Box>
              <Divider/>
              <TableContainer sx={{width:'500px', maxHeight:'400px'}}>
              <Table>
                <TableBody>
                  {
                    sensorList.map((sensor) => (
                      <SensorRow sensor={sensor}/>
                    ))
                  }
                </TableBody>
              </Table>
              </TableContainer>
              <Box
                sx={{
                  display:'flex',
                  justifyContent:'right',
                  alignItems:'center',
                  padding:'10px'
                }}
              >
                <Button
                  sx={{
                      textTransform:'none',
                      color:'rgba(75, 64, 221, 1)',
                      borderColor:'rgba(75, 64, 221, 1)',
                  }}
                  variant='outlined'
                  startIcon={<AddIcon/>}
                  onClick={() => handleSensorImport()}
                  disabled={selectedSensors.length === 0}
                >
                  {
                  selectedSensors.length > 1 ? <div>Import {selectedSensors.length} Sensors</div> :
                  selectedSensors.length === 1 ? <div>Import {selectedSensors.length} Sensor</div> :
                  <div>Import</div>}
                </Button>
              </Box>
            </Box>
          </BasePopup>
          </Box>
          <Divider/>
          <Box
            sx={{
              height:'40px',
              display:'flex',
              backgroundColor:'white',
              justifyContent:'space-between',
              alignItems:'center',
              paddingLeft:'20px',
              paddingRight:'20px',
            }}
          >
            <Box
              sx={{
                  width:'75%',
                  borderRadius:'5px',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'left',
                  gap:'10px',
                  boxShadow:1
              }}
            >
                <SearchIcon  sx={{fill:'rgba(130, 130, 130, 0.9)'}}/>
                <Typography color='rgba(130, 130, 130, 1)'>
                  Search...
                </Typography>
            </Box>
            <Box
              sx={{
                display:'flex',
                padding:'5px',
                backgroundColor:'rgba(55, 70, 91, 0.08)',
                alignItems:'center',
                borderRadius:'5px'
              }}
            >
              <FilterAltIcon sx={{fill:'none', stroke:'black'}}fontSize='1rem'/>
              <ArrowDropDownIcon fontSize='3rem'/>
            </Box>
          </Box>
          <Divider/>
          {
            importedSensors.map((config, idx) => (
              <VariableCard config={config} idx={idx}/>
            ))
          }
        </Box>
        <Divider orientation='vertical' flexItem/>
        <Box
          sx={{
            backgroundColor:'white',
            width:'70%',
            padding:'20px'
          }}
        >
          <Box
            sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
              width:'100%',
              paddingBottom:'10px',
            }}
          >
            <Box
              sx={{
                width:'450px',
                borderRadius:'30px',
                border:'1px solid rgba(217, 217, 217, 1)',
                paddingTop:'5px',
                paddingBottom:'5px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                paddingLeft:'20px',
                paddingRight:'10px',
                gap:'20px'
              }}
            >
              <Box
                sx={{
                  display:'flex',
                  alignItems:'center'
                }}
              >
                <CalendarMonthIcon/>
                <ArrowDropDownIcon/>
              </Box>
              <Box
                sx={{
                  display:'flex',
                  gap:'1px'
                }}
              >
                <TextField
                  size='small'
                  type='number'
                  sx={{
                    width:'120px'
                  }}
                  value={7}
                />
                <FormControl sx={{width:'100%'}} size='small'>
                  <Select
                    defaultValue={'day'}
                    value={'day'}
                  >
                    <MenuItem value={'day'}>days</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'center'
                }}
              >
                <Typography fontWeight='bold'>Live Data</Typography>
                <Switch defaultChecked />
              </Box>
            </Box>
            <Box
              sx={{
                display:'flex',
                gap:'10px',
                alignItems:'center',
                height:'45px'
              }}
            >
              <Box
                sx={{
                  borderRadius:'20px',
                  border:'1px solid rgba(217, 217, 217, 1)',
                  display:'flex',
                  alignItems:'center',
                  padding:'10px',
                  paddingLeft:'20px',
                  paddingRight:'20px',
                  justifyContent:'space-between'
                }}
              >
                <Typography color='rgba(31, 31, 31, 1)'>View Options</Typography>
                <ArrowDropDownIcon/>
              </Box>
              <Box
                sx={{
                  borderRadius:'20px',
                  border:'1px solid rgba(217, 217, 217, 1)',
                  display:'flex',
                  alignItems:'center',
                  padding:'10px',
                  paddingLeft:'20px',
                  paddingRight:'20px',
                  justifyContent:'space-between'
                }}
              >
                <Typography color='rgba(31, 31, 31, 1)'>Legend</Typography>
                <LegendToggleIcon/>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display:'flex',
              height:'600px'
            }}
          >
            <TSGraph sensorId={selectedSensor.sensor_id} color={selectedSensorColor}/>
          </Box>
        </Box>

      </Box>
    </Box>
    </Box>
  );
};

export default GeneralizedDataCreator;