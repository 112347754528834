import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { Box } from '@mui/system';
import { Typography, IconButton, List } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function NestedSelect({getFuncSelection}) {
  const functionOptions = [
      {
        'category_name': 'Math',
        'category_functions': [
          'Add',
          'Subtract',
          'Divide',
          'Multiply',
          'Arcsin',
          'Sin',
          'Arccos',
          'Arctan',
          'Sinh',
          'Cosh',
          'Tanh',
          'Modulus',
          'Cos',
          'Tan',
          'Min',
          'Max',
          'Power',
        ]
      },
      {
        'category_name': 'Rolling Aggregate',
        'category_functions': [
          'Mean',
          'Min',
          'Max',
          'Median',
          'Sum',
          'Standard Deviation',
          'Variance',
        ]
      },
      {
        'category_name': 'Cumulative Aggregate',
        'category_functions': [
          'Mean',
          'Min',
          'Max',
          'Median',
          'Sum',
          'Standard Deviation',
          'Variance',
        ]
      },
      {
        'category_name': 'Transform',
        'category_functions': [
          'Time Shift',
          'Box Cox',
          'Resample',
          'Interpolate'
        ]
      },
  ]


  const [expand, setExpand] = React.useState({
    'Math': false,
    'Rolling Aggregate': false,
    'Cumulative Aggregate': false,
    'Transform': false,
  });

  const [expandList, setExpandList] = React.useState(false);
  const handleExpandList = () => {
    setExpandList(true);
  }
  const handleCloseList = () => {
    setExpandList(false);
  }

  const expandCategory = (category) => {
    var config = {...expand};
    config[category] = true;
    setExpand({...config});
  }

  const closeCategory = (category) => {
    var config = {...expand};
    config[category] = false;
    setExpand({...config});
  }

  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [selectedFunction, setSelectedFunction] = React.useState('');
  const handleSelect = (category, func) => {
    setSelectedCategory(category);
    setSelectedFunction(func);
    setExpandList(false);
    var config = {...expand};
    Object.keys(config).forEach((cat) => {
      config[cat] = false;
    })
    setExpand({...config});
    getFuncSelection(category, func);
  }

  return (
    <Box
    >
      <List>
      <ListSubheader
        sx={{
          backgroundColor:'#120e2e'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Center items horizontally
            justifyContent: 'space-between', // Space items evenly
          }}
        >
          <Box>
            <Typography color='white' variant='overline'>Select Function</Typography>
          </Box>
          {
            !expandList &&
            <IconButton
              sx={{
                color:'white'
              }}
              onClick={handleExpandList}
            >
              <ExpandMore/>
            </IconButton>
          }
          {
            expandList &&
            <IconButton
              sx={{
                color:'white'
              }}
              onClick={handleCloseList}
            >
              <ExpandLess/>
            </IconButton>
          }
        </Box>
      </ListSubheader>
        <Box
          sx={{
            maxHeight: '300px', // Maximum height of the list
            overflow: 'auto',   // Enable scrolling when content exceeds the maximum height
            borderRight: '1px solid #120e2e', // Optional border for visualization
            borderLeft: '1px solid #120e2e', // Optional border for visualization
            border: '1px solid #120e2e', // Optional border for visualization
          }}
        >
        {
          expandList &&
          functionOptions.map((option, cat_idx) => (
            <div>
            <ListSubheader>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Center items horizontally
                  justifyContent: 'space-between', // Space items evenly
                }}
              >
                <Box>
                  {option.category_name}
                </Box>
                {
                  !expand[option.category_name] &&
                  <IconButton
                    onClick={() => expandCategory(option.category_name)}
                  >
                    <ExpandMore/>
                  </IconButton>
                }
                {
                  expand[option.category_name] &&
                  <IconButton
                    onClick={() => closeCategory(option.category_name)}
                  >
                    <ExpandLess/>
                  </IconButton>
                }
              </Box>
            </ListSubheader>
            {
              expand[option.category_name] && option.category_functions.map((func, func_idx) => (
                <MenuItem sx={{}}onClick={() => handleSelect(option.category_name, func)} value={option}><Typography variant='subtitle2'>{func}</Typography></MenuItem>
              ))
            }
            </div>
          ))
        }
      </Box>
      </List>
    </Box>
  );
}