import React from 'react';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import FunctionsIcon from '@mui/icons-material/Functions';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import NestedSelect from './nested-select';
import FunctionBuilder from '../function-builder';

const TransformBuilder = ({transformMd, sensorConfigs, getTransformMd, transformIdx}) => {
    
    const [md, setMd] = React.useState(transformMd);
    
    const [transName, setTransName] = React.useState(md.transform_name);
    const handleNameInput = (event) => {
        setTransName(event.target.value);
    }

    const getFuncSelection = (category, func) => {
        var metadata = {...md};
        metadata['function_category'] = category;
        metadata['function_name'] = func;
        setMd({...metadata})
    }

    const handleDelete = () => {}

    const handleSave = () => {
        getTransformMd(md);
        setSaved(true);
    }

    const handleEdit = () => {
        setSaved(false);
    }

    const [saved, setSaved] = React.useState(false);

    const getTransformConfig = (config) => {
        var metadata = {...md};
        metadata['transform_config'] = config;
        setMd({...metadata});
    }

    console.log(md);
  return (
    <Box
        sx={{
            marginTop:'20px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
    >
        <Box
            sx={{
                height:'40px',
                backgroundColor:'#c8d2e3',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                paddingLeft:'20px',
                padding:'5px',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <IconButton>
                    <FunctionsIcon/>
                </IconButton>
                <TextField variant='filled' sx={{color:'white'}} size='small' value={transName} onChange={handleNameInput}/>
            </Box>
            <Box>
                <IconButton>
                    <EditIcon
                        onClick={handleEdit}
                        disabled={!saved}
                    />
                </IconButton>
                <IconButton>
                    <SaveIcon
                        onClick={handleSave}
                        disabled={saved}
                    />
                </IconButton>
                <IconButton>
                    <DeleteIcon/>
                </IconButton>
            </Box>
        </Box>
        <Box
            alignItems="center"
            justifyContent="center"
            padding="20px"
        >
            {
                (!md.function_category && !saved) &&
                <NestedSelect
                    getFuncSelection={getFuncSelection}
                />
            }
            {
                md.function_category &&
                <FunctionBuilder
                    sensorConfigs={sensorConfigs}
                    func={md.function_name}
                    transformConfig={md.transform_config}
                    transformIdx={transformIdx}
                    getTransformConfig={getTransformConfig}
                    saved={saved}
                />
            }
        </Box>
        </Box>
  );
};

export default TransformBuilder;