import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const CreateHistorianButton = ({text, handleOnClick, link}) => {
  return (
    <Button
      variant="contained"
      style={{
        borderRadius: '5px',
        fontSize: '14px',
        backgroundColor: '#0F1354',
        display: 'flex',
        height: '30px',
      }}
      onClick={handleOnClick}
      component={Link}
      to={link}
      startIcon={<AddCircleIcon />}
    >
          {text}
    </Button>
  );
};

export default CreateHistorianButton;