import * as React from 'react';
import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import { useParams } from 'react-router-dom';
import { Modal, Box, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import HistorianDeletionForm from './components/deletion-form';
import HistorianMetadataSection from './components/historian-metadata';

export default function HistorianManagerExplore({onDelete, onClose}) {
    const {historianId} = useParams('historianId');

    const [historian, setHistorian] = React.useState(null);
    React.useEffect(() => {
        const getHistorian = async () => {
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/get/' + historianId);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data
            const result = await response.json();
            setHistorian(result['historian']);
        };
        if(historianId && historianId !== ''){
            getHistorian();
        }
    }, [historianId]);

    return (
        <Box sx={{ display: 'flex' }}>
          <MenuDrawer/>
          <Box component="main" sx={{ flexGrow: 1 }}>
              <Header title={'Historian Manager'}/>
              {
                historian &&
                <Box
                    sx={{
                        marginLeft:'200px',
                        marginRight:'200px'
                    }}
                    >
                    <Box
                        sx={{
                            paddingTop:'50px',
                            paddingBottom:'40px'
                        }}
                    >
                        <HistorianMetadataSection
                            historian={historian}
                        />
                    </Box>
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            p: 4,
                            boxShadow: 20
                        }}
                    >
                        <HistorianDeletionForm
                            onDelete={() => {}}
                            onClose={() => {}}
                        />
                    </Box>
                </Box>
            }
          </Box>
        </Box>
      );
}
