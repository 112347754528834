import * as React from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Modal,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StormIcon from "@mui/icons-material/Storm";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import { useNavigate } from "react-router-dom";

export default function CreateModal({ open, handleModalState }) {
  const navigate = useNavigate();

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const now = new Date();
  const formattedDate = now
    .toLocaleDateString("en-US", options)
    .replace(",", "")
    .split(",")[0];
  const time = now
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(" ", "");
  const [name, setName] = React.useState(
    `New Model - ${formattedDate} at ${time}`
  );
  const handleNameChange = (newName) => {
    setName(newName);
  };

  const [desc, setDesc] = React.useState(null);
  const handleDescChange = (newDesc) => {
    setDesc(newDesc);
  };

  const [objective, setObjective] = React.useState("Anomaly Detection");
  const handleObjSelect = (obj) => {
    setObjective(obj);
    setModelType("Targeted");
  };

  const [modelType, setModelType] = React.useState("Targeted");
  const handleTypeSelect = (type) => {
    setModelType(type);
  };

  const createModel = async () => {
    try {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/create?name=" +
          name.toString() +
          "&modelType=" +
          modelType.toString() +
          "&modelObjective=" +
          objective.toString() +
          "&description=" +
          desc
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      if (result["success"]) {
        if (modelType == "Generalized") {
          navigate(
            "/ml-factory/model-builder/training-data/generalized/" +
              result["model_id"]
          );
        } else {
          navigate(
            "/ml-factory/model-builder/training-data/" + result["model_id"]
          );
        }
      } else {
        console.log("Failed to create model");
        handleModalState(false);
      }
    } catch (error) {
      console.error("Error:", error);
      handleModalState(false);
    }
  };

  const createDataTable = async () => {
    try {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/create?name=" +
          name.toString() +
          "&sensorConfig=" +
          JSON.stringify([])
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      if (result["success"]) {
        createModel();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [canSave, setCanSave] = React.useState(false);
  React.useEffect(() => {
    if (!name || !desc || !objective || !modelType) {
      setCanSave(false);
    } else if (
      name === "" ||
      desc === "" ||
      objective === "x" ||
      modelType === ""
    ) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [name, desc, objective, modelType]);

  return (
    <Modal
      open={open}
      onClose={() => handleModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "800px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontWeight="bold">Create model</Typography>
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              >
                <CancelIcon onClick={() => handleModalState(false)} />
              </IconButton>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "20px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Name<span style={{ color: "rgba(255,20,20)" }}>*</span>
              </Typography>
              <TextField
                autoFocus
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={(e) => handleNameChange(e.target.value)}
                value={name}
                placeholder="Enter name..."
                size="small"
                sx={{ width: "100%" }}
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Description<span style={{ color: "rgba(255,20,20)" }}>*</span>
              </Typography>
              <TextField
                onChange={(e) => handleDescChange(e.target.value)}
                value={desc}
                placeholder="Enter description..."
                size="small"
                sx={{ width: "100%" }}
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "16px",
                paddingTop: "8px",
                display: "flex",
                gap: "8px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">Objective</Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  width: "100%",
                  height: "124px",
                }}
              >
                <Box
                  sx={{
                    width: "33%",
                    border:
                      objective && objective == "Anomaly Detection"
                        ? "1px solid rgba(70, 67, 212, 1)"
                        : "1px solid rgba(0, 0, 0, 0.15)",
                    boxShadow:
                      objective && objective == "Anomaly Detection"
                        ? "0px 0px 0px 2px rgba(114, 110, 255, 0.16)"
                        : "0px 0px 0px rgba(114, 110, 255, 0)",
                    height: "100%",
                    borderRadius: "4px",
                    "&:hover": {
                      color: "rgb(70, 67, 212)",
                      border: "1px solid rgba(70, 67, 212, 1)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleObjSelect("Anomaly Detection")}
                >
                  <Box
                    sx={{
                      padding: "0px 12px",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "12px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircleNotificationsIcon
                          sx={{ color: "rgba(199, 94, 0, 1)" }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      {objective && objective == "Anomaly Detection" ? (
                        <CheckCircleIcon
                          sx={{ fill: "rgba(70, 67, 212, 1)" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ padding: "4px 16px" }}>
                    <Typography
                      fontWeight="bold"
                      color="rgba(199, 94, 0, 1)"
                      paddingBottom="4px"
                    >
                      Anomaly Detection
                    </Typography>
                    <Typography color="rgba(79, 79, 79, 1)" fontSize="14px">
                      Predict current values
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "33%",
                    border:
                      objective && objective == "Forecasting"
                        ? "1px solid rgba(70, 67, 212, 1)"
                        : "1px solid rgba(0, 0, 0, 0.15)",
                    boxShadow:
                      objective && objective == "Forecasting"
                        ? "0px 0px 0px 2px rgba(114, 110, 255, 0.16)"
                        : "0px 0px 0px rgba(114, 110, 255, 0)",
                    height: "100%",
                    borderRadius: "4px",
                    "&:hover": {
                      color: "rgb(70, 67, 212)",
                      border: "1px solid rgba(70, 67, 212, 1)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleObjSelect("Forecasting")}
                >
                  <Box
                    sx={{
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "12px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <StormIcon sx={{ color: "rgba(33, 150, 122, 1)" }} />
                      </Box>
                    </Box>
                    <Box>
                      {objective && objective == "Forecasting" ? (
                        <CheckCircleIcon
                          sx={{ fill: "rgba(70, 67, 212, 1)" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "4px 16px",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      color="rgba(33, 150, 122, 1)"
                      paddingBottom="4px"
                    >
                      Forecasting
                    </Typography>
                    <Typography color="rgba(79, 79, 79, 1)" fontSize="14px">
                      Predict future values
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "33%",
                    border:
                      objective && objective == "Optimization"
                        ? "1px solid rgba(70, 67, 212, 1)"
                        : "1px solid rgba(0, 0, 0, 0.15)",
                    boxShadow:
                      objective && objective == "Optimization"
                        ? "0px 0px 0px 2px rgba(114, 110, 255, 0.16)"
                        : "0px 0px 0px rgba(114, 110, 255, 0)",
                    height: "100%",
                    borderRadius: "4px",
                    "&:hover": {
                      color: "rgb(70, 67, 212)",
                      border: "1px solid rgba(70, 67, 212, 1)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleObjSelect("Optimization")}
                >
                  <Box
                    sx={{
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "12px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <TroubleshootIcon sx={{ color: "#0047AB" }} />
                      </Box>
                    </Box>
                    <Box>
                      {objective && objective == "Optimization" ? (
                        <CheckCircleIcon
                          sx={{ fill: "rgba(70, 67, 212, 1)" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ padding: "4px 16px" }}>
                    <Typography
                      fontWeight="bold"
                      color="#0047AB"
                      paddingBottom="4px"
                    >
                      Optimization
                    </Typography>
                    <Typography
                      color="rgba(79, 79, 79, 1)"
                      fontSize="14px"
                      lineHeight="18px"
                    >
                      Optimize for a target output value
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "16px",
                paddingTop: "8px",
                display: "flex",
                gap: "4px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">Model type</Typography>
              <Box
                sx={{
                  paddingTop: "4px",
                  display: "flex",
                  gap: "8px",
                  "&:hover": {
                    color: "rgb(70, 67, 212)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTypeSelect("Targeted")}
              >
                {modelType && modelType == "Targeted" ? (
                  <RadioButtonCheckedIcon
                    sx={{ fill: "rgba(70, 67, 212, 1)" }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
                <Box>
                  <Typography
                    fontWeight="bold"
                    color={
                      modelType && modelType == "Targeted"
                        ? "rgba(70, 67, 212, 1)"
                        : "black"
                    }
                  >
                    Targeted
                  </Typography>
                  <Typography color="rgba(79, 79, 79, 1)" fontSize="14px">
                    Select input variables to predict a target variable
                  </Typography>
                </Box>
              </Box>
              {!(objective && objective == "Optimization") && (
                <Box
                  sx={{
                    paddingTop: "4px",
                    display: "flex",
                    gap: "8px",
                    "&:hover": {
                      color: "rgb(70, 67, 212)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleTypeSelect("Generalized")}
                >
                  {modelType && modelType == "Generalized" ? (
                    <RadioButtonCheckedIcon
                      sx={{ fill: "rgba(70, 67, 212, 1)" }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                  <Box>
                    <Typography
                      className="modelTypeTitle"
                      fontWeight="bold"
                      color={
                        modelType && modelType == "Generalized"
                          ? "rgba(70, 67, 212, 1)"
                          : "black"
                      }
                    >
                      Generalized
                    </Typography>
                    <Typography color={"rgba(79, 79, 79, 1)"} fontSize="14px">
                      Select a group of variables to apply on any sensor or
                      stream
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            gap: "5px",
            padding: "20px 24px",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              borderColor: "rgba(55, 70, 91, 0.32)",
              color: "black",
              width: "50px",
              "&:hover": {
                borderColor: "rgba(55, 70, 91, 0.32)",
                backgroundColor: "rgba(55, 70, 91, 0.05)", // Slight background change on hover
              },
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "rgba(33, 150, 83, 1)",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(33, 150, 83, 0.95)", // Hover background color
              },
            }}
            variant="contained"
            onClick={createDataTable}
            disabled={!canSave}
          >
            Create model
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
