import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Divider,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { Modal } from "@mui/base";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CancelIcon from "@mui/icons-material/Cancel";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import StreamIcon from "@mui/icons-material/Stream";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ForwardIcon from "@mui/icons-material/Forward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import MenuDrawer from "../../../components/menu-drawer";
import TSGraph from "./components/line-chart";
import SensorReplacementModal from "./components/sensor-replacement-modal";
import LoadingSpinner from "../../../components/loading-spinner";

const ModelTesting = () => {
  const COLORS = [
    "rgba(39, 174, 96, 0.8)",
    "rgba(41, 128, 185, 0.8)",
    "rgba(231, 76, 60, 0.8)",
    "rgba(243, 156, 18, 0.8)",
    "rgba(142, 68, 173, 0.8)",
    "rgba(26, 188, 156, 0.8)",
    "rgba(241, 196, 15, 0.8)",
    "rgba(52, 152, 219, 0.8)",
    "rgba(211, 84, 0, 0.8)",
    "rgba(127, 140, 141, 0.8)",
    "rgba(255, 99, 132, 0.5)",
    "rgba(54, 162, 235, 0.5)",
    "rgba(255, 206, 86, 0.5)",
    "rgba(75, 192, 192, 0.5)",
    "rgba(153, 102, 255, 0.5)",
    "rgba(255, 159, 64, 0.5)",
    "rgba(244, 67, 54, 0.5)",
    "rgba(33, 150, 243, 0.5)",
    "rgba(76, 175, 80, 0.5)",
    "rgba(156, 39, 176, 0.5)",
    "rgba(63, 81, 181, 0.5)",
    "rgba(255, 87, 34, 0.5)",
    "rgba(205, 220, 57, 0.5)",
    "rgba(121, 85, 72, 0.5)",
    "rgba(96, 125, 139, 0.5)",
    "rgba(255, 235, 59, 0.5)",
    "rgba(139, 195, 74, 0.5)",
    "rgba(233, 30, 99, 0.5)",
    "rgba(103, 58, 183, 0.5)",
    "rgba(0, 150, 136, 0.5)",
  ];

  const { modelId } = useParams("modelId");
  const [altSensorMap, setAltSensorMap] = React.useState({});
  const navigate = useNavigate();

  const [enableOneShot, setEnableOneShot] = React.useState(false);

  const [model, setModel] = useState({
    model_id: "",
    name: "",
    model_type: "",
    model_objective: "",
    sensors: [],
  });
  const [importedSensors, setImportedSensors] = React.useState([]);
  const [importedStreams, setImportedStreams] = React.useState([]);
  const [inputs, setInputs] = React.useState([]);
  const [output, setOutput] = React.useState(null);
  useEffect(() => {
    const getModel = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=" +
          modelId
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      var config = result["model"];
      setModel({ ...config });
      setImportedSensors(config["sensors"]);
      setImportedStreams(config["streams"]);
    };
    // Call the fetchData function
    getModel();
  }, [modelId]);

  const [trainingSet, setTrainingSet] = React.useState({
    training_set_id: null,
    name: "",
    sensor_config: [],
  });
  React.useEffect(() => {
    const getTrainingSet = async () => {
      try {
        // Make the API call using fetch
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=" +
            modelId.toString()
        );
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        setTrainingSet({ ...result["training_set"] });
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (modelId) {
      getTrainingSet();
    }
  }, [modelId]);

  React.useEffect(() => {
    if (model.model_type === "Targeted") {
      var _inputs = [];
      var _output = null;
      trainingSet["sensor_config"].forEach((s) => {
        if (s.variable_type === "input") {
          _inputs.push(s);
        } else {
          _output = s;
        }
      });
      setOutput({ ..._output });
      setInputs([..._inputs]);
    } else {
      setInputs([...model.sensors]);
      setOutput({ ...model.sensors[0] });
    }
  }, [model, trainingSet]);

  const [sensorList, setSensorList] = React.useState([]);
  React.useEffect(() => {
    const getSensors = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll"
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setSensorList(result["sensors"]);
    };
    // Call the fetchData function
    getSensors();
  }, []);

  React.useEffect(() => {
    var newList = [];
    var importedIds = importedSensors.map((sensor) => sensor.sensor_id);
    sensorList.forEach((s) => {
      if (!importedIds.includes(s.sensor_id)) {
        newList.push(s);
      }
    });
    setSensorList([...newList]);
  }, [importedSensors]);

  const [openModal, setOpenModal] = React.useState(true);
  const handleModalState = (status, config, color) => {
    setOpenModal(status);
    setAltSensor(config);
    setAltSensorColor(color);
  };

  const [altSensor, setAltSensor] = React.useState(null);
  const [altSensorColor, setAltSensorColor] = React.useState(null);
  const VariableCard = ({ config, idx }) => {
    var name =
      model.model_type === "Targeted" ? config.sensor_name : config.name;
    var showReset = false;
    if (config.sensor_id in altSensorMap) {
      name = altSensorMap[config.sensor_id].name;
      showReset = true;
    }
    return (
      <Box
        sx={{
          margin: "8px",
          padding: "12px 16px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.07)",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                backgroundColor: COLORS[idx],
              }}
            ></Box>
            <Typography
              color={showReset ? "rgba(75, 64, 221, 1)" : "black"}
              variant="overline"
            >
              {name}
            </Typography>
            {showReset && (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => resetAltSensor(config)}
              >
                <RotateLeftIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {((!showReset && config.variable_type === "target") ||
              (!showReset && model.model_type === "Generalized")) && (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => handleModalState(true, config, COLORS[idx])}
              >
                <FindReplaceIcon />
              </IconButton>
            )}
            <IconButton sx={{ m: 0, p: 0 }}>
              {config.variable_type === "input" && <VisibilityOffIcon />}
              {config.variable_type === "target" && <VisibilityIcon />}
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const [testPredictions, setTestPredictions] = React.useState(null);
  const [testActuals, setTestActuals] = React.useState(null);
  const [testLabels, setTestLabels] = React.useState(null);
  const [targetName, setTargetName] = React.useState(null);
  const [rmse, setRmse] = React.useState(null);
  const [mae, setMae] = React.useState(null);
  const [ymin, setYmin] = React.useState(null);
  const [ymax, setYmax] = React.useState(null);
  const [startDate, setStartDate] = React.useState(dayjs().subtract(7, "day"));
  const [endDate, setEndDate] = React.useState(dayjs());
  const getPredictions = async () => {
    try {
      if (output) {
        var sensor_id = output.sensor_id;
        if (sensor_id in altSensorMap) {
          sensor_id = altSensorMap[sensor_id]["sensor_id"];
        }

        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/generalized/getTestPredictions?modelId=" +
            model.model_id +
            "&sensorId=" +
            sensor_id +
            "&enableOneShot=" +
            "true" +
            "&startDate=" +
            startDate.toISOString() +
            "&endDate=" +
            endDate.toISOString() +
            "&forecastIndex=" +
            forecastIndex
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setTestPredictions(result["predicted"]);
        setTestActuals(result["actuals"]);
        setTestLabels(result["labels"]);
        setTargetName(result["target"]);
        setRmse(result["rmse"]);
        setMae(result["mae"]);
        setYmin(result["y_min"]);
        setYmax(result["y_max"]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  React.useEffect(() => {
    if (output) {
      getPredictions();
    }
  }, [output]);

  const handleSaveButton = () => {
    saveDataTable();
  };

  const saveDataTable = async () => {
    try {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/generalized/saveInputs?streams=" +
          JSON.stringify(importedStreams) +
          "&sensors=" +
          JSON.stringify(importedSensors) +
          "&modelId=" +
          modelId
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      if (result["success"]) {
        setStatusMessage({
          ...{
            message: "Success!",
            severity: "success",
          },
        });
      } else {
        setStatusMessage({
          ...{
            message: "Failed to save training set",
            severity: "error",
          },
        });
      }
      handleAlert();
    } catch (error) {
      console.error(error);
      setStatusMessage({
        ...{
          message: "Failed to create training set",
          severity: "error",
        },
      });
      handleAlert();
    }
  };

  const [statusMessage, setStatusMessage] = React.useState({
    message: "",
    severity: "",
  });
  const [showStatus, setShowStatus] = React.useState(false);

  const handleAlert = () => {
    setShowStatus(true);
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 3000); // Close after 5 seconds

    return () => clearTimeout(timer);
  };

  const getAltSensor = (sensor, altSensor) => {
    var config = { ...altSensorMap };
    config[sensor.sensor_id] = altSensor;
    setAltSensorMap({ ...config });
  };
  const resetAltSensor = (sensor) => {
    var config = { ...altSensorMap };
    if (sensor.sensor_id in altSensorMap) {
      delete config[sensor.sensor_id];
    }
    setAltSensorMap({ ...config });
  };

  const [forecastIndex, setForecastIndex] = React.useState(1);

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box
        component="main"
        sx={{
          flex: "1",
          height: "100vh",
        }}
      >
        {altSensor && model.model_type === "Targeted" && (
          <SensorReplacementModal
            type={altSensor.variable_type}
            color={altSensorColor}
            config={altSensor}
            open={openModal}
            handleModalState={handleModalState}
            getAltSensor={getAltSensor}
          />
        )}
        {altSensor && model.model_type === "Generalized" && (
          <SensorReplacementModal
            type={altSensor.variable_type}
            color={altSensorColor}
            config={altSensor}
            open={openModal}
            handleModalState={handleModalState}
            getAltSensor={getAltSensor}
          />
        )}
        <Modal open={showStatus}>
          <Alert
            sx={{
              transform: "translate(-50%, -50%)",
              position: "absolute",
              top: "15%",
              left: "50%",
              width: "400px",
            }}
            severity={statusMessage["severity"]}
          >
            <AlertTitle>{statusMessage["severity"].toUpperCase()}</AlertTitle>
            {statusMessage["message"]}
          </Alert>
        </Modal>
        <Box
          className="topbar"
          sx={{
            backgroundColor: "white",
            boxShadow: "inset 0px -1px 0px 0px rgba(0, 0, 0, 0.15)",
            height: "44px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <StreamIcon sx={{ p: 0, m: 0, fill: "rgb(130, 130, 130)" }} />
              <Typography
                sx={{ color: "rgba(130, 130, 130, 1)", fontSize: "14px" }}
              >
                Models
              </Typography>
            </Box>
            <Typography fontSize="1.4rem" color="rgba(130, 130, 130, 1)">
              /
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FolderZipIcon
                sx={{ p: 0, m: 0, fill: "rgba(170, 0, 122, 1)" }}
              />
              <Typography
                sx={{ pt: "2px", fontWeight: "bold", fontSize: "14px" }}
              >
                {model.name}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box
              sx={{
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <Box
                sx={{
                  padding: "10px 0px",
                  marginRight: "16px",
                  "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  navigate(`/ml-factory/model-overview/${modelId}`)
                }
              >
                <Typography fontSize="14px">Overview</Typography>
              </Box>
              <Box
                sx={{
                  boxShadow: "0px 2px 0px rgba(70, 67, 212)",
                  padding: "10px 0px",
                  marginRight: "16px",
                }}
                onClick={() => navigate(`/ml-factory/model-testing/${modelId}`)}
              >
                <Typography fontSize="14px" color="rgba(70, 67, 212)">
                  Testing
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px",
                  marginRight: "16px",
                  "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(`/ml-factory/model-sandbox/${modelId}`)}
              >
                <Typography fontSize="14px">Sandbox</Typography>
              </Box>
              <Box
                sx={{
                  padding: "10px 0px",
                  marginRight: "8px",
                  "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                  },
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography fontSize="14px">Deployments</Typography>
                <Box
                  sx={{
                    pl: "5px",
                    pr: "5px",
                    backgroundColor: "rgba(36, 37, 42, 0.1)",
                    borderRadius: "5px",
                  }}
                >
                  <Typography fontSize="12px">0</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              paddingRight: "20px",
            }}
          >
            <Box
              sx={{
                border: "1px solid rgba(55, 70, 91, 0.32)",
                padding: "3px",
                paddingLeft: "10px",
                paddingRight: "5px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                borderRadius: "4px",
              }}
            >
              <Typography fontSize="0.8rem" color="rgba(33, 43, 56, 1)">
                Version 1.0
              </Typography>
              <ArrowDropDownIcon sx={{ fill: "rgba(33, 43, 56, 1)" }} />
            </Box>
            <Divider flexItem orientation="vertical" />
            <ShareIcon />
            <MoreHorizIcon />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "calc(100% - 44px)",
          }}
        >
          <Box
            className="sidepanel"
            sx={{
              width: "360px",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: "1px solid lightgray",
            }}
          >
            <Box
              sx={{
                height: "50px",
                display: "flex",
                backgroundColor: "white",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderBottom: "1px solid lightgray",
              }}
            >
              <Typography fontWeight="bold" variant="h8">
                Configuration
              </Typography>
              <Button
                onClick={getPredictions}
                startIcon={<PlayCircleIcon />}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                Run
              </Button>
            </Box>
            <Box
              sx={{
                overflow: "scroll",
                height: "100%",
              }}
            >
              {model.model_objective === "Forecasting" && (
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                      Forecast Index
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      padding: "0px 20px 16px 20px",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                    >
                      <TextField
                        value={forecastIndex}
                        onChange={(event) =>
                          event.target.value <= model.forecast_count &&
                          event.target.value >= 1
                            ? setForecastIndex(event.target.value)
                            : setForecastIndex(forecastIndex)
                        }
                        defaultValue={1}
                        sx={{ width: "30%", backgroundColor: "white" }}
                        type="number"
                        size="small"
                      />
                      <Typography
                        fontSize="0.85rem"
                        color="rgba(79, 79, 79, 1)"
                      >
                        Test the models ability to predict{" "}
                        {(model["interpolation_frequency"] ?? 1) *
                          (forecastIndex ?? 1)}{" "}
                        {model["interpolation_frequency_units"]
                          ? model["interpolation_frequency_units"]
                          : "minute"}
                        (s) out.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  borderBottom: "1px solid lightgrey",
                  backgroundColor: "white",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    gap: "8px",
                  }}
                >
                  <CalendarMonthIcon fontSize="16px" />
                  <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                    Testing Time Range
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    padding: "0px 20px 16px 20px",
                  }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Start date & time
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5px",
                      backgroundColor: "white",
                      marginBottom: "12px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Typography sx={{ fontSize: "12px" }}>
                    End date & time
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid lightgrey",
                  backgroundColor: "rgba(247, 247, 247, 1)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    gap: "20px",
                  }}
                >
                  <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                    Target
                  </Typography>
                  {model.model_type === "Generalized" && (
                    <Typography
                      color="rgb(67,67,67)"
                      variant="h8"
                      fontSize="0.8rem"
                    >
                      Defaults to the first input for generalized models
                    </Typography>
                  )}
                </Box>
                <Divider />
                {output && <VariableCard config={output} idx={inputs.length} />}
              </Box>
              {model.model_type === "Generalized" && (
                <Box
                  sx={{
                    margin: "10px",
                    backgroundColor: "white",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Typography fontWeight="bold" variant="h8">
                      One Shot Learning
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "lightgrey",
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        padding: "2px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography fontSize="0.8rem">Status</Typography>
                      {!enableOneShot && (
                        <CancelIcon style={{ color: "red", p: 0, m: 0 }} />
                      )}
                      {enableOneShot && (
                        <LoadingSpinner isLoading={true} size={"20px"} />
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ p: "10px" }}>
                    <Box
                      sx={{
                        paddingTop: "5px",
                        display: "flex",
                        gap: "5px",
                        width: "90%",
                      }}
                      onClick={() => setEnableOneShot(true)}
                    >
                      {enableOneShot ? (
                        <RadioButtonCheckedIcon
                          sx={{ fill: "rgba(70, 67, 212, 1)" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                      <Box>
                        <Typography
                          fontSize="0.95rem"
                          fontWeight="bold"
                          color={
                            enableOneShot ? "rgba(70, 67, 212, 1)" : "black"
                          }
                        >
                          Enabled
                        </Typography>
                        <Typography
                          fontSize="0.8rem"
                          color="rgba(79, 79, 79, 1)"
                        >
                          Fine tune the generalized model on the last day of
                          data for the selected sensor. Allow up to 5 minutes
                          for the sandbox to be ready.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "5px",
                        display: "flex",
                        gap: "5px",
                      }}
                      onClick={() => setEnableOneShot(false)}
                    >
                      {!enableOneShot ? (
                        <RadioButtonCheckedIcon
                          sx={{ fill: "rgba(70, 67, 212, 1)" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                      <Box>
                        <Typography
                          fontWeight="bold"
                          fontSize="0.95rem"
                          color={
                            !enableOneShot ? "rgba(70, 67, 212, 1)" : "black"
                          }
                        >
                          Disabled
                        </Typography>
                        <Typography
                          fontSize="0.8rem"
                          color={"rgba(79, 79, 79, 1)"}
                        >
                          Apply the base generalized model without additional
                          training time.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {model.model_type === "Targeted" && (
                <Box
                  sx={{
                    borderBottom: "1px solid lightgrey",
                    backgroundColor: "rgba(247, 247, 247, 1)",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      height: "48px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                      Inputs
                    </Typography>
                  </Box>
                  <Divider />
                  {inputs.map((config, idx) => (
                    <VariableCard config={config} idx={idx} />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              flex: "1",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  padding: "5px",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "rgba(114, 110, 255, 0.16)",
                  boxShadow: 3,
                  border: "1px solid rgba(114, 110, 255, 0.16)",
                }}
              >
                <Typography color="rgba(75, 64, 221, 1)" fontSize="0.9rem">
                  RMSE
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {rmse ? parseFloat(rmse).toFixed(3) : "..."}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  padding: "5px",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "rgba(114, 110, 255, 0.16)",
                  border: "1px solid rgba(114, 110, 255, 0.16)",
                  boxShadow: 3,
                }}
              >
                <Typography color="rgba(75, 64, 221, 1)" fontSize="0.9rem">
                  MAE
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {mae ? parseFloat(mae).toFixed(3) : "..."}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <TSGraph
                predicted={testPredictions}
                actuals={testActuals}
                labels={testLabels}
                targetName={targetName}
                color={COLORS[inputs.length]}
                ymin={ymin}
                ymax={ymax}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModelTesting;
