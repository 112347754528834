import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingSpinner = ({ isLoading, size }) => {
  const __size = size ? size : 60
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {isLoading && <CircularProgress color="primary" size={__size} />} {/* Show spinner if isLoading is true */}
    </div>
  );
};

export default LoadingSpinner;
