import React from 'react';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import RoundedSmallButton from './small-button';
import StorageIcon from '@mui/icons-material/Storage';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SensorsIcon from '@mui/icons-material/Sensors';
import TransformIcon from '@mui/icons-material/Transform';
import WebhookIcon from '@mui/icons-material/Webhook';

const IconMapping = {
    'Praxis Launchpad': <AppsIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
    'ML Factory': <BuildCircleIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
    'Sensor Hub': <SensorsIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
    'Historian Manager': <StorageIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
    'Stream Builder': <TransformIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
    'Deployments': <WebhookIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
    'Optimization Engine': <WebhookIcon style={{ fontSize: '1.8em', marginRight: '10px', color: '#F7F7F8' }}/>,
}

const ColorMapping = {
  'Praxis Launchpad': '#FB6E0D',
  'ML Factory': '#28A745',
  'Sensor Hub': '#FFC300',
  'Historian Manager': '#6F42C1',
  'Stream Builder': '#DC3545',
  'Deployments': '#007BFF',
  'Optimization Engine': '#007BFF',
}

const Header = ({title}) => {
  return (
    <Box
      sx={{
          width:'100%',
          backgroundColor:'#0F1354',
          boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
          <Box sx={{
            backgroundColor:ColorMapping[title],
            boxShadow: 'none',
            paddingLeft: '10px',
            paddingTop: '9px',
            paddingBottom: '5px',
          }}>
            {IconMapping[title]}
          </Box>
          <Box sx={{
            paddingTop: '10px',
            paddingBottom: '5px',
          }}>
              <Typography variant="overline" fontSize='12px' fontWeight={'bold'} color='#F7F7F8'>
                  {title}
              </Typography>
          </Box>
      </Box>
    </Box>
  );
};

export default Header;
