import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css'

import HomePage from './applications/home-page/home';

import MLFactoryHome from "./applications/ml-factory/home";
import MLFactoryModelOverview from "./applications/ml-factory/model-overview";
import ModelTesting from "./applications/ml-factory/model-testing";
import ModelSandbox from "./applications/ml-factory/model-sandbox";
import ModelBuilder from "./applications/ml-factory/model-builder";
import TrainingDataCreator from "./applications/ml-factory/model-target-data";
import GeneralizedDataCreator from "./applications/ml-factory/model-generalized-data"

import HistorianManagerHome from "./applications/historian-manager/home/index";
import HistorianManagerCreate from "./applications/historian-manager/connect";
import HistorianManagerExplore from "./applications/historian-manager/explore";
import HistorianManagerDelete from "./applications/historian-manager/delete";

import SensorHubHome from "./applications/sensor-hub/home";
import SensorHubExplore from "./applications/sensor-hub/explore";
import SensorHubIngest from "./applications/sensor-hub/ingest";
import SensorHubUpload from "./applications/sensor-hub/upload";

import StreamBuilderHome from "./applications/stream-builder/home";
import StreamBuilderCreate from "./applications/stream-builder/create";

import InvestigatorHome from "./applications/investigator";
import MonitorsHome from "./applications/monitors/home"
import MonitorViewer from "./applications/monitors/monitor-viewer"


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
            exact
            path="/"
            element={<HomePage />}
        ></Route>
        <Route
            exact
            path="/historian-manager"
            element={<HistorianManagerHome />}
        ></Route>
        <Route
            exact
            path="/historian-manager/create"
            element={<HistorianManagerCreate />}
        ></Route>
        <Route
            exact
            path="/historian-manager/explore/:historianId"
            element={<HistorianManagerExplore />}
        ></Route>
        <Route
            exact
            path="/historian-manager/delete/:historianId"
            element={<HistorianManagerDelete />}
        ></Route>
        <Route
            exact
            path="/sensor-hub"
            element={<SensorHubHome />}
        ></Route>
        <Route
            exact
            path="/sensor-hub/explore/:sensorId"
            element={<SensorHubExplore />}
        ></Route>
        <Route
            exact
            path="/sensor-hub/ingest"
            element={<SensorHubIngest />}
        ></Route>
        <Route
            exact
            path="/sensor-hub/upload"
            element={<SensorHubUpload />}
        ></Route>
        <Route
            exact
            path="/stream-builder"
            element={<StreamBuilderHome />}
        ></Route>
        <Route
            exact
            path="/stream-builder/create"
            element={<StreamBuilderCreate />}
        ></Route>
        <Route
            exact
            path="/stream-builder/configure/:streamId"
            element={<StreamBuilderCreate />}
        ></Route>
        <Route
            exact
            path="/ml-factory"
            element={<MLFactoryHome />}
        ></Route>
        <Route
            exact
            path="/ml-factory/model-overview/:modelId"
            element={<MLFactoryModelOverview />}
        ></Route>
        <Route
            exact
            path="/ml-factory/model-sandbox/:modelId"
            element={<ModelSandbox />}
        ></Route>
        <Route
            exact
            path="/ml-factory/model-testing/:modelId"
            element={<ModelTesting />}
        ></Route>
        <Route
            exact
            path="/ml-factory/model-builder/training-data/:trainingDatasetId"
            element={<TrainingDataCreator />}
        ></Route>
        <Route
            exact
            path="/ml-factory/model-builder/training-data/generalized/:modelId"
            element={<GeneralizedDataCreator />}
        ></Route>
        <Route
            exact
            path="/ml-factory/model-builder/config/:modelId"
            element={<ModelBuilder />}
        ></Route>
        <Route
            exact
            path="/investigator"
            element={<InvestigatorHome/>}
        ></Route>
        <Route
            exact
            path="/monitors"
            element={<MonitorsHome/>}
        ></Route>
        <Route
            exact
            path="/monitors/monitor-explorer/:monitorId"
            element={<MonitorViewer/>}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);