import * as React from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default function SensorImportHeader({ text, color, getViewStatus }) {
    const [viewContent, setViewContent] = React.useState(false);
    const handleDropdownOpen = () => {
        setViewContent(true);
        getViewStatus(true);
    }
    const handleDropdownClose = () => {
        setViewContent(false);
        getViewStatus(false);
    }
    return (
        <Box>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#e0e0e0', // Light grey background color
                    padding: '10px', // Add padding for spacing
                    borderRadius: '5px', // Add border radius for blocky appearance
                    fontSize: '16px', // Set font size
                    fontWeight: 'bold', // Set font weight
                    color: {color}, // Dark grey text color
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px', // Set font size
                        fontWeight: 'bold', // Set font weight
                    }}
                >{text}</Typography>
                <IconButton
                    onClick={handleDropdownOpen}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>
        </Box>
    );
}