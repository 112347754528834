import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TSGraph = ({predicted, actuals, labels, targetName, color, ymin, ymax}) => {
  const [chartData, setChartData] = React.useState(null);
  const [options, setOptions] = React.useState(null);

  React.useEffect(() => {
    if(actuals && predicted && labels){
      var __chartData = {
        labels: labels,
        datasets: [
          {
            label:`Model Predictions`,
            data: predicted,
            fill: false,
            borderColor: 'rgba(230, 151, 255, 1)',
            tension: 0.1,
            pointRadius:0,
            yAxisID: 'y1',
            borderDash:[5,5]
          },
          {
            label:`${targetName}`,
            data: actuals,
            fill: false,
            borderColor: color,
            tension: 0.1,
            pointRadius:0,
            yAxisID: 'y1',
          },
        ],
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: true,
          },
          title: {
            display: false,
            text: targetName,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'time',
            },
            ticks: {
              color: 'rgb(120,120,120)', // Change the x-axis ticks color
              callback: function(value, index, values) {
                const label = this.getLabelForValue(value);
                return index % 10 === 0 ? label.slice(0,11) : '';
              },
            },
          },
          y1: {
            // min: ymin,
            // max: ymax,
            type: 'linear',
            display: true,
            position: 'left',
          },
        },
      };

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [predicted, actuals, labels])


  return (
    <div style={{height:'620px', width:'100%'}}>
      {(chartData && options) && <Line data={chartData} options={options} />}
    </div>
  );
};

export default TSGraph;
