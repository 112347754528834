import * as React from "react";

import MenuDrawer from "../../components/menu-drawer";

import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, IconButton, TextField, Button, Grid } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';

import SummarizeIcon from '@mui/icons-material/Summarize';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
export default function InvestigatorHome() {
    const chatTitles = {
        'today': [
            'Machine 1 RCA',
            'OEE analysis',
            'Recent downtimes',
        ],
        'past_7': [
            'Quality optimization',
            'Machine 1 RCA',
            'OEE analysis',
            'Recent downtimes',
        ],
        'past_30': [
            'Quality optimization',
            'Machine 1 RCA',
            'OEE analysis',
            'Recent downtimes',
        ]
    }

    const PreviousChatSection = ({timeFrame, timeFrameString}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                }}
            >
                <Typography paddingLeft='10px' fontSize='0.8rem' fontWeight='bold'>{timeFrameString}</Typography>
                <List>
                    {chatTitles[timeFrame].map((title) => (<PreviousChatCard title={title}/>))}
                </List>
            </Box>
        )
    }

    const PreviousChatCard = ({title}) => {
        return (
            <ListItem
                sx={{
                    '&:hover':{
                        backgroundColor:'#ECECEC',
                        '.shareIcon': {
                            visibility: 'visible',
                        }
                    },
                    borderRadius:'8px',
                    pl:'10px',
                }}
                secondaryAction={
                    <IconButton 
                        className="shareIcon"
                        sx={{
                            visibility: 'hidden',
                            p:0,
                            m:0
                        }}
                    >
                        <MoreHorizIcon edge="end"  />
                    </IconButton>
                }
            >
                <Typography fontSize='0.9rem'>{title}</Typography>
            </ListItem>
        )
    }

    const ExampleQueryCard = ({query}) => {
        return (
            <Box
                sx={{
                    border:'1px solid lightgrey',
                    borderRadius:'8px',
                    padding:'15px',
                    display:'flex',
                    alignItems:'center',
                    gap:'10px',
                    '&:hover':{
                        backgroundColor:'#F9F9F9',
                    }
                }}
            >
                <SummarizeIcon/>
                <Typography fontSize='0.9rem'>
                    {query}
                </Typography>
            </Box>
        )
    }

    const [query, setQuery] = React.useState(null);

    return (
        <Box sx={{ display: "flex" }}>
          <MenuDrawer />
          <Box component="main" sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                    display:'flex',
                    height:'100vh'
                }}
              >
                <Box
                    sx={{
                        backgroundColor:'#F9F9F9',
                        padding:'10px',
                        display:'flex',
                        flexDirection:'column',
                        gap:'30px',
                        width:'20%'
                    }}
                >
                    <PreviousChatSection timeFrame={'today'} timeFrameString={'Today'}/>
                    <PreviousChatSection timeFrame={'past_7'} timeFrameString={'Previous 7 Days'}/>
                    <PreviousChatSection timeFrame={'past_30'} timeFrameString={'Previous 30 Days'}/>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        width:'80%',
                        backgroundColor:'white',
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            height:'100%',
                            width:'100%',
                            alignItems:'center',
                            justifyContent:'center',
                            flexDirection:'column',
                            gap:'5px'
                        }}
                    >
                        <Box
                            sx={{padding:'20px'}}
                        >
                            <Typography color='#13343B' variant='h4'>Start your analysis</Typography>
                        </Box>
                        <TextField
                            sx={{
                                width:'60%',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ccc', // Default border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#888', // Border color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#888', // Border color on focus
                                    },
                                }
                            }}
                            multiline
                            rows={3}
                            placeholder="Ask anything..."
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <Box
                            sx={{
                                width:'60%',
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center'
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    gap:'5px',
                                }}
                            >
                                <Button
                                    sx={{
                                        textTransform:'none',
                                        borderRadius:'20px'
                                    }}
                                    variant='text'
                                    startIcon={<FilterListIcon/>}
                                >
                                    Focus
                                </Button>
                                <Button
                                    sx={{
                                        textTransform:'none',
                                        borderRadius:'20px'
                                    }}
                                    variant='text'
                                    startIcon={<AddCircleIcon/>}
                                >
                                    Attach
                                </Button>
                            </Box>
                            <IconButton>
                                <ArrowCircleRightIcon
                                    sx={{
                                        fill: query ? '#1976D2' : 'grey',
                                        fontSize:'1.9rem'
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                width:'60%'
                            }}
                        >
                            <Grid container spacing='20px'>
                                <Grid item xs={6}>
                                    <ExampleQueryCard query='Recent Anomalies'/>
                                </Grid>
                                <Grid item xs={6}>
                                    <ExampleQueryCard query='Throughput Forecast'/>
                                </Grid>
                                <Grid item xs={6}>
                                    <ExampleQueryCard query='Quality Optimization'/>
                                </Grid>
                                <Grid item xs={6}>
                                    <ExampleQueryCard query='OEE Summary'/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
              </Box>
          </Box>
        </Box>
      );
}