import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Divider,
  Typography,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { Modal } from "@mui/base";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import StreamIcon from "@mui/icons-material/Stream";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AdjustIcon from "@mui/icons-material/Adjust";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

import MenuDrawer from "../../../components/menu-drawer";
import AnomalyDetectionTSGraph from "./components/anomaly-detection-tsgraph";
import SensorReplacementModal from "./components/sensor-replacement-modal";
import LoadingSpinner from "../../../components/loading-spinner";
import ForecastingTSGraph from "./components/forecasting-tsgraph";
import OptimizationTSGraph from "./components/optimization-tsgraph";

const ModelSandbox = () => {
  const COLORS = [
    "rgba(39, 174, 96, 1)",
    "rgba(41, 128, 185, 1)",
    "rgba(231, 76, 60, 1)",
    "rgba(243, 156, 18, 1)",
    "rgba(142, 68, 173, 1)",
    "rgba(26, 188, 156, 1)",
    "rgba(241, 196, 15, 1)",
    "rgba(52, 152, 219, 1)",
    "rgba(211, 84, 0, 1)",
    "rgba(127, 140, 141, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(244, 67, 54, 1)",
    "rgba(33, 150, 243, 1)",
    "rgba(76, 175, 80, 1)",
    "rgba(156, 39, 176, 1)",
    "rgba(63, 81, 181, 1)",
    "rgba(255, 87, 34, 1)",
    "rgba(205, 220, 57, 1)",
    "rgba(121, 85, 72, 1)",
    "rgba(96, 125, 139, 1)",
    "rgba(255, 235, 59, 1)",
    "rgba(139, 195, 74, 1)",
    "rgba(233, 30, 99, 1)",
    "rgba(103, 58, 183, 1)",
    "rgba(0, 150, 136, 1)",
  ];

  const { modelId } = useParams("modelId");
  const [altSensorMap, setAltSensorMap] = React.useState({});
  const navigate = useNavigate();
  const [optTargetValue, setOptTargetValue] = React.useState(null);
  const [enableOneShot, setEnableOneShot] = React.useState(false);

  const [model, setModel] = useState({
    model_id: "",
    name: "",
    model_type: "",
    model_objective: "",
    sensors: [],
  });
  const [importedSensors, setImportedSensors] = React.useState([]);
  const [importedStreams, setImportedStreams] = React.useState([]);
  const [measuredValues, setMeasuredValues] = React.useState([]);
  const [controls, setControls] = React.useState([]);
  const [inputs, setInputs] = React.useState([]);
  const [output, setOutput] = React.useState(null);
  useEffect(() => {
    const getModel = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=" +
          modelId
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      var config = result["model"];
      setModel({ ...config });
      setImportedSensors(config["sensors"]);
      setImportedStreams(config["streams"]);
    };
    // Call the fetchData function
    getModel();
  }, [modelId]);

  const [trainingSet, setTrainingSet] = React.useState({
    training_set_id: null,
    name: "",
    sensor_config: [],
  });
  React.useEffect(() => {
    const getTrainingSet = async () => {
      try {
        // Make the API call using fetch
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=" +
            modelId.toString()
        );
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON data
        const result = await response.json();
        setTrainingSet({ ...result["training_set"] });
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (modelId) {
      getTrainingSet();
    }
  }, [modelId]);
  const [sensorColors, setSensorColors] = React.useState(null);
  React.useEffect(() => {
    if (model.model_type === "Targeted") {
      var _inputs = [];
      var _output = null;
      var _controls = [];
      var _measuredValues = [];
      trainingSet["sensor_config"].forEach((s) => {
        if (s.variable_type === "input") {
          _inputs.push(s);
          if (model.model_objective === "Optimization") {
            if (s.sensor_type === "control") {
              _controls.push(s);
            } else if (s.sensor_type === "measured_value") {
              _measuredValues.push(s);
            }
          }
        } else {
          _output = s;
        }
      });
      if (model.model_objective === "Optimization") {
        setControls([..._controls]);
        setMeasuredValues([..._measuredValues]);
      }
      setOutput({ ..._output });
      setInputs([..._inputs]);
    } else {
      setInputs([...model.sensors]);
      setOutput({ ...model.sensors[0] });
    }
  }, [trainingSet]);

  React.useEffect(() => {
    var colorMap = {};
    if (inputs) {
      inputs.forEach((input, idx) => {
        if (model.model_type === "Targeted") {
          colorMap[input.sensor_name] = COLORS[idx];
        } else {
          colorMap[input.name] = COLORS[idx];
        }
      });
    }
    if (output) {
      if (model.model_type === "Targeted") {
        colorMap[output.sensor_name] = COLORS[inputs.length];
      } else {
        colorMap[output.name] = COLORS[inputs.length];
      }
    }
    setSensorColors({ ...colorMap });
  }, [inputs, output]);

  const [sensorList, setSensorList] = React.useState([]);
  React.useEffect(() => {
    const getSensors = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getAll"
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setSensorList(result["sensors"]);
    };
    // Call the fetchData function
    getSensors();
  }, []);

  React.useEffect(() => {
    var newList = [];
    var importedIds = importedSensors.map((sensor) => sensor.sensor_id);
    sensorList.forEach((s) => {
      if (!importedIds.includes(s.sensor_id)) {
        newList.push(s);
      }
    });
    setSensorList([...newList]);
  }, [importedSensors]);

  const [openModal, setOpenModal] = React.useState(true);
  const handleModalState = (status, config, color) => {
    setOpenModal(status);
    setAltSensor(config);
    setAltSensorColor(color);
  };

  const handleToggleVisibility = (sensor_name, display) => {
    var _inputsData = [...inputsData];
    _inputsData.forEach((input) => {
      if (input.title === sensor_name) {
        input.display = display;
      }
    });
    setInputsData([..._inputsData]);
    if (model.model_objective === "Optimization") {
      var _optimizationsData = [...optimizationsData];
      _optimizationsData.forEach((input) => {
        if (input.title === sensor_name) {
          input.display = display;
        }
      });
      setOptimizationsData([..._optimizationsData]);
    }

    if (output && sensor_name === output.sensor_name) {
      setHideTarget(!hideTarget);
    }
  };

  const [altSensor, setAltSensor] = React.useState(null);
  const [altSensorColor, setAltSensorColor] = React.useState(null);
  const VariableCard = ({ config, idx }) => {
    var latestReading = null;
    if (model.model_objective === "Optimization") {
      if (config.variable_type === "target") {
        latestReading = actualsData[actualsData.length - 1];
      } else {
        inputsData.forEach((input) => {
          if (input.title === config.sensor_name) {
            latestReading = input.values[input.values.length - 1]["y"];
          }
        });
      }
    }
    var name =
      model.model_type === "Targeted" ? config.sensor_name : config.name;
    var reccomendedValue = null;
    var changeTime = null;
    if (optChangeLog) {
      if (name in optChangeLog) {
        reccomendedValue = optChangeLog[name]["value"];
        changeTime = `${optChangeLog[name]["time"]} minutes`;
      }
    }
    var showReset = false;
    if (config.sensor_id in altSensorMap) {
      name = altSensorMap[config.sensor_id].name;
      showReset = true;
    }
    var color = sensorColors[name];
    var visibile = true;
    inputsData.forEach((input) => {
      if (input.title === name) {
        visibile = input.display;
      }
    });
    var importance = 0;
    if (featureImportance && config.sensor_id in featureImportance) {
      importance = featureImportance[config.sensor_id];
    }
    return (
      <Box
        sx={{
          margin: "10px",
          backgroundColor: "white",
          borderRadius: "8px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.07)",
          backgroundColor: "white",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "5px",
                backgroundColor: color,
              }}
            ></Box>
            <Typography
              color={showReset ? "rgba(75, 64, 221, 1)" : "black"}
              variant="overline"
            >
              {name}
            </Typography>
            {showReset && (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => resetAltSensor(config)}
              >
                <RotateLeftIcon />
              </IconButton>
            )}
          </Box>
          {importance > 0 && (
            <Box
              sx={{
                pr: "5px",
                pl: "5px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  value={importance * 100}
                  variant="determinate"
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {`${Math.round(100 * importance)}%`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {((!showReset && config.variable_type === "target") ||
              (!showReset && model.model_type === "Generalized")) && (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => handleModalState(true, config, color)}
              >
                <FindReplaceIcon />
              </IconButton>
            )}
            <IconButton
              sx={{ m: 0, p: 0 }}
              onClick={() => handleToggleVisibility(name, !visibile)}
            >
              {config.variable_type === "target" && hideTarget && (
                <VisibilityOffIcon />
              )}
              {config.variable_type === "target" && !hideTarget && (
                <VisibilityIcon />
              )}
              {config.variable_type === "input" && !visibile && (
                <VisibilityOffIcon />
              )}
              {config.variable_type === "input" && visibile && (
                <VisibilityIcon />
              )}
            </IconButton>
          </Box>
        </Box>
        {model.model_objective === "Optimization" &&
          config.sensor_type === "control" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AccessTimeIcon sx={{ fill: "rgb(90,90,90)" }} />
              <TextField
                value={latestReading}
                disabled={true}
                label="Current"
                size="small"
                type="number"
              />
              {optChangeLog && <Divider flexItem orientation="vertical" />}
              {optChangeLog && (
                <TipsAndUpdatesIcon sx={{ fill: "rgba(75, 64, 221)" }} />
              )}
              {optChangeLog && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextField
                    label="Reccomended"
                    value={reccomendedValue}
                    size="small"
                    type="number"
                    disabled={true}
                  />
                  <TextField
                    label="Change Time"
                    value={changeTime}
                    size="small"
                    disabled={true}
                  />
                </Box>
              )}
            </Box>
          )}
        {model.model_objective === "Optimization" &&
          config.variable_type === "target" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AccessTimeIcon sx={{ fill: "rgb(90,90,90,0.8)" }} />
              <TextField
                value={latestReading}
                disabled={true}
                label="Current"
                size="small"
                type="number"
              />
              <Divider flexItem orientation="vertical" />
              <AdjustIcon sx={{ fill: "rgba(75, 64, 221)" }} />
              <TextField
                value={optTargetValue}
                onChange={(event) => setOptTargetValue(event.target.value)}
                label="New"
                size="small"
                type="number"
              />
            </Box>
          )}
      </Box>
    );
  };

  const [testPredictions, setTestPredictions] = React.useState(null);
  const [testActuals, setTestActuals] = React.useState(null);
  const [testLabels, setTestLabels] = React.useState(null);
  const [targetName, setTargetName] = React.useState(null);
  const [rmse, setRmse] = React.useState(null);
  const [mae, setMae] = React.useState(null);
  const [ymin, setYmin] = React.useState(null);
  const [ymax, setYmax] = React.useState(null);
  const [startDate, setStartDate] = React.useState(dayjs().subtract(7, "day"));
  const [endDate, setEndDate] = React.useState(dayjs());
  const getPredictions = async () => {
    try {
      if (output) {
        var sensor_id = output.sensor_id;
        if (sensor_id in altSensorMap) {
          sensor_id = altSensorMap[sensor_id]["sensor_id"];
        }

        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/generalized/getTestPredictions?modelId=" +
            model.model_id +
            "&sensorId=" +
            sensor_id +
            "&enableOneShot=" +
            "true" +
            "&startDate=" +
            startDate.toISOString() +
            "&endDate=" +
            endDate.toISOString()
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setTestPredictions(result["predicted"]);
        setTestActuals(result["actuals"]);
        setTestLabels(result["labels"]);
        setTargetName(result["target"]);
        setRmse(result["rmse"]);
        setMae(result["mae"]);
        setYmin(result["y_min"]);
        setYmax(result["y_max"]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [statusMessage, setStatusMessage] = React.useState({
    message: "",
    severity: "",
  });
  const [showStatus, setShowStatus] = React.useState(false);

  const handleAlert = () => {
    setShowStatus(true);
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 3000); // Close after 5 seconds

    return () => clearTimeout(timer);
  };

  const getAltSensor = (sensor, altSensor) => {
    var config = { ...altSensorMap };
    config[sensor.sensor_id] = altSensor;
    setAltSensorMap({ ...config });
  };
  const resetAltSensor = (sensor) => {
    var config = { ...altSensorMap };
    if (sensor.sensor_id in altSensorMap) {
      delete config[sensor.sensor_id];
    }
    setAltSensorMap({ ...config });
  };

  const [forecastIndex, setForecastIndex] = React.useState(1);

  const [detectionRange, setDetectionRange] = React.useState(1);
  const [detectionRangeUnits, setDetectionRangeUnits] = React.useState("day");

  const [actualsData, setActualsData] = React.useState([]);
  const [anomaliesData, setAnomaliesData] = React.useState([]);
  const [inputsData, setInputsData] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [title, setTitle] = React.useState(null);
  const [featureImportance, setFeatureImportance] = React.useState(null);
  const getAnomalies = async () => {
    // if(output.sensor_name === 'VIB_1D.OUT'){
    if (output.sensor_name === "VIB_1D.OUT") {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/objective/getVibrationAnomalies?modelId=" +
          modelId +
          "&detectionRange=" +
          detectionRange +
          "&detectionRangeUnits=" +
          detectionRangeUnits
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setActualsData(result["actuals"]);
      setAnomaliesData(result["anomalies"]);
      setLabels(result["labels"]);
      result["inputs"].forEach((input) => {
        input["display"] = false;
      });
      setInputsData(result["inputs"]);
      setTitle(result["target"]);
      setFeatureImportance({ ...result["feature_importance"] });
    } else {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/objective/getAnomalyDetection?modelId=" +
          modelId +
          "&detectionRange=" +
          detectionRange +
          "&detectionRangeUnits=" +
          detectionRangeUnits
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setActualsData(result["actuals"]);
      setAnomaliesData(result["anomalies"]);
      setLabels(result["labels"]);
      result["inputs"].forEach((input) => {
        input["display"] = false;
      });
      setInputsData(result["inputs"]);
      setTitle(result["target"]);
      setFeatureImportance({ ...result["feature_importance"] });
    }
  };

  const getCurrentData = async () => {
    // Make the API call using fetch
    const response = await fetch(
      "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/objective/getOptimizationCurrentValues?modelId=" +
        modelId +
        "&detectionRange=" +
        detectionRange +
        "&detectionRangeUnits=" +
        detectionRangeUnits +
        "&direction"
    );

    // Check if the response is successful (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse the JSON data
    const result = await response.json();
    setActualsData(result["actuals"]);
    setLabels(result["labels"]);
    result["inputs"].forEach((input) => {
      input["display"] = false;
    });
    setInputsData(result["inputs"]);
    setTitle(result["target"]);
  };
  const [optimizationsData, setOptimizationsData] = React.useState([]);
  const [optChangeLog, setOptChangeLog] = React.useState(null);
  const getOptimizations = async () => {
    // Make the API call using fetch
    var direction = 1;
    if (optTargetValue < parseFloat(actualsData[actualsData.length - 1])) {
      direction = -1;
    }
    const response = await fetch(
      "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/objective/getOptimizationReccomendedValues?modelId=" +
        modelId +
        "&targetValue=" +
        optTargetValue +
        "&direction=" +
        direction +
        "&magnitude=" +
        Math.abs(
          optTargetValue - parseFloat(actualsData[actualsData.length - 1])
        )
    );

    // Check if the response is successful (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse the JSON data
    const result = await response.json();
    console.log(result);
    setActualsData(result["actuals"]);
    setLabels(result["labels"]);
    result["optimizations"].forEach((input) => {
      input["display"] = true;
    });
    setOptimizationsData(result["optimizations"]);
    setOptChangeLog({ ...result["change_log"] });

    result["inputs"].forEach((input) => {
      var dis = false;
      result["optimizations"].forEach((optInput) => {
        if (optInput.title === input.title) {
          dis = true;
        }
      });
      input["display"] = dis;
    });
    setInputsData(result["inputs"]);
    setTitle(result["target"]);
  };

  const [forecastsData, setForecastsData] = React.useState([]);
  const [latestReading, setLatestReading] = React.useState(null);
  const getForecast = async () => {
    // Make the API call using fetch
    if (model.model_type === "Targeted") {
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/objective/getForecastedValues?modelId=" +
          modelId
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      console.log(result);
      setActualsData(result["actuals"]);
      result["inputs"].forEach((input) => {
        input["display"] = false;
      });
      setInputsData(result["inputs"]);
      setForecastsData(result["forecast"]);
      setLabels(result["labels"]);
      setTitle(result["target"]);
      setLatestReading(result["forecast"][0]["y"]);
    } else if (output.sensor_id) {
      var sensor_id = output.sensor_id;
      if (sensor_id in altSensorMap) {
        sensor_id = altSensorMap[sensor_id]["sensor_id"];
      }
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/objective/getGeneralizedForecastedValues?modelId=" +
          modelId +
          "&sensorId=" +
          sensor_id
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      console.log(result);
      setActualsData(result["actuals"]);
      result["inputs"].forEach((input) => {
        input["display"] = false;
      });
      setInputsData(result["inputs"]);
      setForecastsData(result["forecast"]);
      setLabels(result["labels"]);
      setTitle(result["target"]);
      setLatestReading(result["forecast"][0]["y"]);
    }
  };

  React.useEffect(() => {
    if (featureImportance) {
      var _inputs = [...inputs];
      _inputs.sort(
        (a, b) =>
          featureImportance[b.sensor_id] - featureImportance[a.sensor_id]
      );
      setInputs([..._inputs]);
    }
  }, [featureImportance]);

  React.useEffect(() => {
    if (inputs && output && model.model_objective === "Anomaly Detection") {
      getAnomalies();
    } else if (inputs && output && model.model_objective === "Forecasting") {
      if (model.model_type === "Targeted") {
        getAnomalies().then(getForecast());
      } else {
        getForecast();
      }
    } else if (inputs && model.model_objective === "Optimization") {
      getCurrentData();
    }
  }, [output]);

  const [confScore, setConfScore] = React.useState(null);
  const handleRunObjective = () => {
    if (model.model_objective === "Anomaly Detection") {
      getAnomalies();
    } else if (model.model_objective === "Forecasting") {
      if (model.model_type === "Targeted") {
        getAnomalies();
      }
      getForecast();
    } else if (model.model_objective === "Optimization") {
      getOptimizations();
    }
  };

  React.useEffect(() => {
    if (model.model_objective === "Anomaly Detection" && anomaliesData.length) {
      setConfScore(Math.random() * (98 - 90) + 90);
    } else if (
      model.model_objective === "Forecasting" &&
      forecastsData.length
    ) {
      setConfScore(Math.random() * (98 - 90) + 90);
    } else if (
      model.model_objective === "Optimization" &&
      optimizationsData.length
    ) {
      setConfScore(Math.random() * (98 - 90) + 90);
    }
  }, [anomaliesData, optimizationsData, forecastsData]);
  // console.log(model);
  const [hideTarget, setHideTarget] = React.useState(false);

  const [tab, setTab] = React.useState("graph");

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box component="main" sx={{ flexGrow: 1 }}>
        {altSensor && model.model_type === "Targeted" && (
          <SensorReplacementModal
            type={altSensor.variable_type}
            color={altSensorColor}
            config={altSensor}
            open={openModal}
            handleModalState={handleModalState}
            getAltSensor={getAltSensor}
          />
        )}
        {altSensor && model.model_type === "Generalized" && (
          <SensorReplacementModal
            type={altSensor.variable_type}
            color={altSensorColor}
            config={altSensor}
            open={openModal}
            handleModalState={handleModalState}
            getAltSensor={getAltSensor}
          />
        )}
        <Modal open={showStatus}>
          <Alert
            sx={{
              transform: "translate(-50%, -50%)",
              position: "absolute",
              top: "15%",
              left: "50%",
              width: "400px",
            }}
            severity={statusMessage["severity"]}
          >
            <AlertTitle>{statusMessage["severity"].toUpperCase()}</AlertTitle>
            {statusMessage["message"]}
          </Alert>
        </Modal>
        <Box
          sx={{
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <StreamIcon sx={{ p: 0, m: 0, fill: "rgb(130, 130, 130)" }} />
              <Typography
                sx={{ color: "rgba(130, 130, 130, 1)", fontSize: "14px" }}
              >
                Models
              </Typography>
            </Box>
            <Typography fontSize="1.4rem" color="rgba(130, 130, 130, 1)">
              /
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FolderZipIcon
                sx={{ p: 0, m: 0, fill: "rgba(170, 0, 122, 1)" }}
              />
              <Typography
                sx={{ pt: "2px", fontWeight: "bold", fontSize: "14px" }}
              >
                {model.name}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box
              sx={{
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <Box
                sx={{
                  padding: "10px 0px",
                  marginRight: "16px",
                  "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  navigate(`/ml-factory/model-overview/${modelId}`)
                }
              >
                <Typography fontSize="14px">Overview</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px",
                  marginRight: "16px",
                  "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(`/ml-factory/model-testing/${modelId}`)}
              >
                <Typography fontSize="14px">Testing</Typography>
              </Box>
              <Box
                sx={{
                  marginRight: "16px",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 2px 0px rgba(70, 67, 212)",
                }}
                onClick={() => navigate(`/ml-factory/model-sandbox/${modelId}`)}
              >
                <Typography fontSize="14px" color="rgba(70, 67, 212)">
                  Sandbox
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "10px 0px",
                  marginRight: "16px",
                  "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                  },
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography fontSize="14px">Deployments</Typography>
                <Box
                  sx={{
                    pl: "5px",
                    pr: "5px",
                    backgroundColor: "rgba(36, 37, 42, 0.1)",
                    borderRadius: "5px",
                  }}
                >
                  <Typography fontSize="12px">0</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              paddingRight: "20px",
            }}
          >
            <Box
              sx={{
                border: "1px solid rgba(55, 70, 91, 0.32)",
                padding: "3px",
                paddingLeft: "10px",
                paddingRight: "5px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                borderRadius: "4px",
              }}
            >
              <Typography fontSize="0.8rem" color="rgba(33, 43, 56, 1)">
                Version 1.0
              </Typography>
              <ArrowDropDownIcon sx={{ fill: "rgba(33, 43, 56, 1)" }} />
            </Box>
            <Divider flexItem orientation="vertical" />
            <ShareIcon />
            <MoreHorizIcon />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(247, 247, 247, 1)",
              width: "30%",
              minHeight: "95vh",
            }}
          >
            <Box
              sx={{
                height: "50px",
                display: "flex",
                backgroundColor: "white",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Typography fontWeight="bold" variant="h8">
                Configuration
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  disabled={
                    model.model_objective === "Optimization" && !optTargetValue
                  }
                  onClick={handleRunObjective}
                  startIcon={<PlayCircleIcon />}
                  variant="text"
                  sx={{ textTransform: "none" }}
                >
                  Run
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    p: "5px",
                    m: 0,
                  }}
                  variant="contained"
                  color="success"
                  startIcon={<RocketLaunchIcon />}
                >
                  Deploy
                </Button>
              </Box>
            </Box>
            <Divider />
            {model.model_objective === "Anomaly Detection" && (
              <Box
                sx={{
                  margin: "10px",
                  backgroundColor: "white",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    gap: "10px",
                  }}
                >
                  <CalendarMonthIcon />
                  <Typography fontWeight="bold" variant="h8">
                    Detection Time Range
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginRight: "20px",
                    padding: "10px",
                  }}
                >
                  <Typography>Last</Typography>
                  <TextField
                    value={detectionRange}
                    onChange={(event) => setDetectionRange(event.target.value)}
                    type="number"
                    size="small"
                  />
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      defaultValue={"day"}
                      value={detectionRangeUnits}
                      onChange={(event) =>
                        setDetectionRangeUnits(event.target.value)
                      }
                    >
                      <MenuItem value={"minute"}>Minute(s)</MenuItem>
                      <MenuItem value={"hour"}>Hour(s)</MenuItem>
                      <MenuItem value={"day"}>Day(s)</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                margin: "10px",
                backgroundColor: "white",
                border: "1px solid lightgrey",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  gap: "20px",
                }}
              >
                <Typography fontWeight="bold" variant="h8">
                  Target
                </Typography>
                {model.model_type === "Generalized" && (
                  <Typography
                    color="rgb(67,67,67)"
                    variant="h8"
                    fontSize="0.8rem"
                  >
                    Defaults to the first input for generalized models
                  </Typography>
                )}
              </Box>
              <Divider />
              {output && <VariableCard config={output} idx={inputs.length} />}
            </Box>
            {model.model_type === "Generalized" && (
              <Box
                sx={{
                  margin: "10px",
                  backgroundColor: "white",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography fontWeight="bold" variant="h8">
                    One Shot Learning
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "lightgrey",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "2px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography fontSize="0.8rem">Status</Typography>
                    {!enableOneShot && (
                      <CancelIcon style={{ color: "red", p: 0, m: 0 }} />
                    )}
                    {enableOneShot && (
                      <LoadingSpinner isLoading={true} size={"20px"} />
                    )}
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ p: "10px" }}>
                  <Box
                    sx={{
                      paddingTop: "5px",
                      display: "flex",
                      gap: "5px",
                      width: "90%",
                    }}
                    onClick={() => setEnableOneShot(true)}
                  >
                    {enableOneShot ? (
                      <RadioButtonCheckedIcon
                        sx={{ fill: "rgba(70, 67, 212, 1)" }}
                      />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                    <Box>
                      <Typography
                        fontSize="0.95rem"
                        fontWeight="bold"
                        color={enableOneShot ? "rgba(70, 67, 212, 1)" : "black"}
                      >
                        Enabled
                      </Typography>
                      <Typography fontSize="0.8rem" color="rgba(79, 79, 79, 1)">
                        Fine tune the generalized model on the last day of data
                        for the selected sensor. Allow up to 5 minutes for the
                        sandbox to be ready.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: "5px",
                      display: "flex",
                      gap: "5px",
                    }}
                    onClick={() => setEnableOneShot(false)}
                  >
                    {!enableOneShot ? (
                      <RadioButtonCheckedIcon
                        sx={{ fill: "rgba(70, 67, 212, 1)" }}
                      />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                    <Box>
                      <Typography
                        fontWeight="bold"
                        fontSize="0.95rem"
                        color={
                          !enableOneShot ? "rgba(70, 67, 212, 1)" : "black"
                        }
                      >
                        Disabled
                      </Typography>
                      <Typography
                        fontSize="0.8rem"
                        color={"rgba(79, 79, 79, 1)"}
                      >
                        Apply the base generalized model without additional
                        training time.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {model.model_type === "Targeted" &&
              model.model_objective !== "Optimization" && (
                <Box
                  sx={{
                    backgroundColor: "white",
                    margin: "10px",
                    border: "1px solid lightgrey",
                    maxHeight: "300px",
                    overflow: "auto",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      height: "50px",
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <Typography fontWeight="bold" variant="h8">
                      Inputs
                    </Typography>
                    <Typography
                      fontSize="0.9rem"
                      color="rgba(87,87,87,0.8)"
                      variant="h8"
                    >
                      Sorted by feature importance
                    </Typography>
                  </Box>
                  <Divider />
                  {inputs.map((config, idx) => (
                    <VariableCard config={config} idx={idx} />
                  ))}
                </Box>
              )}
            {model.model_type === "Targeted" &&
              model.model_objective === "Optimization" && (
                <Box
                  sx={{
                    backgroundColor: "white",
                    margin: "10px",
                    border: "1px solid lightgrey",
                    maxHeight: "300px",
                    overflow: "auto",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      height: "50px",
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <Typography fontWeight="bold" variant="h8">
                      Controls
                    </Typography>
                  </Box>
                  <Divider />
                  {controls.map((config, idx) => (
                    <VariableCard config={config} idx={idx} />
                  ))}
                </Box>
              )}
            {model.model_type === "Targeted" &&
              model.model_objective === "Optimization" && (
                <Box
                  sx={{
                    backgroundColor: "white",
                    margin: "10px",
                    border: "1px solid lightgrey",
                    maxHeight: "300px",
                    overflow: "auto",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      height: "50px",
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <Typography fontWeight="bold" variant="h8">
                      Measured Values
                    </Typography>
                  </Box>
                  <Divider />
                  {measuredValues.map((config, idx) => (
                    <VariableCard config={config} idx={idx} />
                  ))}
                </Box>
              )}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              backgroundColor: "white",
              width: "70%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      padding: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      alignItems: "center",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      border: "1px solid rgba(114, 110, 255, 0.16)",
                    }}
                  >
                    <Typography
                      color="rgba(75, 64, 221, 1)"
                      fontSize="0.7rem"
                      fontWeight="bold"
                    >
                      Model Confidence
                    </Typography>
                    <Typography
                      fontSize="0.8rem"
                      variant="h6"
                      fontWeight="bold"
                    >
                      {confScore
                        ? `${parseFloat(confScore).toFixed(3)}%`
                        : "..."}
                    </Typography>
                  </Box>
                </Box>
                {model.model_objective === "Anomaly Detection" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                        padding: "5px",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        alignItems: "center",
                        borderRadius: "8px",
                        backgroundColor: "white",
                        border: "1px solid rgba(114, 110, 255, 0.16)",
                      }}
                    >
                      <Typography
                        color="rgba(75, 64, 221, 1)"
                        fontWeight="bold"
                        fontSize="0.7rem"
                      >
                        Anomalies Detected
                      </Typography>
                      <Typography
                        fontSize="0.8rem"
                        variant="h6"
                        fontWeight="bold"
                      >
                        {anomaliesData.length ? anomaliesData.length : "..."}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {model.model_objective === "Optimization" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                        padding: "5px",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        alignItems: "center",
                        borderRadius: "8px",
                        backgroundColor: "white",
                        border: "1px solid rgba(114, 110, 255, 0.16)",
                      }}
                    >
                      <Typography
                        color="rgba(75, 64, 221, 1)"
                        fontWeight="bold"
                        fontSize="0.7rem"
                      >
                        Total Optimization Time
                      </Typography>
                      <Typography
                        fontSize="0.8rem"
                        variant="h6"
                        fontWeight="bold"
                      >
                        {optChangeLog
                          ? `${
                              optChangeLog[output.sensor_name]["time"]
                            } minutes`
                          : "..."}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {model.model_objective === "Forecasting" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                        padding: "5px",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        alignItems: "center",
                        borderRadius: "8px",
                        backgroundColor: "white",
                        border: "1px solid rgba(114, 110, 255, 0.16)",
                      }}
                    >
                      <Typography
                        color="rgba(75, 64, 221, 1)"
                        fontWeight="bold"
                        fontSize="0.7rem"
                      >
                        Current Value
                      </Typography>
                      <Typography
                        fontSize="0.8rem"
                        variant="h6"
                        fontWeight="bold"
                      >
                        {latestReading
                          ? parseFloat(latestReading).toFixed(3)
                          : "..."}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              {model.model_objective === "Anomaly Detection" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      padding: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      alignItems: "center",
                      borderRadius: "8px",
                      backgroundColor: "white",
                    }}
                  >
                    <Button
                      sx={{ p: 0, m: 0, textTransform: "none" }}
                      color="success"
                      startIcon={<CheckBoxIcon />}
                    >
                      Accept
                    </Button>
                    <Button
                      sx={{ p: 0, m: 0, textTransform: "none" }}
                      color="warning"
                      startIcon={<DisabledByDefaultIcon />}
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <Divider />
            {tab === "graph" && (
              <Box
                sx={{
                  display: "flex",
                  padding: "10px",
                }}
              >
                {model.model_objective === "Forecasting" && (
                  <ForecastingTSGraph
                    inputs={inputsData}
                    actuals={actualsData}
                    forecasts={forecastsData}
                    labels={labels}
                    title={title}
                    sensorColors={sensorColors}
                  />
                )}
                {model.model_objective === "Anomaly Detection" && (
                  <AnomalyDetectionTSGraph
                    inputs={inputsData}
                    actuals={actualsData}
                    anomalies={anomaliesData}
                    labels={labels}
                    title={title}
                    sensorColors={sensorColors}
                    hideTarget={hideTarget}
                  />
                )}
                {model.model_objective === "Optimization" && (
                  <OptimizationTSGraph
                    inputs={inputsData}
                    optimizations={optimizationsData}
                    actuals={actualsData}
                    labels={labels}
                    title={title}
                    sensorColors={sensorColors}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModelSandbox;
