import * as React from "react";
import { Link } from "react-router-dom";

import { Box, Divider, Typography, Button, Drawer, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import MenuDrawer from "../../../components/menu-drawer";
import CreateModal from "./components/create-modal";

import CancelIcon from "@mui/icons-material/Cancel";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StormIcon from "@mui/icons-material/Storm";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import PsychologyIcon from "@mui/icons-material/Psychology";
import StarIcon from "@mui/icons-material/Star";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 400;

function formatDateTime(inputString) {
  // Parse the input date string
  const date = new Date(inputString);

  // Options for date formatting
  const dateOptions = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };

  // Format the date part
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  // Options for time formatting
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Format the time part
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Combine date and time with the desired format
  return `${formattedDate} at ${formattedTime}`;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

const _monitorsList = [
  {
    monitor_id: 'monitor_1',
    name: 'Machine 1 Monitor',
    description: 'Monitor all sensors and downtime events on machine 1',
    creation_time: 'July 15, 2024 at 09:56 AM',
    sensors: ['sensor_a.PV', 'sensor_b.OUT', 'sensor_c.SP'],
    events: ['downtime_events'],
    attachments: ['machine1_manual.pdf'],
    severity: 'medium',
  },
  {
    monitor_id: 'monitor_2',
    name: 'Machine 2 Monitor',
    description: 'Monitor all sensors and waste events on machine 2',
    creation_time: 'July 17, 2024 at 09:56 AM',
    sensors: ['sensor_d.PV', 'sensor_e.OUT', 'sensor_f.SP'],
    events: ['waste_events'],
    attachments: ['machine2_manual.pdf'],
    severity: 'high',
  },
  {
    monitor_id: 'monitor_3',
    name: 'Machine 3 Monitor',
    description: 'Monitor all sensors and quality events on machine 3',
    creation_time: 'July 17, 2024 at 09:56 AM',
    sensors: ['sensor_d.PV', 'sensor_e.OUT', 'sensor_f.SP'],
    events: ['quality_events'],
    attachments: ['machine3_manual.pdf'],
    severity: 'low',
  },
]

export default function MonitorsHome({}) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState({ model_id: -1 });
  const [selectedMonitor, setSelectedMonitor] = React.useState({ monitor_id: -1 });
  const [openCreate, setOpenCreate] = React.useState(false);

  const handleModalState = (state) => {
    setOpenCreate(state);
  };

  const handleModelSelect = (model) => {
    if (model.model_id == selectedModel.model_id) {
      setOpenDrawer(false);
      setSelectedModel({ ...{ model_id: -1 } });
    } else {
      setSelectedModel({ ...model });
      setOpenDrawer(true);
    }
  };

  const handleMonitorSelect = (monitor) => {
    if (monitor.monitor_id == selectedMonitor.monitor_id) {
      setOpenDrawer(false);
      setSelectedMonitor({ ...{ monitor_id: -1 } });
    } else {
      setSelectedMonitor({ ...monitor });
      setOpenDrawer(true);
    }
  };


  const [modelList, setModelList] = React.useState([]);
  const [monitorsList, setMonitorsList] = React.useState(_monitorsList);
  React.useEffect(() => {
    const getModels = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/getAll"
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      setModelList(result["models"]);
    };
    // Call the fetchData function
    getModels();
  }, []);

  const ModelCard = ({ model }) => {
    return (
      <Box
        sx={{
          padding: "16px 20px",
          borderBottom: "1px solid lightgrey",
          backgroundColor:
            model.model_id === selectedModel.model_id
              ? "rgba(44, 44, 45, 0.06)"
              : "rgba(255, 255, 255, 1)",
        }}
        onClick={() => handleModelSelect(model)}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            {model.name}
          </Typography>
          <Box
            sx={{
              backgroundColor:
                model.model_type === "Generalized"
                  ? "#F2F2F2"
                  : "rgba(47, 128, 237, 0.1)",
              padding: "2px 8px",
              borderRadius: "3px",
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>
              {model.model_type}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "4px",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            {model.description}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: "16px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {model.model_objective == "Forecasting" ? (
              <StormIcon
                sx={{ color: "rgba(33, 150, 122, 1)", fontSize: "14px" }}
              />
            ) : model.model_objective == "Optimization" ? (
              <TroubleshootIcon sx={{ color: "#0047AB", fontSize: "14px" }} />
            ) : (
              <CircleNotificationsIcon
                sx={{ color: "rgba(199, 94, 0, 1)", fontSize: "14px" }}
              />
            )}
            <Typography
              color={
                model.model_objective == "Forecasting"
                  ? "rgba(33, 150, 122, 1)"
                  : model.model_objective == "Optimization"
                  ? "#0047AB"
                  : "rgba(199, 94, 0, 1)"
              }
              fontSize="11px"
              fontWeight="600"
              variant="body1"
            >
              {model.model_objective}
            </Typography>
          </Box>
          <Typography fontSize="11px" fontWeight="800">
            •
          </Typography>
          <Typography fontSize="11px" variant="subtitle2" color="#5e5e5e">
            Created on {formatDateTime(model.creation_time)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const MonitorCard = ({ monitor }) => {
    var borderTopColor = '#D9534F';
    if(monitor.severity === 'medium'){
      borderTopColor = '#F0AD4E';
    }else if(monitor.severity === 'low'){
      borderTopColor = '#6C9A8B'
    }
    return (
      <Box
        sx={{
          padding: "16px 20px",
          borderTop:`10px solid ${borderTopColor}`,
          borderBottom: "1px solid lightgrey",
          backgroundColor:
            monitor.monitor_id === selectedMonitor.monitor_id
              ? "rgba(44, 44, 45, 0.06)"
              : "rgba(255, 255, 255, 1)",
            minHeight: '200px'
        }}
        // onClick={() => handleMonitorSelect(monitor)}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            {monitor.name}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: "4px",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            {monitor.description}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: "15px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "130px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography color="rgba(79, 79, 79, 1)" variant="body2">
              Sensors
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {monitor.sensors.map((sensor) => (
              <SensorTag sensor={sensor} />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "15px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "130px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography color="rgba(79, 79, 79, 1)" variant="body2">
              Events
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: "10px",
            }}
          >
            <Typography variant="body2">
              {monitor.events}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "15px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "130px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography color="rgba(79, 79, 79, 1)" variant="body2">
              Attachments
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: "10px",
            }}
          >
            <Typography variant="body2">
              {monitor.attachments}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "16px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography fontSize="11px" variant="subtitle2" color="#5e5e5e">
            Created on {monitor.creation_time}
          </Typography>
        </Box>
      </Box>
    );
  };

  const sensorColors = {
    PV: "rgba(39, 174, 96, 1)",
    SP: "rgba(41, 128, 185, 1)",
    OUT: "rgba(231, 76, 60, 1)",
    TEST: "rgba(243, 156, 18, 1)",
  };

  const SensorTag = ({ sensor }) => {
    var sensorColor = "black";
    Object.keys(sensorColors).forEach((type) => {
      if (sensor.includes(type)) {
        sensorColor = sensorColors[type];
      }
    });

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">
          {sensor}
        </Typography>
        <Divider orientation="vertical" flexItem />
      </Box>
    );
  };

  const StreamTag = ({ stream, idx }) => {
    var sensorColor = Object.values(sensorColors)[idx] ?? "black";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">{stream.name}</Typography>
        <Divider orientation="vertical" flexItem />
      </Box>
    );
  };

  const [selectedModelData, setSelectedModelData] = React.useState({
    sensors: [],
    streams: [],
  });
  React.useEffect(() => {
    const getResources = async () => {
      var streams = [];
      var sensors = [];
      if (selectedModel.model_type == "Targeted") {
        try {
          const response = await fetch(
            "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=" +
              selectedModel.model_id
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          sensors = result["training_set"]["sensor_config"];
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (selectedModel.model_type == "Generalized") {
        sensors = selectedModel.sensors;
        streams = selectedModel.streams;
      }
      var modelData = {
        sensors: sensors,
        streams: streams,
      };

      setSelectedModelData({ ...modelData });
    };

    if (selectedModel.sensor_id !== -1) {
      getResources();
    }
  }, [selectedModel]);

  const [hasResults, setHasResults] = React.useState(false);
  React.useEffect(() => {
    const checkForResults = async () => {
      try {
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/hasResults?modelId=" +
            selectedModel.model_id
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result["has_results"]) {
          setHasResults(true);
        } else {
          setHasResults(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (selectedModel.sensor_id !== -1) {
      checkForResults();
    }
  }, [selectedModel]);

  const [trainResults, setTrainResults] = React.useState(null);
  React.useEffect(() => {
    const getResults = async () => {
      try {
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/getResults?modelId=" +
            selectedModel.model_id
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setTrainResults(result["model_results"]);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (hasResults) {
      getResults();
    }
  }, [selectedModel]);

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <CreateModal open={openCreate} handleModalState={handleModalState} />
        <Box sx={{ display: "flex" }}>
          <Main open={openDrawer}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "64px",
                paddingRight: "64px",
                paddingTop: "32px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontSize: "32px", lineHeight: "44px" }}
                  variant="h4"
                >
                  Monitors
                </Typography>
                <Button
                  onClick={() => handleModalState(true)}
                  color="success"
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  New
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  height: "40px",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                }}
              >
                <Box
                  sx={{
                    flexGrow: "1",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "white",
                    boxShadow: 1,
                  }}
                >
                  <SearchIcon />
                  <Typography>Search...</Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    backgroundColor: "white",
                    boxShadow: 1,
                  }}
                >
                  <Typography>Severity</Typography>
                  <ArrowDropDownIcon />
                </Box>
              </Box>
              <Grid container spacing={'20px'}>
                {monitorsList.map((monitor) => (
                  <Grid item xs={6}>
                    <MonitorCard monitor={monitor} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Main>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={openDrawer}
          >
            <Box
              sx={{
                width: "400px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                minHeight: "100%",
                boxShadow: 20,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  justifyContent: "left",
                  alignItems: "center",
                  height: "64px",
                  paddingLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    height: "30px",
                    width: "30px",
                    backgroundColor: "lightgrey",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PsychologyIcon />
                </Box>
                <Typography fontWeight="bold" variant="h7">
                  {selectedModel.name}
                </Typography>
                <StarIcon sx={{ color: "gold" }} />
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  height: "50px",
                  padding: "10px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "rgba(70, 67, 212, 1)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                    },
                    width: "120px",
                  }}
                  variant="contained"
                  component={Link}
                  to={"/ml-factory/model-overview/" + selectedModel.model_id}
                >
                  Open
                </Button>
                <Button
                  sx={{
                    backgroundColor: "rgba(114, 110, 255, 0.16)",
                    color: "rgba(75, 64, 221, 1)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(114, 110, 255, 0.5)", // Hover background color
                    },
                    width: "120px",
                  }}
                  component={Link}
                  to={
                    selectedModel.model_type == "Targeted"
                      ? "/ml-factory/model-builder/training-data/" +
                        selectedModel.model_id
                      : "/ml-factory/model-builder/training-data/generalized/" +
                        selectedModel.model_id
                  }
                  variant="contained"
                  startIcon={<SettingsIcon />}
                >
                  Configure
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    borderColor: "black",
                    color: "black",
                    width: "50px",
                    "&:hover": {
                      borderColor: "black",
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  variant="outlined"
                >
                  Share
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    borderColor: "black",
                    color: "black",
                    "&:hover": {
                      borderColor: "black",
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                    },
                  }}
                  variant="outlined"
                >
                  <Typography fontWeight="bold">...</Typography>
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Details
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Objective
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    {selectedModel.model_objective == "Forecasting" ? (
                      <StormIcon sx={{ color: "rgba(33, 150, 122, 1)" }} />
                    ) : selectedModel.model_objective == "Optimization" ? (
                      <TroubleshootIcon sx={{ color: "#0047AB" }} />
                    ) : (
                      <CircleNotificationsIcon
                        sx={{ color: "rgba(199, 94, 0, 1)" }}
                      />
                    )}
                    <Typography
                      color={
                        selectedModel.model_objective == "Forecasting"
                          ? "rgba(33, 150, 122, 1)"
                          : selectedModel.model_objective == "Optimization"
                          ? "#0047AB"
                          : "rgba(199, 94, 0, 1)"
                      }
                      variant="body2"
                    >
                      {selectedModel.model_objective}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Model Type
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {selectedModel.model_type}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Sensors
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "190px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedModelData.sensors.map((sensor) => (
                      <SensorTag sensor={sensor} />
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Streams
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "190px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedModelData.streams.map((stream) => (
                      <StreamTag stream={stream} />
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Training time range
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(selectedModel.start_time)}{" "}
                      <span style={{ color: "rgba(79, 79, 79, 1)" }}>to</span>{" "}
                      {formatDateTime(selectedModel.end_time)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Last trained
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    {trainResults && (
                      <Typography variant="body2">
                        {formatDateTime(trainResults.creation_time)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Deployment
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Active
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "2px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <CancelIcon sx={{ fill: "red", stroke: "white" }} />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Metadata
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Creator
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">You</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Last modified
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(selectedModel.last_updated)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Created on
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(selectedModel.creation_time)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}
