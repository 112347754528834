import React, { useState } from 'react';
import { Button, Box, Typography, Card } from '@mui/material';

const CSVFileUploader = ({getSelectedFile}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  // Function to handle file selection
  const handleFileChange = (event) => {
    // Retrieve the selected file from the input element
    const file = event.target.files[0];
    setSelectedFile(file);
    getSelectedFile(file);
  };

  // Function to handle file upload
  const handleFileUpload = () => {
    if (selectedFile) {
      // Perform file upload logic here (e.g., read the file content)
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvText = event.target.result;
        // Process the CSV file content (e.g., parse CSV data)
        console.log('CSV content:', csvText);
      };
      reader.readAsText(selectedFile);
    } else {
      alert('Please select a CSV file to upload.');
    }
  };

  return (
    <Box>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="csv-file-input"
      />
      <label htmlFor="csv-file-input">
        <Button
          variant="contained"
          component="span"
          color="primary"
        >
          Choose CSV File
        </Button>
      </label>
      {
          selectedFile && <Box
            sx={{
                paddingTop:'10px',
            }}
          >
              <Card
                sx={{
                    width:'400px',
                    height:'40px',
                    paddingTop:'20px',
                    paddingLeft:'20px'
                }}
              >
                  {selectedFile['name']}
              </Card>
          </Box>
      }
    </Box>
  );
};

export default CSVFileUploader;
