import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { TableHead } from '@mui/material';

export default function HistorianTable({ 
  header,
  id_column,
  data,
  handleSelection
}) {

  const SelectableTableRow = ({ rowData, selected, onSelect }) => {
    return (
      <TableRow
        hover
        onClick={() => onSelect(rowData[id_column])}
        selected={selected}
        sx={{
          backgroundColor: selectedRow === rowData[id_column] ? '#f0f8ff' : '#ffffff',
        }}
      >
        {
          header.map((col) => (
            <TableCell>{rowData[col[0]]}</TableCell>
          ))
        }
      </TableRow>
    );
  };

  const [selectedRow, setSelectedRow] = useState(null);
    
  const handleRowSelect = (rowData) => {
    if(selectedRow && (rowData[id_column] == selectedRow)){
      setSelectedRow(null);
      handleSelection(null);
    }else{
      handleSelection(rowData[id_column]);
      setSelectedRow(rowData[id_column]);
    }
  };

    return (
      <Box
      sx={{
      }}
      >
      <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
        <Table>
          <TableHead>
              <TableRow>
              {header.map((col) => (
                  <TableCell>
                      <Typography variant="body1" fontWeight="bold">
                          {col[1]}
                      </Typography>
                  </TableCell>
                ))}
              </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <SelectableTableRow
                rowData={row}
                selected={selectedRow === row.id}
                onSelect={() => handleRowSelect(row)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    );
  };