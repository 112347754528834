import * as React from 'react';
import Box from '@mui/material/Box';
import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { TableContainer } from '@mui/material';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Card, CardContent, Button, Icon, TextField,  Radio, RadioGroup, FormControlLabel } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage'; // Import the Storage icon
import { useNavigate } from 'react-router-dom';

export default function HistorianManagerCreate({title, showButton, options, handleSensorImport}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();

    const steps = [
        'Configuration',
        'Credentials',
        'Review',
    ];
    const connectionTypes = [
        { id: 1, name: 'FactoryTalk', description: 'Rockwell Automation', icon: <StorageIcon /> },
        { id: 2, name: 'Proficy', description: 'GE Digital', icon: <StorageIcon /> },
        { id: 3, name: 'SIMATIC', description: 'Siemens', icon: <StorageIcon /> },
        { id: 4, name: 'Uniformance', description: 'Honeywell', icon: <StorageIcon /> },
        { id: 5, name: 'PI Vision', description: 'OSIsoft', icon: <StorageIcon /> },
      ];

      const [name, setName] = React.useState('');
      const [username, setUsername] = React.useState('');
      const [password, setPassword] = React.useState('');
      const [desc, setDesc] = React.useState('');
      const [type, setType] = React.useState('');
      const [authType, setAuthType] = React.useState('');
      const [stepComplete, setStepComplete] = React.useState(false);
      const [connStatus, setConnStatus] = React.useState('none');
      const [connUrl, setConnUrl] = React.useState('');
      const [dbTables, setDbTables] = React.useState([]);

      const handleNextStep = () => {
          setActiveStep(activeStep + 1);
          setStepComplete(false);
      }

      const handlePrevStep = () => {
        setActiveStep(activeStep - 1);
        setStepComplete(true);
      }

      const handleNameChange = (event) => {
          setName(event.target.value);
      }

      const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleConnUrlChange = (event) => {
        setConnUrl(event.target.value);
    }

      const handleDescChange = (event) => {
          setDesc(event.target.value);
      }

      const handleTypeSelection = (connectionType) => {
          setType(connectionType.name);
      }

      const setAuthenticationType = (authType) => {
        setAuthType(authType);
      }

      const TestConnection = () => {
        const testConnection = async () => {
            try{
                // Make the API call using fetch
                const database_url = connUrl.toString() + 'allTables';
                const response = await fetch(database_url);
        
                // Check if the response is successful (status code 200-299)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
        
                // Parse the JSON data
                const result = await response.json();
                setConnStatus('success');
                setDbTables(result);
            }catch(error){
                setConnStatus('fail');
            }
          };
      
          testConnection();
      }

      React.useEffect(() => {
        if(activeStep === 0 && name !== '' && desc !== '' && type !== ''){
            setStepComplete(true);
        }else if(activeStep === 1 && connStatus === 'success'){
            setStepComplete(true);
        }
        else{
            setStepComplete(false);
        }
      }, [name, desc, type, connStatus, stepComplete])

      const IngestMetadata = async (historian_id) => {
          try {
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/ingest_service/ingestMetadata/' + historian_id.toString());
            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON data
            const result = await response.json()

            if (result['success']){
                navigate('/historian-manager');
            }else{
                console.error('Failed to ingest metadata')
            }
          } catch (error) {
              console.error('Error:', error);
          }
      }

      const CreateConnection = async () => {
        try {
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/create', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: name,
                description: desc,
                type: type,
                auth_type: authType,
                conn_url: connUrl,
                username: username,
                password: password,
              }),
            });
            const result = await response.json();
            if (result['success']) {
                const historian_id = result['historian_id']
                IngestMetadata(historian_id)
            } else {
                console.error('Failed to add connection');
            }
          } catch (error) {
            console.error('Error:', error);
          }
      };

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuDrawer/>
      <Box component="main" sx={{ flexGrow: 1 }}>
          <Header title={'Historian Manager'}/>
          <Box sx={{
              width: '50%',
              paddingTop:'50px',
              paddingLeft: '25vw',
            }}>
            <Grid container>
                <Grid item xs={1}>
                    <Button onClick={handlePrevStep} disabled={activeStep === 0}>Previous</Button>
                </Grid>
                <Grid item xs={10}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={1}>
                    <Button onClick={handleNextStep} disabled={!stepComplete}>Next</Button>
                </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
                paddingLeft:'100px',
                paddingRight:'100px',
            }}
          >
              {
                  activeStep === 0 &&
                <div>
                    <Box
                        sx={{
                            paddingTop: '40px',
                            marginTop: '10px',
                            paddingBottom: '20px',
                            marginLeft: '100px',
                            marginRight: '100px',
                        }}
                    >
                        <Typography variant='h7' gutterBottom='50px' style={{ fontWeight: 'bold' }}>
                            Name
                        </Typography>
                        <TextField
                            onChange={handleNameChange}
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={name}
                        />
                        </Box>
                        <Box
                        sx={{
                            paddingBottom: '10px',
                            marginLeft: '100px',
                            marginRight: '100px',
                        }}
                    >
                        <Typography variant='h7' gutterBottom='50px' style={{ fontWeight: 'bold' }}>
                            Description
                        </Typography>
                        <TextField
                            onChange={handleDescChange}
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={desc}
                        />
                        </Box>
                    <Box
                        sx={{
                            paddingTop: '50px',
                            marginTop: '10px',
                            paddingLeft: '30px',
                            paddingRight: '30px',
                            paddingBottom: '50px',
                            marginLeft: '100px',
                            marginRight: '100px',
                            backgroundColor: '#e9e9e9'
                        }}
                    >
                        <Grid container spacing={1}>
                        {connectionTypes.map((connectionType) => (
                            <Grid item xs={3}>
                                <Card
                                    key={connectionType.id}
                                    style={{
                                        margin: '10px',
                                        width: '250px',
                                        textAlign: 'center',
                                        backgroundColor: connectionType.name === type ? '#0F1354' : 'white'
                                    }}
                                >
                                <CardContent>
                                    <Icon color="primary" style={{ fontSize: 40 }}>
                                    {connectionType.icon}
                                    </Icon>
                                    <Typography variant="h6" component="div" color={connectionType.name === type ? 'white' : 'black'}>
                                    {connectionType.name}
                                    </Typography>
                                    <Typography variant="body2" color={connectionType.name === type ? 'white' : 'black'}>
                                    {connectionType.description}
                                    </Typography>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => handleTypeSelection(connectionType)}
                                    >
                                    Select
                                    </Button>
                                </CardContent>
                                </Card>
                            </Grid>
                        ))}
                            </Grid>
                    </Box>
                </div>
              }
              {
                  activeStep === 1 &&
                  <div>
                    <Box
                        sx={{
                            paddingTop: '40px',
                            marginTop: '40px',
                            paddingLeft: '1vw',
                            marginLeft: '100px',
                            marginRight: '100px',
                        }}
                    >
                        <Typography variant='h7' gutterBottom='50px' style={{ fontWeight: 'bold' }}>
                            Authentication Type
                        </Typography>
                        <RadioGroup
                            aria-label="authentication-type"
                            name="authentication-type"
                            value={authType}
                            onChange={(e) => setAuthenticationType(e.target.value)}
                            style={{ flexDirection: 'row', paddingTop: '10px' }}
                        >
                            <FormControlLabel value="oauth" control={<Radio />} label="OAuth" />
                            <FormControlLabel value="saml" control={<Radio />} label="SAML" />
                            <FormControlLabel value="basic" control={<Radio />} label="BASIC" />
                        </RadioGroup>
                    </Box>

                    <Box
                        sx={{
                            paddingTop: '10px',
                            marginTop: '10px',
                            paddingLeft: '1vw',
                            marginLeft: '100px',
                            marginRight: '100px',
                        }}
                    >
                        <Typography variant='h7' gutterBottom='50px' style={{ fontWeight: 'bold' }}>
                            Connection URL
                        </Typography>
                        <TextField
                            onChange={handleConnUrlChange}
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={connUrl}
                        />
                    </Box>
                    <Box
                        sx={{
                            paddingTop: '10px',
                            marginTop: '10px',
                            paddingBottom: '20px',
                            paddingLeft: '1vw',
                            marginLeft: '100px',
                            marginRight: '100px',
                        }}
                    >
                        <Typography variant='h7' gutterBottom='50px' style={{ fontWeight: 'bold' }}>
                            Username
                        </Typography>
                        <TextField
                            onChange={handleUsernameChange}
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={username}
                        />
                        </Box>
                        <Box
                        sx={{
                            paddingBottom: '20px',
                            marginLeft: '100px',
                            marginRight: '100px',
                            paddingLeft: '1vw',
                        }}
                    >
                        <Typography variant='h7' gutterBottom='50px' style={{ fontWeight: 'bold' }}>
                            Password
                        </Typography>
                        <TextField
                            onChange={handlePasswordChange}
                            type="password"
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={password}
                        />
                        </Box>
                        <Box
                        sx={{
                            paddingBottom: '30px',
                            marginLeft: '100px',
                            marginRight: '100px',
                            paddingLeft: '1vw',
                        }}
                    >
                        <Button
                            sx={{
                            }}
                            startIcon={<CheckCircleIcon/>}
                            onClick={TestConnection}
                        >Test Connection</Button>
                        </Box>
                        {
                            connStatus !== 'none'  &&
                            <Box
                            sx={{
                                paddingBottom: '10px',
                                marginLeft: '100px',
                                marginRight: '100px',
                                paddingLeft: '1vw',
                            }}
                        >
                            <Typography variant='h6' color={connStatus === 'success' ? 'green' : 'red'}
                            >Connection {connStatus === 'success' ? 'Successful' : 'Failed'}</Typography>
                            </Box>
                        }
                        {
                            connStatus === 'success' &&
                            <Box
                            sx={{
                                paddingTop: '10px',
                                marginTop: '10px',
                                paddingLeft: '1vw',
                                marginLeft: '100px',
                                marginRight: '100px',
                            }}
                            >
                            <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableCell>
                                          <Typography variant="body1" fontWeight="bold">
                                              Available Sensor Tags ({dbTables.length.toString()})
                                          </Typography>
                                      </TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                    dbTables.map((tableName) => (
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="body1">
                                                    {tableName}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                          </Box>
                        }
                    </div>
              }
              {
                  activeStep === 2 &&
                  <div>
                    <Box
                        sx={{
                            paddingTop: '40px',
                            marginTop: '10px',
                            paddingLeft: '1vw',
                            marginLeft: '20vw',
                            marginRight: '100px',
                            width: '60%'
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table>
                              <TableBody>
                                {
                                    [
                                        ['Connection Name', name],
                                        ['Connection Description', desc],
                                        ['Connection Type', type],
                                        ['Connection URL', connUrl],
                                        ['Authentication Type', authType],
                                        ['Username', username],
                                        ['Password', '*********'],
                                    ].map((config) => (
                                        <TableRow>
                                            <TableCell>
                                                <Typography fontWeight="bold" variant="body1">
                                                    {config[0]}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body1">
                                                    {config[1]}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                              </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: '40px',
                            marginTop: '10px',
                            paddingLeft: '25vw',
                            marginLeft: '20vw',
                            marginRight: '100px',
                            width: '60%'
                        }}
                    >
                        <Button
                            onClick={CreateConnection}
                        >CREATE</Button>
                    </Box>
                  </div>
              }
            </Box>
      </Box>
    </Box>
  );
}