import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, zoomPlugin);

const AnomalyDetectionTSGraph = ({
  actuals,
  inputs,
  anomalies,
  labels,
  title,
  sensorColors,
  hideTarget
}) => {
  const chartRef = React.useRef(null);

  const colors = [
    'rgba(255, 206, 86, 0.5)',  // Bright yellow
    'rgba(54, 162, 235, 0.5)',  // Vibrant blue
    'rgba(153, 102, 255, 0.5)', // Soft purple
    'rgba(255, 159, 64, 0.5)',  // Warm orange
    'rgba(201, 203, 207, 0.5)', // Neutral gray
    'rgba(75, 192, 192, 0.5)', // Lighter teal
    'rgba(255, 99, 132, 0.5)', // Lighter pink/red
    'rgba(105, 105, 105, 0.5)',  // Darker gray
    'rgba(0, 128, 128, 0.5)',    // Darker teal
    'rgba(220, 20, 60, 0.5)',     // Strong crimson
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(244, 67, 54, 0.5)',
    'rgba(33, 150, 243, 0.5)',
    'rgba(76, 175, 80, 0.5)',
    'rgba(156, 39, 176, 0.5)',
    'rgba(63, 81, 181, 0.5)',
    'rgba(255, 87, 34, 0.5)',
    'rgba(205, 220, 57, 0.5)',
    'rgba(121, 85, 72, 0.5)',
    'rgba(96, 125, 139, 0.5)',
    'rgba(255, 235, 59, 0.5)',
    'rgba(139, 195, 74, 0.5)',
    'rgba(233, 30, 99, 0.5)',
    'rgba(103, 58, 183, 0.5)',
    'rgba(0, 150, 136, 0.5)',
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Anomaly',
        data: anomalies,
        pointRadius:10,
        backgroundColor: 'rgba(255,99,132,1)',
        type: 'scatter',
        showLine: false,
      },
      {
        data: actuals,
        hidden: hideTarget,
        fill: false,
        borderColor: sensorColors ? sensorColors[title] : 'black',
        tension: 0.1,
        pointRadius: 0, // Hide the circles at data points
      },...inputs.map((input, idx) => (
        {
          label: `Input: ${input.title}`,
          hidden: !input.display,
          data: input.values,
          borderColor: sensorColors ? sensorColors[input.title] : 'black',
          tension: 0.1,
          pointRadius: 0, // Hide the circles at data points
          yAxisID: `y${idx+1}`,
        }
      ))
    ],
  };

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
        text: title,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    onClick: (event) => {
      const chart = chartRef.current;
      if (!chart) return;

      const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

      if(chart.isZoomedOrPanned()){
        chart.resetZoom();
      }else if (points.length) {
        const idx = points[0].element.$context.parsed.x;
        const xMin = Math.max(chart.getInitialScaleBounds().x.min, idx - 10); // Adjust as needed
        const xMax = Math.min(chart.getInitialScaleBounds().x.max, idx + 10); // Adjust as needed
        chart.zoomScale('x', { min: xMin, max: xMax });
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: 'time',
        },
        ticks: {
          color: 'rgba(87,87,87,0.8)', // Change the x-axis ticks color
          callback: function(value, index, values) {
            const label = this.getLabelForValue(value);
            return index % 5 === 0 ? label.slice(0,11) : '';
          },
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
    },
  };

  inputs.forEach((input, idx) => {
    options.scales[`y${idx + 1}`] = {
      type: 'linear',
      display: input.display,
      position: 'left',
      border: {
          color: sensorColors ? sensorColors[input.title] : 'black'
      },
    }
  })

  return (
    <div style={{height:'620px', width:'100%'}}>
      {
        title &&
        <Line ref={chartRef} data={data} options={options} />
      }
    </div>
  );
};

export default AnomalyDetectionTSGraph;