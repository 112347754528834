import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Divider, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import MenuDrawer from "../../../components/menu-drawer";
import TrainingLogs from "./components/training-logs";

import CachedIcon from "@mui/icons-material/Cached";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import EastIcon from "@mui/icons-material/East";
import StreamIcon from "@mui/icons-material/Stream";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import StormIcon from "@mui/icons-material/Storm";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SettingsIcon from "@mui/icons-material/Settings";

function formatDateTime(inputString) {
  // Parse the input date string
  const date = new Date(inputString);

  // Options for date formatting
  const dateOptions = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };

  // Format the date part
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  // Options for time formatting
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Format the time part
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Combine date and time with the desired format
  return `${formattedDate} at ${formattedTime}`;
}

export default function MLFactoryModelOverview({
  title,
  showButton,
  options,
  handleSensorImport,
}) {
  const navigate = useNavigate();

  const sensorColors = {
    PV: "rgba(39, 174, 96, 1)",
    SP: "rgba(41, 128, 185, 1)",
    OUT: "rgba(231, 76, 60, 1)",
    TEST: "rgba(243, 156, 18, 1)",
  };

  const SensorTag = ({ sensor }) => {
    var sensorColor = "black";
    try {
      Object.keys(sensorColors).forEach((type) => {
        if (sensor.name.includes(type)) {
          sensorColor = sensorColors[type];
        }
      });
    } catch (error) {}
    try {
      Object.keys(sensorColors).forEach((type) => {
        if (sensor.sensor_name.includes(type)) {
          sensorColor = sensorColors[type];
        }
      });
    } catch (error) {}

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">
          {model.model_type === "Generalized"
            ? sensor.name
            : sensor.sensor_name}
        </Typography>
      </Box>
    );
  };

  const StreamTag = ({ stream, idx }) => {
    var sensorColor = Object.values(sensorColors)[idx] ?? "black";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">{stream.name}</Typography>
        <Divider orientation="vertical" flexItem />
      </Box>
    );
  };

  const { modelId } = useParams("modelId");

  const [model, setModel] = React.useState({
    model_id: "",
    name: "",
    model_type: "",
    model_objective: "",
    training_status: "",
  });

  React.useEffect(() => {
    const getModel = async () => {
      // Make the API call using fetch
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/get?modelId=" +
          modelId
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON data
      const result = await response.json();
      var config = result["model"];
      setModel({ ...config });
    };
    // Call the fetchData function
    getModel();
  }, [modelId]);

  const [hasResults, setHasResults] = React.useState(false);
  React.useEffect(() => {
    const checkForResults = async () => {
      try {
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/hasResults?modelId=" +
            model.model_id
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result["has_results"]) {
          setHasResults(true);
        } else {
          setHasResults(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (model.sensor_id !== -1) {
      checkForResults();
    }
  }, [model]);

  const [trainResults, setTrainResults] = React.useState(null);
  React.useEffect(() => {
    const getResults = async () => {
      try {
        const response = await fetch(
          "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/getResults?modelId=" +
            model.model_id
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setTrainResults(result["model_results"]);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (hasResults) {
      getResults();
    }
  }, [hasResults, model]);

  const [inputs, setInputs] = React.useState(null);
  const [output, setOutput] = React.useState(null);
  const [modelData, setModelData] = React.useState({
    sensors: [],
    streams: [],
  });
  React.useEffect(() => {
    if (model.model_type === "Targeted") {
      var _inputs = [];
      var _output = null;
      modelData["sensors"].forEach((s) => {
        if (s.variable_type === "input") {
          _inputs.push(s);
        } else {
          _output = s;
        }
      });
      setOutput({ ..._output });
      setInputs([..._inputs]);
    } else {
      setInputs([...modelData.sensors]);
    }
  }, [modelData]);

  React.useEffect(() => {
    const getResources = async () => {
      var streams = [];
      var sensors = [];
      if (model.model_type == "Targeted") {
        try {
          const response = await fetch(
            "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/get?trainingDatasetId=" +
              model.model_id
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          sensors = result["training_set"]["sensor_config"];
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (model.model_type == "Generalized") {
        sensors = model.sensors;
        streams = model.streams;
      }
      var modelData = {
        sensors: sensors,
        streams: streams,
      };

      setModelData({ ...modelData });
    };

    if (model.sensor_id !== -1) {
      getResources();
    }
  }, [model]);

  const [jobStarted, setJobStarted] = React.useState(false);
  const trainModel = async () => {
    try {
      setJobStarted(true);
      const response = await fetch(
        "https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/train?modelId=" +
          model.model_id
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setJobStarted(false);
    } catch (error) {
      setJobStarted(false);
      console.error("Error:", error);
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuDrawer />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box sx={{}}>
          <Box
            sx={{
              backgroundColor: "white",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                }}
              >
                <StreamIcon sx={{ p: 0, m: 0, fill: "rgb(130, 130, 130)" }} />
                <Typography
                  sx={{ color: "rgba(130, 130, 130, 1)", fontSize: "14px" }}
                >
                  Models
                </Typography>
              </Box>
              <Typography fontSize="1.4rem" color="rgba(130, 130, 130, 1)">
                /
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FolderZipIcon
                  sx={{ p: 0, m: 0, fill: "rgba(170, 0, 122, 1)" }}
                />
                <Typography
                  sx={{ pt: "2px", fontWeight: "bold", fontSize: "14px" }}
                >
                  {model.name}
                </Typography>
              </Box>
              <Divider flexItem orientation="vertical" />
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "10px",
                }}
              >
                {/*
                <Tabs
                  textColor="#4643D4"
                  indicatorColor="#4643D4"
                  value={value}
                  onChange={handleChange}
                >
                  <Tab
                    isActive
                    fontSize="14px"
                    disableRipple
                    textTransform="none"
                    value={`/ml-factory/model-overview/${modelId}`}
                    label="Overview"
                  />
                  <Tab
                    fontSize="14px"
                    disableRipple
                    textTransform="none"
                    value={`/ml-factory/model-testing/${modelId}`}
                    label="Testing"
                  />
                  <Tab
                    fontSize="14px"
                    disableRipple
                    textTransform="none"
                    value={`/ml-factory/model-sandbox/${modelId}`}
                    label="Sandbox"
                  />
                </Tabs>*/}
                <Box
                  sx={{
                    //borderBottom: "2px solid rgba(70, 67, 212)",
                    boxShadow: "0px 2px 0px rgba(70, 67, 212)",
                    padding: "10px 0px",
                    marginRight: "8px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(70, 67, 212)">
                    Overview
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    marginRight: "8px",
                    "&:hover": {
                      color: "rgba(70, 67, 212)",
                      cursor: "pointer",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(`/ml-factory/model-testing/${modelId}`)
                  }
                >
                  <Typography fontSize="14px">Testing</Typography>
                </Box>
                <Box
                  sx={{
                    padding: "10px 0px",
                    marginRight: "16px",
                    "&:hover": {
                      color: "rgba(70, 67, 212)",
                      cursor: "pointer",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(`/ml-factory/model-sandbox/${modelId}`)
                  }
                >
                  <Typography fontSize="14px">Sandbox</Typography>
                </Box>
                <Box
                  sx={{
                    padding: "10px 0px",
                    marginRight: "16px",
                    "&:hover": {
                      color: "rgba(70, 67, 212)",
                      cursor: "pointer",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography fontSize="14px">Deployments</Typography>
                  <Box
                    sx={{
                      pl: "5px",
                      pr: "5px",
                      backgroundColor: "rgba(36, 37, 42, 0.1)",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography fontSize="12px">0</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                paddingRight: "20px",
              }}
            >
              <Box
                sx={{
                  border: "1px solid rgba(55, 70, 91, 0.32)",
                  padding: "3px",
                  paddingLeft: "10px",
                  paddingRight: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  borderRadius: "4px",
                }}
              >
                <Typography fontSize="0.8rem" color="rgba(33, 43, 56, 1)">
                  Version 1.0
                </Typography>
                <ArrowDropDownIcon sx={{ fill: "rgba(33, 43, 56, 1)" }} />
              </Box>
              <Divider flexItem orientation="vertical" />
              <ShareIcon />
              <MoreHorizIcon />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                height: "70px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "20px",
                paddingLeft: "64px",
                paddingRight: "64px",
                paddingBottom: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "20px",
                  }}
                >
                  <Typography
                    fontSize={"1.7rem"}
                    fontWeight="bold"
                    variant="h5"
                  >
                    {model.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      paddingTop: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={"1.1rem"}
                      color="rgba(79, 79, 79, 1)"
                      variant="body2"
                    >
                      Objective
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2px",
                        alignItems: "center",
                      }}
                    >
                      {model.model_objective == "Forecasting" ? (
                        <StormIcon sx={{ color: "rgba(33, 150, 122, 1)" }} />
                      ) : model.model_objective == "Optimization" ? (
                        <TroubleshootIcon sx={{ color: "#0047AB" }} />
                      ) : (
                        <CircleNotificationsIcon
                          sx={{ color: "rgba(199, 94, 0, 1)" }}
                        />
                      )}
                      <Typography
                        fontSize={"1.1rem"}
                        color={
                          model.model_objective == "Forecasting"
                            ? "rgba(33, 150, 122, 1)"
                            : model.model_objective == "Optimization"
                            ? "#0047AB"
                            : "rgba(199, 94, 0, 1)"
                        }
                        variant="body2"
                      >
                        {model.model_objective}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor:
                      model.model_type == "Generalized"
                        ? "#F2F2F2"
                        : "rgba(47, 128, 237, 0.1)",
                    padding: "5px",
                    borderRadius: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <Typography fontSize="0.9rem">{model.model_type}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider orientation="horizontal" />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "35%" }}>
              <Box sx={{ margin: "50px", backgroundColor: "white" }}>
                <Box
                  sx={{
                    height: "4px",
                    background: "linear-gradient(45deg, #1AEFAF, #605DFE)",
                  }}
                ></Box>
                <Box
                  sx={{
                    padding: "20px",
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderTop: "0px solid white",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Typography fontWeight="bold" fontSize="0.9rem">
                      Model Checklist
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    {hasResults && <CheckBoxIcon sx={{ fill: "#14AE5C" }} />}
                    {!hasResults && (
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #4643D4",
                          height: "20px",
                          width: "19px",
                          pl: "2px",
                          pr: "2px",
                          display: "flex",
                          borderRadius: "3px",
                          marginTop: "2px",
                        }}
                      >
                        <Typography fontWeight="bold" color="#4643D4">
                          1
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography fontSize="0.9rem">
                        Configure and train model
                      </Typography>
                      <Typography color="#24252A" fontSize="0.8rem">
                        Specify model parameters such as the context size and
                        set the training strategy
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    {hasResults && (
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #4643D4",
                          height: "20px",
                          width: "45px",
                          display: "flex",
                          borderRadius: "3px",
                          marginTop: "2px",
                        }}
                      >
                        <Typography fontWeight="bold" color="#4643D4">
                          2
                        </Typography>
                      </Box>
                    )}
                    {!hasResults && (
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid rgba(0, 0, 0, 0.35)",
                          height: "20px",
                          width: "45px",
                          display: "flex",
                          borderRadius: "3px",
                          marginTop: "2px",
                        }}
                      >
                        <Typography fontWeight="bold" color="#4F4F4F">
                          2
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography fontSize="0.9rem">
                        Explore the model sandbox to view performance
                      </Typography>
                      <Typography color="#24252A" fontSize="0.8rem">
                        The sandbox allows you to investigate how the model is
                        performing for specific sensors. You will be able to
                        simulate results before deploying the model.
                      </Typography>
                      <Button
                        endIcon={<EastIcon />}
                        variant="contained"
                        sx={{
                          mt: "10px",
                          p: "5px",
                          fontSize: "0.8rem",
                          textTransform: "none",
                          backgroundColor: "#24252A",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "black", // Hover background color
                          },
                        }}
                      >
                        Open Sandbox
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid rgba(0, 0, 0, 0.35)",
                        height: "20px",
                        width: "18px",
                        display: "flex",
                        borderRadius: "3px",
                        marginTop: "2px",
                        pr: "2px",
                        pl: "2px",
                      }}
                    >
                      <Typography fontWeight="bold" color="#4F4F4F">
                        3
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontSize="0.9rem">Deploy model</Typography>
                      <Typography color="#24252A" fontSize="0.8rem">
                        Call model results from a live endpoint or writeback the
                        results to a historian
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "65%" }}>
              <Box
                sx={{
                  padding: "50px",
                  paddingLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize="1.4rem" fontWeight="bold">
                    Model summary
                  </Typography>
                  <Button
                    sx={{
                      color: "rgba(75, 64, 221, 1)",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgba(114, 110, 255, 0.1)", // Hover background color
                      },
                    }}
                    component={Link}
                    to={
                      model.model_type == "Targeted"
                        ? "/ml-factory/model-builder/training-data/" +
                          model.model_id
                        : "/ml-factory/model-builder/training-data/generalized/" +
                          model.model_id
                    }
                    variant="text"
                    endIcon={<SettingsIcon />}
                  >
                    View Configuration
                  </Button>
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    padding: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Data
                    </Typography>
                    <Box
                      sx={{
                        paddingTop: "15px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "130px",
                          display: "flex",
                        }}
                      >
                        <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                          Inputs
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "190px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                          flexWrap: "wrap",
                          maxHeight: "130px",
                          overflow: "auto",
                        }}
                      >
                        {inputs &&
                          inputs.map((sensor) => <SensorTag sensor={sensor} />)}
                      </Box>
                    </Box>
                    {output && (
                      <Box
                        sx={{
                          paddingTop: "15px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "130px",
                            display: "flex",
                          }}
                        >
                          <Typography
                            color="rgba(79, 79, 79, 1)"
                            variant="body2"
                          >
                            Target
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "190px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {output && <SensorTag sensor={output} />}
                        </Box>
                      </Box>
                    )}
                    <Box
                      sx={{
                        paddingTop: "15px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "130px",
                          display: "flex",
                        }}
                      >
                        <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                          Training time range
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                        }}
                      >
                        {model.start_time && (
                          <Typography variant="body2">
                            {formatDateTime(model.start_time)}{" "}
                            <span style={{ color: "rgba(79, 79, 79, 1)" }}>
                              to
                            </span>{" "}
                            {formatDateTime(model.end_time)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Divider flexItem orientation="vertical" />
                  <Box
                    sx={{
                      padding: "20px",
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Metadata
                    </Typography>
                    <Box
                      sx={{
                        paddingTop: "15px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "110px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                          Creator
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                        }}
                      >
                        <Typography variant="body2">You</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "15px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "110px",
                          display: "flex",
                        }}
                      >
                        <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                          Last modified
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                        }}
                      >
                        <Typography variant="body2">
                          {formatDateTime(model.last_updated)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "15px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "110px",
                          display: "flex",
                        }}
                      >
                        <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                          Last trained
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                        }}
                      >
                        {trainResults && (
                          <Typography variant="body2">
                            {formatDateTime(trainResults.creation_time)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "15px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "110px",
                          display: "flex",
                        }}
                      >
                        <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                          Created on
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "10px",
                        }}
                      >
                        <Typography variant="body2">
                          {formatDateTime(model.creation_time)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "50px",
                  }}
                >
                  <Typography fontSize="1.4rem" fontWeight="bold">
                    Training
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor: "rgba(55, 70, 91, 0.08)",
                      color: "rgba(33, 43, 56, 1)",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgba(55, 70, 91, 0.2)", // Hover background color
                      },
                    }}
                    onClick={trainModel}
                    disabled={jobStarted}
                    // component={Link}
                    // to={model.model_type == 'Targeted' ? '/ml-factory/model-builder/training-data/' + model.model_id : '/ml-factory/model-builder/training-data/generalized/' + model.model_id}
                    variant="text"
                    endIcon={<CachedIcon />}
                  >
                    {hasResults ? "Re-train model" : "Train model"}
                  </Button>
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    padding: "20px",
                    backgroundColor: "white",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow
                  }}
                >
                  <TrainingLogs jobStarted={jobStarted} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
