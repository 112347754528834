import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from "@mui/system"
import { Typography, Divider, TextField, Button } from "@mui/material"

import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import CSVFileUploader from "./components/csv-uploader"


export default function SensorHubUpload({}) {
    const [sensorName, setSensorName] = useState(null);
    const handleSensorName = (event) => {
        setSensorName(event.target.value);
    }

    const [sensorDescription, setSensorDescription] = useState(null);
    const handleSensorDescription = (event) => {
        setSensorDescription(event.target.value);
    }

    const [sensorUnits, setSensorUnits] = useState(null);
    const handleSensorUnits = (event) => {
        setSensorUnits(event.target.value);
    }

    const [file, setFile] = useState(null);
    const getSelectedFile = (f) => {
        setFile(f);
    }

    const [uploadStatus, setUploadStatus] = useState(false);
    useEffect(() => {
        const canUpload = () => {
            if(sensorName && sensorDescription && sensorUnits && file){
                setUploadStatus(true);
            }else{
                setUploadStatus(false);
            }
        }
        canUpload();
    }, [sensorName, sensorDescription, file, sensorUnits])

    const navigate = useNavigate();
    const uploadSensorData = async () => {
        try {
            const formData = new FormData();
            formData.append('file', file);
    
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/upload?sensorName=' + sensorName, {
              method: 'POST',
              body: formData,
            });

            if (!response.ok) {
              throw new Error('Failed to upload CSV file');
            }

            const result = await response.json();
            if(result['success']){
                navigate('/data-hub')
            }else{
                alert('Error uploading CSV file. Please try again.');
            }
        } catch (error) {
            alert('Error uploading CSV file. Please try again.');
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
        <MenuDrawer/>
        <Box component="main" sx={{ flexGrow: 1 }}>
            <Header title={'Sensor Hub'}/>
            <Box
                sx={{
                    paddingTop: '40px',
                    marginTop: '10px',
                    paddingBottom: '20px',
                    paddingLeft: '200px',
                    paddingRight: '200px',
                }}
            >
                <Box
                    sx={{
                        paddingBottom:'10px',
                    }}
                >  
                    <Typography
                        sx={{
                            display: 'block', // Make the text display as a block
                            backgroundColor: '#e0e0e0', // Light grey background color
                            padding: '10px', // Add padding for spacing
                            borderRadius: '5px', // Add border radius for blocky appearance
                            fontSize: '16px', // Set font size
                            fontWeight: 'bold', // Set font weight
                            color: '#333', // Dark grey text color
                        }}
                    >
                        Sensor Name
                    </Typography>  
                </Box>
                <Box
                    sx={{
                        paddingBottom:'40px',
                    }}
                >
                    <TextField
                        value={sensorName}
                        onChange={handleSensorName}
                        sx={{
                            width:'40%',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        paddingBottom:'10px',
                    }}
                >  
                    <Typography
                        sx={{
                            display: 'block', // Make the text display as a block
                            backgroundColor: '#e0e0e0', // Light grey background color
                            padding: '10px', // Add padding for spacing
                            borderRadius: '5px', // Add border radius for blocky appearance
                            fontSize: '16px', // Set font size
                            fontWeight: 'bold', // Set font weight
                            color: '#333', // Dark grey text color
                        }}
                    >
                        Sensor Description
                    </Typography>  
                </Box>
                <Box
                >
                    <TextField
                        value={sensorDescription}
                        onChange={handleSensorDescription}
                        sx={{
                            width:'75%'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        paddingBottom:'10px',
                        paddingTop:'40px',
                    }}
                >  
                    <Typography
                        sx={{
                            display: 'block', // Make the text display as a block
                            backgroundColor: '#e0e0e0', // Light grey background color
                            padding: '10px', // Add padding for spacing
                            borderRadius: '5px', // Add border radius for blocky appearance
                            fontSize: '16px', // Set font size
                            fontWeight: 'bold', // Set font weight
                            color: '#333', // Dark grey text color
                        }}
                    >
                        Sensor Units
                    </Typography>  
                </Box>
                <Box
                >
                    <TextField
                        value={sensorUnits}
                        onChange={handleSensorUnits}
                        sx={{
                            width:'20%'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop: '40px'
                    }}
                >
                    <CSVFileUploader
                        getSelectedFile={getSelectedFile}
                    />
                </Box>
                {
                    uploadStatus &&
                    <Box
                    sx={{
                        paddingTop:'40px',
                        paddingBottom:'10px'
                    }}
                    >  
                        <Button
                            onClick={uploadSensorData}
                        >Upload</Button>
                    </Box>
                }
            </Box>
        </Box>
        </Box>
    )
}