import React from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

const MLFactoryCreateButton = ({text, handleOnClick, link, disabled}) => {
  return (
    <Button
      variant="contained"
      style={{
        borderRadius: '5px',
        fontSize: '14px',
        backgroundColor: disabled ? '#e0e0e0' : '#0F1354',
        display: 'flex',
        height: '30px',
      }}
      startIcon={<AddCircleIcon />}
      disabled={disabled}
      component={Link}
      to={link}
    >
          {text}
    </Button>
  );
};

export default MLFactoryCreateButton;