import * as React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Grid, Typography, TextField } from '@mui/material';

import MenuDrawer from '../../../components/menu-drawer';
import Header from '../../../components/header';
import SensorDataHeader from './components/sensor-data-header';
import SensorMetadataSection from './components/sensor-metadata';
import SensorDataTable from './components/sensor-data-table';

export default function SensorHubExplore({}) {
    const {sensorId} = useParams('sensorId');

    const [sensor, setSensor] = React.useState(null);
    React.useEffect(() => {
        const getSensor = async () => {
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/get/' + sensorId);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data
            const result = await response.json();
            setSensor(result['sensor']);
        };
        if(sensorId && sensorId !== ''){
            getSensor();
        }
    }, [sensorId])


    const [historian, setHistorian] = React.useState(null);
    React.useEffect(() => {
        const getHistorian = async () => {
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/historian_service/get/' + sensor['historian_id']);

            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data
            const result = await response.json();
            setHistorian(result['historian']);
        };
        if(sensor){
            getHistorian();
        }
    }, [sensor])


    const [tableHeader, setTableHeader] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);  
    React.useEffect(() => {
        const getSensorData = async () => {
            try{
                // Make the API call using fetch
                const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/sensor_service/getData/' +  sensorId.toString());
            
                // Check if the response is successful (status code 200-299)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            
                // Parse the JSON data
                const result = await response.json();
                setTableHeader(result['header']);
                setTableData(result['data']);
            }catch(error){
                console.log(error);
            }
        };

        getSensorData();
    }, [sensorId])

    return (
        <Box sx={{ display: 'flex' }}>
          <MenuDrawer/>
          <Box component="main" sx={{ flexGrow: 1 }}>
              <Header title={'Sensor Hub'}/>
              {
                (sensor && historian) &&
                <Box
                    sx={{
                        padding:'200px',
                        paddingTop:'40px',
                    }}
                >
                    <SensorMetadataSection
                        sensor={sensor}
                        historian={historian}
                    />
                    <Box
                        sx={{
                            paddingTop:'40px'
                        }}
                    >
                        <SensorDataHeader/>
                    </Box>
                    <Box
                        sx={{
                            paddingTop:'40px'
                        }}
                    >
                        <SensorDataTable
                            header={tableHeader}
                            data={tableData}
                        />
                    </Box>
                </Box>
              }
          </Box>
        </Box>
      );
}
