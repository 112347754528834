import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { TableHead } from '@mui/material';

export default function SensorDataTable({ 
  header,
  data,
}) {

    return (
      <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
        <Table>
          <TableHead>
              <TableRow>
              {header.map((col) => (
                  <TableCell>
                      <Typography variant="body1" fontWeight="bold">
                          {col}
                      </Typography>
                  </TableCell>
                ))}
              </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
                <TableRow
                    hover
                >
                    {
                    header.map((col) => (
                        <TableCell>{row[col]}</TableCell>
                    ))
                    }
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };