import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import levenshtein from 'fast-levenshtein';

import { Box, Divider, Modal, Typography, IconButton } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import EastIcon from '@mui/icons-material/East';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';

import SensorSelector from './sensor-selector';
import AltSensorTSGraph from './alt-sensor-graph';

export default function SensorReplacementModal({type, config, color, open, handleModalState, getAltSensor}) {
    const navigate = useNavigate();

    const options = { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-US', options).replace(',', '').split(',')[0];
    const time = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).replace(' ', '');
    const [name, setName] = React.useState(`New Model - ${formattedDate} at ${time}`);
    const handleNameChange = (newName) => {
        setName(newName);
    }

    const [desc, setDesc] = React.useState(null);
    const handleDescChange = (newDesc) => {
        setDesc(newDesc)
    }

    const [objective, setObjective] = React.useState(null);
    const handleObjSelect = (obj) => {
        setObjective(obj);
        setModelType(null);
    }
    const [selectedSensor, setSelectedSensor] = React.useState(null);
    const [modelType, setModelType] = React.useState(null);
    const handleTypeSelect = (type) => {
        setModelType(type);
    }

    const handleSave = (config, selectedSensor) => {
        getAltSensor(config, selectedSensor)
        handleModalState(false);
    }

    const createModel = async () => {
        try{
            // Make the API call using fetch
            const response = await fetch('https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/model/create?name='
            + name.toString() + '&modelType=' + modelType.toString() + '&modelObjective=' + objective.toString()
            + '&description=' + desc
            );
        
            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data
            const result = await response.json();
            if(result['success']){
                if(modelType == 'Generalized'){
                    navigate('/ml-factory/model-builder/training-data/generalized/' + result['model_id']);
                }else{
                    navigate('/ml-factory/model-builder/training-data/' + result['model_id']);
                }
            }else{
                console.log('Failed to create model');
                handleModalState(false)
            }
        }catch (error) {
            console.error('Error:', error);
            handleModalState(false)
        }
    };

    const VariableCard = () => {
        const name = config.sensor_name ? config.sensor_name : config.name;
        return (
            <Box
                sx={{
                    width:'120px',
                    height:'30px',
                    border:'1px solid lightgrey',
                    borderRadius:'8px',
                    paddingLeft:'20px',
                    display:'flex',
                    alignItems:'center',
                }}
            >
                <Box>
                    <Typography fontSize='1rem' color='grey' >{name}</Typography>
                </Box>
            </Box>
        )
    }

    const [similarity, setSimilarity] = React.useState(null);
    React.useEffect(() => {
        if(selectedSensor){
            const name = config.sensor_name ? config.sensor_name : config.name;
            const selectedName = selectedSensor.name;
            const score = 1 - (levenshtein.get(name, selectedName) / Math.max(name.length, selectedName.length));
            setSimilarity(score.toFixed(3));
        }
    }, [selectedSensor])

    const createDataTable = async () => {
        try {
            // Make the API call using fetch
            const response = await fetch(
              'https://ec2-3-18-252-244.us-east-2.compute.amazonaws.com/api/model_service/training_data/create?name=' + name.toString()
              + '&sensorConfig=' + JSON.stringify([])
            );
    
            // Check if the response is successful (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Parse the JSON data
            const result = await response.json();
            if(result['success']){
                createModel();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const [canSave, setCanSave] = React.useState(false);
    React.useEffect(() => {
        if(!name || !desc || !objective || !modelType){
            setCanSave(false);
        }else if(name === '' || desc === '' || objective === '' || modelType === ''){
            setCanSave(false);
        }else{
            setCanSave(true);
        }
    }, [name, desc, objective, modelType])

    const getSelectedSensor = (sensor) => {
        setSelectedSensor(sensor);
    }

    return (
        <Modal
        open={open}
        onClose={() => handleModalState(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height:'500px',
                    width:'550px',
                    backgroundColor:'white',
                    borderRadius:'8px',
                    boxShadow:24,
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between'
                    }}
                >
                    <Box>
                    <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        paddingLeft:'30px',
                        paddingRight:'30px',
                        paddingBottom:'15px',
                        paddingTop:'15px',
                    }}
                    >
                        <Typography fontWeight='bold'>Alternate {type} configuration</Typography>
                        <Box
                            sx={{
                                display:'flex',
                                gap:'10px'
                            }}
                        >
                            <IconButton
                                sx={{
                                    margin:0,
                                    padding:0
                                }}
                            >
                                <CancelIcon onClick={() => handleModalState(false)}/>
                            </IconButton>
                            <Divider flexItem orientation='vertical'/>
                            <IconButton
                                sx={{
                                    margin:0,
                                    padding:0
                                }}
                                disabled={!selectedSensor}
                            >
                                <SaveIcon onClick={() => handleSave(config, selectedSensor)}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box
                        sx={{
                            display:'flex'
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    padding:'30px',
                                    paddingBottom:'0px',
                                    paddingTop:'20px',
                                    display:'flex',
                                    gap:'5px',
                                    flexDirection:'column'
                                }}
                            >
                                <Typography color='rgba(79, 79, 79, 1)'>Select alternate sensor</Typography>
                            </Box>
                            <Box
                                sx={{
                                    margin:'10px',
                                    padding:'10px',
                                    paddingTop:'0px',
                                    paddingBottom:'0px',
                                    paddingLeft:'20px',
                                    display:'flex',
                                    gap:'20px',
                                    flexDirection:'row',
                                    alignItems:'center',
                                }}
                            >
                                <VariableCard/>
                                <EastIcon sx={{fill:'grey'}}/>
                                <SensorSelector getSelectedSensor={getSelectedSensor}/>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                margin:'10px',
                                pr:'20px',
                                pl:'20px',
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',
                                backgroundColor:'lightgrey',
                                borderRadius:'8px'
                            }}
                        >
                            <Typography variant='overline'>Similarity</Typography>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress value={similarity*100} variant="determinate"/>
                            <Box
                                sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" color="text.secondary">
                                {`${Math.round(100*similarity)}%`}
                                </Typography>
                            </Box>
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        border:'1px solid lightgrey',
                        margin:'10px'
                    }}
                >
                    <AltSensorTSGraph color={color} sensor={config} altSensor={selectedSensor}/>
                </Box>
            </Box>
        </Modal>
    );
}
