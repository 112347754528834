import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';

const ExploreHistorianButton = ({text, handleOnClick, link, disabled}) => {
  return (
    <Button
      variant="contained"
      style={{
        borderRadius: '5px',
        fontSize: '14px',
        backgroundColor: disabled ? '#e0e0e0' : '#0F1354',
        display: 'flex',
        height: '30px',
      }}
      onClick={handleOnClick}
      component={Link}
      to={link}
      startIcon={<ExploreIcon />}
      disabled={disabled}
    >
          {text}
    </Button>
  );
};

export default ExploreHistorianButton;